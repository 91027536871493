// @flow
import MapDimensionService from 'services/AdvancedQueryApp/MapDimensionService';

// Build a unique key that is only based on the shape's dimensions.
export default function buildShapeKey(dimensions: {
  +[dimension: string]: string | null | void,
  ...,
}): string {
  return MapDimensionService.getGeographyDimensions()
    .map(dimension => dimensions[dimension] || '')
    .join('__')
    .toLowerCase();
}
