// @flow
import * as React from 'react';

import type { SVGProps } from 'components/ui/Icon/internal/SVGs/types';

export default function DashboardWithBackground(
  props: SVGProps,
): React.Element<'svg'> {
  return (
    <svg
      fill="none"
      height="90"
      viewBox="0 0 90 90"
      width="90"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <ellipse
        cx="44.2623"
        cy="45.7377"
        fill="#EFF1F5"
        rx="44.2623"
        ry="44.2623"
      />
      <g clipPath="url(#clip0)" fill="#BFC2C9">
        <path d="M61.4739 73.9882C61.1798 73.9877 60.8871 73.9487 60.6031 73.8721C60.1842 73.7639 59.7911 73.5729 59.4472 73.3104C59.1032 73.0478 58.8153 72.7191 58.6004 72.3435L47.426 52.9938H55.0788L64.3376 69.025C64.7734 69.7852 64.8908 70.6869 64.6642 71.5334C64.4377 72.3798 63.8855 73.1023 63.1283 73.5432C62.6273 73.8386 62.0554 73.9924 61.4739 73.9882ZM50.7735 54.9288L60.2742 71.376C60.3644 71.5323 60.4845 71.6693 60.6276 71.7792C60.7707 71.889 60.9341 71.9696 61.1084 72.0163C61.2827 72.0631 61.4645 72.075 61.6434 72.0514C61.8223 72.0279 61.9948 71.9693 62.1511 71.8791C62.3087 71.7889 62.4468 71.6684 62.5575 71.5245C62.6682 71.3806 62.7493 71.2162 62.7961 71.0407C62.8429 70.8653 62.8544 70.6823 62.8301 70.5024C62.8057 70.3225 62.7459 70.1492 62.6542 69.9925L53.9565 54.9288H50.7735Z" />
        <path d="M44.2623 75.2459C43.3848 75.2459 42.5432 74.8973 41.9227 74.2768C41.3022 73.6563 40.9536 72.8147 40.9536 71.9371V52.9938H47.5711V71.9371C47.5711 72.8147 47.2225 73.6563 46.602 74.2768C45.9815 74.8973 45.1399 75.2459 44.2623 75.2459ZM42.8885 54.9288V71.9371C42.8885 72.3015 43.0333 72.6509 43.2909 72.9086C43.5485 73.1662 43.898 73.311 44.2623 73.311C44.6267 73.311 44.9761 73.1662 45.2338 72.9086C45.4914 72.6509 45.6362 72.3015 45.6362 71.9371V54.9288H42.8885Z" />
        <path d="M27.0508 73.9882C26.4725 73.9906 25.9043 73.8369 25.4061 73.5432C24.6446 73.1039 24.0885 72.3803 23.86 71.5314C23.6314 70.6825 23.7491 69.7776 24.1871 69.0153L33.4459 52.9938H41.0986L29.9339 72.3435C29.7168 72.7202 29.4271 73.0501 29.0817 73.3141C28.7363 73.5781 28.342 73.7711 27.9215 73.8818C27.6372 73.9551 27.3445 73.9909 27.0508 73.9882ZM34.5681 54.9288L25.8608 69.9925C25.7685 70.1483 25.7081 70.3209 25.6832 70.5003C25.6582 70.6797 25.6693 70.8623 25.7157 71.0374C25.7629 71.2135 25.8445 71.3785 25.9558 71.5229C26.067 71.6674 26.2057 71.7884 26.3639 71.8791C26.5225 71.9786 26.6997 72.0447 26.8848 72.0732C27.0698 72.1018 27.2587 72.0922 27.4399 72.0451C27.6212 71.998 27.7908 71.9143 27.9385 71.7993C28.0862 71.6842 28.2088 71.5402 28.2989 71.376L37.7995 54.9288H34.5681Z" />
        <path d="M67.3852 54.4354H21.1395C20.8245 54.4354 20.5126 54.3729 20.222 54.2515C19.9313 54.1301 19.6676 53.9522 19.4463 53.7281C19.2249 53.504 19.0502 53.2382 18.9323 52.9461C18.8144 52.654 18.7557 52.3414 18.7595 52.0264V23.0019H69.7652V52.0264C69.769 52.3414 69.7103 52.654 69.5924 52.9461C69.4745 53.2382 69.2998 53.504 69.0784 53.7281C68.857 53.9522 68.5934 54.1301 68.3027 54.2515C68.012 54.3729 67.7002 54.4354 67.3852 54.4354ZM19.727 23.9694V52.0264C19.7231 52.2143 19.7568 52.4011 19.8261 52.5759C19.8953 52.7506 19.9988 52.9097 20.1303 53.044C20.2619 53.1783 20.4189 53.2849 20.5922 53.3577C20.7655 53.4305 20.9516 53.468 21.1395 53.4679H67.3852C67.5731 53.468 67.7592 53.4305 67.9325 53.3577C68.1058 53.2849 68.2628 53.1783 68.3944 53.044C68.5259 52.9097 68.6294 52.7506 68.6986 52.5759C68.7679 52.4011 68.8015 52.2143 68.7977 52.0264V23.9694H19.727Z" />
        <path d="M67.3851 54.9288H21.1395C20.3697 54.9288 19.6315 54.623 19.0872 54.0787C18.5429 53.5344 18.2371 52.7962 18.2371 52.0264V22.4892H70.2489V52.0264C70.249 52.7895 69.9485 53.522 69.4125 54.0652C68.8765 54.6084 68.1482 54.9187 67.3851 54.9288ZM20.2107 24.4241V52.0264C20.2107 52.283 20.3127 52.5291 20.4941 52.7105C20.6755 52.8919 20.9216 52.9939 21.1782 52.9939H67.3851C67.6417 52.9939 67.8878 52.8919 68.0693 52.7105C68.2507 52.5291 68.3526 52.283 68.3526 52.0264V24.4241H20.2107Z" />
        <path d="M69.2814 24.4241H19.2432C18.6884 24.4511 18.1339 24.3651 17.6133 24.1714C17.0927 23.9776 16.6169 23.6802 16.2147 23.297C15.8125 22.9139 15.4923 22.4531 15.2736 21.9425C15.0548 21.432 14.942 20.8823 14.942 20.3268C14.942 19.7714 15.0548 19.2217 15.2736 18.7111C15.4923 18.2005 15.8125 17.7397 16.2147 17.3566C16.6169 16.9735 17.0927 16.676 17.6133 16.4823C18.1339 16.2886 18.6884 16.2026 19.2432 16.2295H69.2814C69.8362 16.2026 70.3907 16.2886 70.9113 16.4823C71.4319 16.676 71.9077 16.9735 72.3099 17.3566C72.7121 17.7397 73.0323 18.2005 73.2511 18.7111C73.4699 19.2217 73.5827 19.7714 73.5827 20.3268C73.5827 20.8823 73.4699 21.432 73.2511 21.9425C73.0323 22.4531 72.7121 22.9139 72.3099 23.297C71.9077 23.6802 71.4319 23.9776 70.9113 24.1714C70.3907 24.3651 69.8362 24.4511 69.2814 24.4241ZM19.2432 18.1645C18.9471 18.1447 18.6501 18.1859 18.3705 18.2856C18.091 18.3853 17.8349 18.5413 17.6182 18.7441C17.4014 18.9468 17.2286 19.1919 17.1105 19.4642C16.9923 19.7364 16.9314 20.03 16.9314 20.3268C16.9314 20.6236 16.9923 20.9172 17.1105 21.1895C17.2286 21.4618 17.4014 21.7068 17.6182 21.9096C17.8349 22.1123 18.091 22.2684 18.3705 22.3681C18.6501 22.4678 18.9471 22.509 19.2432 22.4891H69.2814C69.5775 22.509 69.8746 22.4678 70.1541 22.3681C70.4336 22.2684 70.6897 22.1123 70.9065 21.9096C71.1232 21.7068 71.296 21.4618 71.4142 21.1895C71.5323 20.9172 71.5933 20.6236 71.5933 20.3268C71.5933 20.03 71.5323 19.7364 71.4142 19.4642C71.296 19.1919 71.1232 18.9468 70.9065 18.7441C70.6897 18.5413 70.4336 18.3853 70.1541 18.2856C69.8746 18.1859 69.5775 18.1447 69.2814 18.1645H19.2432Z" />
        <path d="M33.3201 48.6595C32.5107 48.6595 31.7195 48.4195 31.0465 47.9698C30.3735 47.5202 29.8489 46.881 29.5392 46.1332C29.2294 45.3854 29.1484 44.5626 29.3063 43.7687C29.4642 42.9748 29.854 42.2456 30.4263 41.6733C30.9987 41.101 31.7279 40.7112 32.5217 40.5533C33.3156 40.3954 34.1384 40.4764 34.8862 40.7862C35.634 41.0959 36.2732 41.6204 36.7229 42.2934C37.1725 42.9664 37.4126 43.7577 37.4126 44.5671C37.41 45.6517 36.978 46.6911 36.2111 47.4581C35.4442 48.225 34.4047 48.657 33.3201 48.6595ZM33.3201 42.4096C32.8934 42.4096 32.4763 42.5361 32.1215 42.7732C31.7667 43.0103 31.4902 43.3472 31.3269 43.7415C31.1636 44.1357 31.1208 44.5695 31.2041 44.988C31.2873 45.4065 31.4928 45.7909 31.7945 46.0927C32.0963 46.3944 32.4807 46.5999 32.8992 46.6831C33.3177 46.7664 33.7515 46.7236 34.1457 46.5603C34.54 46.3971 34.8769 46.1205 35.114 45.7657C35.3511 45.4109 35.4776 44.9938 35.4776 44.5671C35.4776 43.9949 35.2503 43.4461 34.8457 43.0415C34.4411 42.6369 33.8923 42.4096 33.3201 42.4096Z" />
        <path d="M49.3125 36.5467C49.1232 36.544 48.9388 36.4858 48.7821 36.3794C48.6255 36.2729 48.5036 36.1228 48.4315 35.9477C48.3594 35.7725 48.3403 35.5801 48.3765 35.3942C48.4127 35.2084 48.5027 35.0372 48.6353 34.902L54.1596 29.368C54.2496 29.2773 54.3566 29.2053 54.4745 29.1562C54.5924 29.1071 54.7188 29.0818 54.8465 29.0818C54.9743 29.0818 55.1007 29.1071 55.2186 29.1562C55.3365 29.2053 55.4435 29.2773 55.5334 29.368C55.7136 29.5492 55.8148 29.7944 55.8148 30.05C55.8148 30.3056 55.7136 30.5508 55.5334 30.7321L49.9994 36.2661C49.909 36.3558 49.8018 36.4267 49.684 36.4749C49.5661 36.523 49.4399 36.5474 49.3125 36.5467Z" />
        <path d="M54.8465 36.5467C54.7192 36.5474 54.593 36.523 54.4751 36.4749C54.3572 36.4267 54.25 36.3558 54.1596 36.2661L48.6159 30.7418C48.4574 30.5567 48.3746 30.3186 48.384 30.0751C48.3934 29.8316 48.4944 29.6007 48.6667 29.4284C48.839 29.2561 49.07 29.1551 49.3134 29.1457C49.5569 29.1363 49.795 29.2191 49.9801 29.3776L55.5141 34.9116C55.6943 35.0929 55.7954 35.3381 55.7954 35.5937C55.7954 35.8493 55.6943 36.0945 55.5141 36.2758C55.3347 36.4486 55.0956 36.5457 54.8465 36.5467Z" />
        <path d="M50.0865 48.2242C49.7209 48.2177 49.3605 48.1363 49.0277 47.9848C48.6948 47.8334 48.3966 47.6152 48.1516 47.3438L33.7361 31.6028C33.6028 31.4571 33.4178 31.3694 33.2207 31.3586C33.0235 31.3477 32.83 31.4146 32.6815 31.5448L27.699 35.9081C27.5048 36.0645 27.2578 36.1399 27.0095 36.1186C26.7611 36.0973 26.5305 35.9809 26.3658 35.7938C26.2011 35.6067 26.115 35.3632 26.1255 35.1141C26.1359 34.865 26.2421 34.6296 26.4219 34.4569L31.4141 30.0936C31.9445 29.6351 32.632 29.4001 33.332 29.438C34.0321 29.4759 34.6901 29.7837 35.168 30.2967L49.5834 46.0473C49.6984 46.1708 49.8514 46.2521 50.018 46.2784C50.1846 46.3047 50.3552 46.2745 50.5025 46.1925L54.2854 43.9576C54.5061 43.8268 54.7697 43.7889 55.0182 43.8524C55.2668 43.9159 55.48 44.0756 55.6108 44.2962C55.7417 44.5169 55.7795 44.7805 55.716 45.029C55.6525 45.2776 55.4929 45.4908 55.2722 45.6216L51.4603 47.8759C51.0409 48.1105 50.5671 48.2307 50.0865 48.2242Z" />
        <path d="M56.7042 48.5725C56.5098 48.5737 56.3184 48.5244 56.1488 48.4294C55.9792 48.3344 55.8372 48.197 55.7367 48.0307L52.573 42.6321C52.478 42.4656 52.428 42.2772 52.428 42.0855C52.428 41.8938 52.478 41.7054 52.573 41.5389C52.6731 41.3714 52.8147 41.2326 52.9843 41.1359C53.1538 41.0393 53.3454 40.9881 53.5405 40.9874H59.8001C59.9917 40.9852 60.1804 41.0334 60.3475 41.1271C60.5146 41.2208 60.6541 41.3568 60.752 41.5214C60.85 41.686 60.903 41.8735 60.9058 42.065C60.9085 42.2566 60.8608 42.4454 60.7676 42.6128L57.662 48.0597C57.5616 48.2219 57.4202 48.3548 57.252 48.4448C57.0838 48.5348 56.8948 48.5789 56.7042 48.5725ZM55.0111 42.9127L56.6945 45.8151L58.3392 42.9127H55.0111Z" />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect
            fill="white"
            height="59.0164"
            transform="translate(14.7541 16.2295)"
            width="59.0164"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
