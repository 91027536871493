// @flow
import * as Zen from 'lib/Zen';
import I18N from 'lib/I18N';
import Moment from 'models/core/wip/DateTime/Moment';
import { capitalize } from 'util/stringUtil';
import type AlertCaseCoreInfo from 'models/CaseManagementApp/AlertCaseCoreInfo';
import type { ExternalAlertActivity } from 'models/CaseManagementApp/ExternalAlert';
import type { Serializable } from 'lib/Zen';

type EventTypeMap = {
  ALERT: 'ALERT',
  DATA_ENTRY: 'DATA_ENTRY',
  EXTERNAL_ALERT_ACTIVITY: 'EXTERNAL_ALERT_ACTIVITY',
  GLOBAL: 'GLOBAL',
  METADATA_CHANGE: 'METADATA_CHANGE',
  STATUS_CHANGE: 'STATUS_CHANGE',
  USER: 'USER',
};

type EventType = $Keys<EventTypeMap>;

type RequiredValues = {
  created: Moment,
  name: string,

  /**
   * The event type determine how we will persist this event, and also what
   * we will load when someone clicks on the timeline item. Only GLOBAL,
   * USER, STATUS_CHANGE, and METADATA_CHANGE events are persisted to postgres
   * as part of the Case. All other events can be loaded on-the-fly by
   * querying others sources.
   */
  type: EventType,
};

type DefaultValues = {
  additionalInfo: Zen.Array<{ label: string, value: string }>,
  customIcon: string | void, // must be a valid glyphicon
  description: string,

  /** linked cases only exist for ALERT events */
  linkedCase: AlertCaseCoreInfo | void,
};

type AdditionalInfo = {
  customIcon?: string,
  metadata: $ReadOnlyArray<{ label: string, value: string }>,
};

type SerializedCaseEvent = {
  additionalInfo: AdditionalInfo,
  caseUri?: string,
  created: { $date: number }, // date is in miliseconds
  description: string,
  name: string,
  type: EventType,
};

const EVENT_TYPE_TO_GLYPHICON = {
  ALERT: 'glyphicon-alert',
  DATA_ENTRY: 'glyphicon-folder-open',
  EXTERNAL_ALERT_ACTIVITY: 'glyphicon-pencil',
  GLOBAL: 'glyphicon-flash',
  METADATA_CHANGE: 'glyphicon-info-sign',
  STATUS_CHANGE: 'glyphicon-pencil',
  USER: 'glyphicon-option-horizontal',
};

class CaseEvent extends Zen.BaseModel<CaseEvent, RequiredValues, DefaultValues>
  implements Serializable<SerializedCaseEvent> {
  static defaultValues: DefaultValues = {
    additionalInfo: Zen.Array.create(),
    customIcon: undefined,
    description: '',
    linkedCase: undefined,
  };

  static EventTypes: EventTypeMap = {
    ALERT: 'ALERT',
    DATA_ENTRY: 'DATA_ENTRY',
    EXTERNAL_ALERT_ACTIVITY: 'EXTERNAL_ALERT_ACTIVITY',
    GLOBAL: 'GLOBAL',
    METADATA_CHANGE: 'METADATA_CHANGE',
    STATUS_CHANGE: 'STATUS_CHANGE',
    USER: 'USER',
  };

  static deserialize(
    serializedCaseEvent: SerializedCaseEvent,
  ): Zen.Model<CaseEvent> {
    const {
      additionalInfo,
      created,
      description,
      name,
      type,
    } = serializedCaseEvent;
    return CaseEvent.create({
      description,
      name,
      type,
      additionalInfo: Zen.Array.create(additionalInfo.metadata),
      created: Moment.create(created.$date),
      customIcon: additionalInfo.customIcon,
    });
  }

  static sortEvents(
    events: Zen.Array<Zen.Model<CaseEvent>>,
    sortOrder: 'ASC' | 'DESC' = 'ASC',
  ): Zen.Array<Zen.Model<CaseEvent>> {
    return events.sort((event1, event2) => {
      if (sortOrder === 'ASC') {
        return event1.created().diff(event2.created());
      }
      return event2.created().diff(event1.created());
    });
  }

  /**
   * Create an event that represents when a case had data submitted in druid.
   * E.g. whenever a facility reported new data.
   */
  static createDataSubmissionEvent(timestampMs: number): Zen.Model<CaseEvent> {
    return CaseEvent.create({
      created: Moment.utc(timestampMs),
      name: I18N.text('Data Submitted'),
      type: CaseEvent.EventTypes.DATA_ENTRY,
    });
  }

  /**
   * Create an event that represents a user changing the status of a case.
   */
  static createStatusChangeEvent(
    newStatus: string,
    comments: string,
    source: string,
    username?: string,
  ): Zen.Model<CaseEvent> {
    const additionalInfo = [{ label: I18N.text('Source:'), value: source }];
    if (username) {
      additionalInfo.push({
        label: I18N.text('User:'),
        value: username,
      });
    }

    return CaseEvent.create({
      additionalInfo: Zen.Array.create(additionalInfo),
      created: Moment.create(),
      description: comments,
      name: newStatus,
      type: CaseEvent.EventTypes.STATUS_CHANGE,
    });
  }

  /**
   * Create an event from when a user changes the metadata of a case.
   */
  static createMetadataChangeEvent(values: {
    comments: string,
    metadataName: string,
    source: string,
    username: string,
  }): Zen.Model<CaseEvent> {
    const { comments, metadataName, source, username } = values;
    const additionalInfo = [{ label: I18N.textById('Source:'), value: source }];
    if (username) {
      additionalInfo.push({
        label: I18N.textById('User:'),
        value: username,
      });
    }

    return CaseEvent.create({
      additionalInfo: Zen.Array.create(additionalInfo),
      created: Moment.create(),
      description: comments,
      name: metadataName,
      type: CaseEvent.EventTypes.METADATA_CHANGE,
    });
  }

  /**
   * Create a user-defined event.
   */
  static createUserEvent(
    eventTitle: string,
    comments: string,
    customIcon: string | void,
    source: string,
    username: string,
  ): Zen.Model<CaseEvent> {
    return CaseEvent.create({
      customIcon,
      additionalInfo: Zen.Array.create([
        {
          label: I18N.textById('User:'),
          value: username,
        },
        { label: I18N.textById('Source:'), value: source },
      ]),
      created: Moment.create(),
      description: comments,
      name: eventTitle,
      type: CaseEvent.EventTypes.USER,
    });
  }

  /**
   * Create an event that represents an alert trigger.
   */
  static createAlertEvent(
    alertCaseCoreInfo: AlertCaseCoreInfo,
  ): Zen.Model<CaseEvent> {
    const alert = alertCaseCoreInfo.alertNotification();
    const additionalInfo = Zen.Array.create([
      {
        label: I18N.textById('Why?'),
        value: alertCaseCoreInfo.getAlertExplanation(),
      },
      {
        label: I18N.textById('Source:'),
        value: alertCaseCoreInfo.alertSource(),
      },
      {
        label: I18N.text('Status:'),
        value: alertCaseCoreInfo.status().label(),
      },
    ]);
    return CaseEvent.create({
      additionalInfo,
      created: Moment.create(alert.generationDate()),
      linkedCase: alertCaseCoreInfo,
      name: `${alert.dimensionValue()}: ${alert.title()}`,
      type: CaseEvent.EventTypes.ALERT,
    });
  }

  /**
   * Create an event for something outside of our system entirely.
   * Eg. Cyclone Idai
   */
  static createExternalEvent(
    eventTitle: string,
    comments: string,
    customIcon: string | void,
    created: string,
  ): Zen.Model<CaseEvent> {
    return CaseEvent.create({
      customIcon,
      created: Moment.create(created),
      description: comments,
      name: eventTitle,
      type: CaseEvent.EventTypes.GLOBAL,
    });
  }

  static createExternalAlertActivityEvent(
    externalActivity: ExternalAlertActivity,
    source: string,
  ): Zen.Model<CaseEvent> {
    const { action, comments, timestamp, user } = externalActivity;
    return CaseEvent.create({
      additionalInfo: Zen.Array.create([
        { label: I18N.textById('User:'), value: user || 'S/I' },
        { label: I18N.textById('Source:'), value: source },
      ]),
      created: Moment.create(timestamp),
      description: comments,
      name: capitalize(action),
      type: CaseEvent.EventTypes.EXTERNAL_ALERT_ACTIVITY,
    });
  }

  /**
   * Not all events should be serializable to postgres. Most events are
   * created on the fly by querying from other sources. The only events
   * we should be serializing to postgres are those whose type is GLOBAL,
   * USER, STATUS_CHANGE, and METADATA_CHANGE.
   */
  isSerializableEvent(): boolean {
    return (
      this._.type() in ['GLOBAL', 'USER', 'STATUS_CHANGE', 'METADATA_CHANGE']
    );
  }

  /**
   * If customIcon is valid, use that. If customIcon is invalid, throw an error.
   * Otherwise, use the default icon for this event type.
   * @returns {string|*} - icon to display for this event
   */
  getEventIconClass(): string {
    const customIcon = this._.customIcon();
    if (customIcon) {
      if (!customIcon.startsWith('glyphicon')) {
        throw new Error(
          '[CaseEvent] customIcon must be a valid glyphicon class name',
        );
      }
      return customIcon;
    }

    return EVENT_TYPE_TO_GLYPHICON[this._.type()];
  }

  serialize(): SerializedCaseEvent {
    const {
      additionalInfo,
      created,
      customIcon,
      description,
      name,
      type,
    } = this.modelValues();
    const serializedAdditionalInfo: AdditionalInfo = {
      metadata: additionalInfo.arrayView(),
    };
    if (customIcon && customIcon !== EVENT_TYPE_TO_GLYPHICON[type]) {
      serializedAdditionalInfo.customIcon = customIcon;
    }
    return {
      description,
      name,
      type,
      additionalInfo: serializedAdditionalInfo,
      created: { $date: created.valueOf() },
    };
  }
}

export default ((CaseEvent: $Cast): Class<Zen.Model<CaseEvent>>);
