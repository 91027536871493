// @flow
import * as React from 'react';

import type { SVGProps } from 'components/ui/Icon/internal/SVGs/types';

export default function AIPresent(props: SVGProps): React.Element<'svg'> {
  return (
    <svg
      fill="none"
      height="16"
      width="16"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M2.302 15.383h10.285c.631 0 1.143-.511 1.143-1.142V6.812H1.16v7.429c0 .63.511 1.142 1.143 1.142Z"
        fill="#fff"
      />
      <path
        d="M12.587 3.955H2.302c-.632 0-1.143.512-1.143 1.143v1.714H13.73V5.098c0-.631-.512-1.143-1.143-1.143Z"
        fill="#D7E0FF"
      />
      <path
        d="M5.242 3.955h-2.94a1.143 1.143 0 0 0-1.143 1.143v9.143a1.143 1.143 0 0 0 1.143 1.143h10.285c.631 0 1.143-.512 1.143-1.143V9.669M1.159 6.812h6"
        stroke="#4147D5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="m4.016 12.527 1.714-1.715-1.714-1.714M7.444 12.526H9.16"
        stroke="#4147D5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.888 4.54c-.4-.069-.4-.645 0-.715a3.63 3.63 0 0 0 2.924-2.8l.024-.11c.087-.397.652-.399.742-.003l.03.128a3.649 3.649 0 0 0 2.931 2.784c.403.07.403.648 0 .719a3.649 3.649 0 0 0-2.932 2.783l-.03.129c-.09.395-.654.393-.74-.004l-.025-.11a3.63 3.63 0 0 0-2.924-2.8Z"
        fill="#fff"
      />
      <path
        d="M7.888 4.54c-.4-.069-.4-.645 0-.715a3.63 3.63 0 0 0 2.924-2.8l.024-.11c.087-.397.652-.399.742-.003l.03.128a3.649 3.649 0 0 0 2.931 2.784c.403.07.403.648 0 .719a3.649 3.649 0 0 0-2.932 2.783l-.03.129c-.09.395-.654.393-.74-.004l-.025-.11a3.63 3.63 0 0 0-2.924-2.8Z"
        stroke="#4147D5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
