// @flow
import * as React from 'react';
import classNames from 'classnames';

import {
  GROUP_SET_OPERATION_LABEL,
  SET_OPERATION_LABEL,
} from 'components/common/QueryBuilder/CustomizableIndicatorTag/IndicatorCustomizationModule/CalculationCustomizationBlock/CohortCustomizationBlock/CohortCreationPanel/constants';
import type { SetOperation } from 'models/core/wip/Calculation/CohortCalculation/types';
import type { StyleObject } from 'types/jsCore';

type Operation = {
  // If the operation is a group operation (i.e. it can operate on more than two
  // segments) we use the group labels (ALL/ANY) rather than the default set
  // operation labels (AND/OR)
  isGroupOperation: boolean,

  operator: SetOperation,
};

type Props = {
  borderless?: boolean,
  operations: $ReadOnlyArray<Operation>,
  style: StyleObject,
};

export default function CohortGroupDiagramSetOperationPill({
  borderless = false,
  operations,
  style,
}: Props): React.Node {
  const className = classNames('ca-cohort-group-diagram__set-operation-pill', {
    'ca-cohort-group-diagram__set-operation-pill--borderless': borderless,
  });

  return (
    <div className={className} style={style}>
      {operations
        .map(operation => {
          const { isGroupOperation, operator } = operation;
          return isGroupOperation
            ? GROUP_SET_OPERATION_LABEL[operator]
            : SET_OPERATION_LABEL[operator];
        })
        .join('\n')}
    </div>
  );
}
