// @flow
import * as Zen from 'lib/Zen';
import AtLeastFilter from 'models/core/wip/Calculation/CohortCalculation/NumericValueCohortFilterItem/AtLeastFilter';
import AtMostFilter from 'models/core/wip/Calculation/CohortCalculation/NumericValueCohortFilterItem/AtMostFilter';
import BetweenFilter from 'models/core/wip/Calculation/CohortCalculation/NumericValueCohortFilterItem/BetweenFilter';
import EqualToFilter from 'models/core/wip/Calculation/CohortCalculation/NumericValueCohortFilterItem/EqualToFilter';
import GreaterThanFilter from 'models/core/wip/Calculation/CohortCalculation/NumericValueCohortFilterItem/GreaterThanFilter';
import LessThanFilter from 'models/core/wip/Calculation/CohortCalculation/NumericValueCohortFilterItem/LessThanFilter';
import NumericValueCohortFilter from 'models/core/wip/Calculation/CohortCalculation/NumericValueCohortFilter';

export interface NumericValueCohortFilterWrapper {
  filter(): NumericValueCohortFilter;
}

export type NumericValueCohortFilterItem =
  | AtLeastFilter
  | AtMostFilter
  | BetweenFilter
  | EqualToFilter
  | GreaterThanFilter
  | LessThanFilter;

export default class NumericValueCohortFilterItemUtil {
  static deserialize(
    values: Zen.Serialized<NumericValueCohortFilterItem>,
  ): NumericValueCohortFilterItem | void {
    switch (values.type) {
      case 'AT_LEAST':
        return AtLeastFilter.deserialize(values);
      case 'AT_MOST':
        return AtMostFilter.deserialize(values);
      case 'BETWEEN':
        return BetweenFilter.deserialize(values);
      case 'EQUAL_TO':
        return EqualToFilter.deserialize(values);
      case 'GREATER_THAN':
        return GreaterThanFilter.deserialize(values);
      case 'LESS_THAN':
        return LessThanFilter.deserialize(values);
      case undefined:
        // NOTE(david): When we initially added these filters, we neglected to
        // the type property and deserialization logic. We cannot accurately
        // deserialize any models that were serialized and stored before type
        // was added so we return no filter here.
        // TODO(david): Remove this in April 2021 or later and do an audit
        // of popular nacosa dashboards to make sure that doing so doesn't cause
        // any crashes.
        return undefined;
      default:
        throw new Error(
          `[NumericValueCohortFilterItemUtil] Invalid type provided during deserialization: ${values.type}`,
        );
    }
  }
}
