// @flow
import { maybeOpenNewTab } from 'util/util';
/**
 * This file holds utility functions for constructing case managemeent
 * URLs and navigating to different part of the case management app.
 */

export function getDruidCasePageURL(
  caseType: string,
  primaryDruidDimensionValues: { +[dimensionName: string]: string, ... },
): string {
  const dimensionArgs = Object.keys(primaryDruidDimensionValues).map(
    dimensionName =>
      `${dimensionName}=${primaryDruidDimensionValues[dimensionName]}`,
  );
  return `/case-management?caseType=${caseType}&${dimensionArgs.join('&')}`;
}

export function openCaseManagementHome(
  mouseEvent?: SyntheticMouseEvent<HTMLElement>,
) {
  maybeOpenNewTab('case-management', mouseEvent ? mouseEvent.metaKey : false);
}

export function openNewDruidCaseTab(
  caseType: string,
  primaryDruidDimensionValues: { +[dimensionName: string]: string, ... },
): void {
  maybeOpenNewTab(
    getDruidCasePageURL(caseType, primaryDruidDimensionValues),
    true,
  );
}

export function openNewAlertCaseTab(alertId: string): void {
  maybeOpenNewTab(`case-management?caseType=alert&id=${alertId}`, true);
}
