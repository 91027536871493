// @flow
import * as Zen from 'lib/Zen';
import NumericValueCohortFilter from 'models/core/wip/Calculation/CohortCalculation/NumericValueCohortFilter';
import type { NumericValueCohortFilterWrapper } from 'models/core/wip/Calculation/CohortCalculation/NumericValueCohortFilterItem/NumericValueCohortFilterItemUtil';
import type { Serializable } from 'lib/Zen';

type Values = {
  maxValue: number,
  minValue: number,
};

type SerializedBetweenFilter = {
  maxValue: number,
  minValue: number,
  type: 'BETWEEN',
};

class BetweenFilter extends Zen.BaseModel<BetweenFilter, Values>
  implements
    Serializable<SerializedBetweenFilter>,
    NumericValueCohortFilterWrapper {
  static tag: 'BETWEEN' = 'BETWEEN';
  tag: 'BETWEEN' = 'BETWEEN';

  static deserialize(
    serializedBetweenFilter: SerializedBetweenFilter,
  ): Zen.Model<BetweenFilter> {
    const { maxValue, minValue } = serializedBetweenFilter;

    return BetweenFilter.create({ maxValue, minValue });
  }

  filter(): NumericValueCohortFilter {
    return NumericValueCohortFilter.create({
      lowerBound: this._.minValue(),
      lowerBoundStrict: false,
      upperBound: this._.maxValue(),
      upperBoundStrict: false,
    });
  }

  serialize(): SerializedBetweenFilter {
    return {
      maxValue: this._.maxValue(),
      minValue: this._.minValue(),
      type: this.tag,
    };
  }
}

export default ((BetweenFilter: $Cast): Class<Zen.Model<BetweenFilter>>);
