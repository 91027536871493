// @flow
import * as React from 'react';

import AddItemButton from 'components/common/QueryBuilder/CustomizableIndicatorTag/IndicatorCustomizationModule/CalculationCustomizationBlock/CohortCustomizationBlock/common/AddItemButton';
import AtLeastFilter from 'models/core/wip/Calculation/CohortCalculation/NumericValueCohortFilterItem/AtLeastFilter';
import CohortCreationContext from 'components/common/QueryBuilder/CustomizableIndicatorTag/IndicatorCustomizationModule/CalculationCustomizationBlock/CohortCustomizationBlock/CohortCreationPanel/CohortCreationContext';
import DimensionValueFilterOption from 'components/common/QueryBuilder/CustomizableIndicatorTag/IndicatorCustomizationModule/CalculationCustomizationBlock/CohortCustomizationBlock/CohortCreationPanel/CohortSegmentRow/DimensionValueFilterOption';
import I18N from 'lib/I18N';
import IndicatorOption from 'components/common/QueryBuilder/CustomizableIndicatorTag/IndicatorCustomizationModule/CalculationCustomizationBlock/CohortCustomizationBlock/CohortCreationPanel/CohortSegmentRow/IndicatorOption';
import NewFilterOption from 'components/common/QueryBuilder/CustomizableIndicatorTag/IndicatorCustomizationModule/CalculationCustomizationBlock/CohortCustomizationBlock/CohortCreationPanel/CohortSegmentRow/NewFilterOption';
import NumericFilterOption from 'components/common/QueryBuilder/CustomizableIndicatorTag/IndicatorCustomizationModule/CalculationCustomizationBlock/CohortCustomizationBlock/CohortCreationPanel/CohortSegmentRow/NumericFilterOption';
import RemoveItemButton from 'components/ui/RemoveItemButton';
import TimeIntervalOption from 'components/common/QueryBuilder/CustomizableIndicatorTag/IndicatorCustomizationModule/CalculationCustomizationBlock/CohortCustomizationBlock/CohortCreationPanel/CohortSegmentRow/TimeIntervalOption';
import autobind from 'decorators/autobind';
import type CohortSegment from 'models/core/wip/Calculation/CohortCalculation/CohortSegment';

type DefaultProps = {
  onRemoveSegment: CohortSegment => void,
  removable: boolean,
  rowName: string | void,
};

type Props = {
  ...DefaultProps,
  indicatorTitle: React.Node,
  onSegmentChange: (
    newSegment: CohortSegment,
    originalSegment: CohortSegment,
  ) => void,
  segment: CohortSegment,
};

type State = {
  addingNewFilter: boolean,
  hovering: boolean,
  showNumericFilterCustomizationModule: boolean,
};

export default class CohortSegmentRow extends React.PureComponent<
  Props,
  State,
> {
  static defaultProps: DefaultProps = {
    onRemoveSegment: () => {},
    removable: false,
    rowName: undefined,
  };

  static contextType: typeof CohortCreationContext = CohortCreationContext;

  state: State = {
    addingNewFilter: false,
    hovering: false,
    showNumericFilterCustomizationModule: false,
  };

  @autobind
  onHoverStart() {
    this.setState({ hovering: true });
  }

  @autobind
  onHoverEnd() {
    this.setState({ hovering: false });
  }

  @autobind
  onAddNewFilterStart() {
    this.setState({ addingNewFilter: true });
  }

  @autobind
  onAddNewFilterEnd() {
    this.setState({ addingNewFilter: false });
  }

  @autobind
  onSegmentChange(newSegment: CohortSegment) {
    const { onSegmentChange, segment } = this.props;
    onSegmentChange(newSegment, segment);
  }

  @autobind
  onRemoveSegment() {
    const { onRemoveSegment, segment } = this.props;
    onRemoveSegment(segment);
  }

  @autobind
  onNumericFilterAdd() {
    const { segment } = this.props;
    const numericFilter = segment.numericValueCohortFilter();

    // If no new numeric filter exists then we add a new one otherwise we open
    // the existing filter for editing
    if (numericFilter === undefined) {
      this.onSegmentChange(
        segment.numericValueCohortFilter(AtLeastFilter.create({ value: 1 })),
      );
    }

    this.setState({ showNumericFilterCustomizationModule: true });
    this.onAddNewFilterEnd();
  }

  @autobind
  onNumericFilterTagClick() {
    this.setState({ showNumericFilterCustomizationModule: true });
  }

  @autobind
  onRequestCloseNumericFilterCustomizationModule() {
    this.setState({ showNumericFilterCustomizationModule: false });
  }

  maybeRenderAddFilterButton(): React.Node {
    if (!this.state.hovering) {
      return null;
    }

    return (
      <AddItemButton
        className="cohort-segment-row__add-filter-button"
        onClick={this.onAddNewFilterStart}
        text={I18N.text('Add filter')}
      />
    );
  }

  maybeRenderRemoveSegmentButton(): React.Node {
    if (!this.props.removable || !this.state.hovering) {
      return null;
    }

    return (
      <RemoveItemButton
        onClick={this.onRemoveSegment}
        tooltipText={I18N.text('Remove segment')}
      />
    );
  }

  maybeRenderNewFilterItem(): React.Node {
    if (!this.state.addingNewFilter) {
      return null;
    }

    const { segment } = this.props;
    const filters = segment.filters();
    return (
      <NewFilterOption
        onFilterItemAdd={filter => {
          this.onSegmentChange(segment.filters(filters.push(filter)));
          this.onAddNewFilterEnd();
        }}
        onFilterItemRemove={this.onAddNewFilterEnd}
        onNumericFilterAdd={this.onNumericFilterAdd}
      />
    );
  }

  maybeRenderRowName(): React.Node {
    const { rowName } = this.props;
    if (rowName === undefined) {
      return null;
    }

    return (
      <div className="cohort-segment-row__row-name-block">
        <div className="cohort-segment-row__row-name">{rowName}</div>
        <div className="cohort-segment-row__row-border" />
      </div>
    );
  }

  render(): React.Node {
    const { indicatorTitle, segment } = this.props;
    const { showNumericFilterCustomizationModule } = this.state;
    const { fieldHierarchyLoaded, fieldHierarchyRoot } = this.context;
    return (
      <div
        className="cohort-segment-row"
        onMouseLeave={this.onHoverEnd}
        onMouseMove={this.onHoverStart}
      >
        {this.maybeRenderRowName()}
        <div className="cohort-segment-row__row-option-block">
          <IndicatorOption
            hierarchyLoaded={fieldHierarchyLoaded}
            hierarchyRoot={fieldHierarchyRoot}
            onSegmentChange={this.onSegmentChange}
            segment={segment}
            title={indicatorTitle}
          />
          <NumericFilterOption
            onRequestCloseCustomizationModule={
              this.onRequestCloseNumericFilterCustomizationModule
            }
            onSegmentChange={this.onSegmentChange}
            onTagClick={this.onNumericFilterTagClick}
            segment={segment}
            showCustomizationModule={showNumericFilterCustomizationModule}
          />
          <TimeIntervalOption
            onSegmentChange={this.onSegmentChange}
            segment={segment}
          />
          <DimensionValueFilterOption
            onSegmentChange={this.onSegmentChange}
            segment={segment}
          />
          {this.maybeRenderNewFilterItem()}
          {this.maybeRenderAddFilterButton()}
          {this.maybeRenderRemoveSegmentButton()}
        </div>
      </div>
    );
  }
}
