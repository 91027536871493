// @flow
import Promise from 'bluebird';
import invariant from 'invariant';

import APIService, { API_VERSION } from 'services/APIService';
import CachedMapService from 'services/wip/CachedMapService';
import CategoryService from 'services/wip/CategoryService';
// no way to avoid this circular dependency unfortunately
// eslint-disable-next-line import/no-cycle
import Dimension from 'models/core/wip/Dimension';
import autobind from 'decorators/autobind';
import { convertIDToURI, convertURIToID } from 'services/wip/util';
import type { APIVersion, HTTPService } from 'services/APIService';
import type { Cache, RejectFn, ResolveFn } from 'services/wip/CachedMapService';
import type { URI, URIConverter } from 'services/types/api';

class DimensionService extends CachedMapService<Dimension>
  implements URIConverter {
  apiVersion: APIVersion = API_VERSION.V2;
  endpoint: string;
  _httpService: HTTPService;
  _dimensionIdLookup: { [number]: Dimension };

  constructor(httpService: HTTPService, includeHiddenDimensions: boolean) {
    super();
    this.endpoint = includeHiddenDimensions
      ? 'query/dimensions?include_hidden=true'
      : 'query/dimensions';
    this._httpService = httpService;
  }

  buildCache(
    resolve: ResolveFn<Dimension>,
    reject: RejectFn,
  ): Promise<Cache<Dimension>> {
    return Promise.all([
      this._httpService.get(this.apiVersion, this.endpoint),
      CategoryService.getAll(),
    ])
      .then(([rawDimensionList]) => {
        const dimensionMappingCache = {};
        rawDimensionList.forEach(rawDimension => {
          const {
            description,
            dimensionCode,
            id,
            nameTranslations,
          } = rawDimension;
          const categories = rawDimension.categoryMapping.map(mapping => ({
            category: CategoryService.UNSAFE_get(mapping.categoryId),
            ordering: mapping.ordering,
          }));
          const dimension = Dimension.fromObject({
            categories,
            description,
            dimensionCode,
            id,
            nameTranslations,
          });
          dimensionMappingCache[dimension.dimensionCode()] = dimension;
        });
        resolve(dimensionMappingCache);
      })
      .catch(reject);
  }

  convertURIToID(uri: URI): string {
    return convertURIToID(uri, this.apiVersion, this.endpoint);
  }

  convertIDToURI(id: string): URI {
    return convertIDToURI(id, this.apiVersion, this.endpoint);
  }

  /**
   * Retrive a single value using `id` as a key instead of `dimensionCode`
   */
  @autobind
  UNSAFE_forceGetById(id: number): Dimension {
    if (!this._dimensionIdLookup) {
      this._dimensionIdLookup = {};
      this.UNSAFE_getAll().forEach(dimension => {
        this._dimensionIdLookup[dimension.id()] = dimension;
      });
    }
    invariant(
      this._dimensionIdLookup[id] !== undefined,
      `Unable to find value in cache for id: ${id}`,
    );
    return this._dimensionIdLookup[id];
  }

  /**
   * Fetch the ordered list of matched dimensions. They are ordered by the hierarchy with
   * non-hierarchical matched dimensions last.
   */
  @autobind
  getMatchedDimensions(): Promise<$ReadOnlyArray<Dimension>> {
    return this._httpService
      .get(this.apiVersion, 'query/dimensions/matched')
      .then(serializedDimensions =>
        serializedDimensions.map(Dimension.UNSAFE_deserialize),
      );
  }
}

export const FullDimensionService: DimensionService = new DimensionService(
  APIService,
  true,
);

export default (new DimensionService(APIService, false): DimensionService);
