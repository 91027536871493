// @flow
import * as Zen from 'lib/Zen';
import HierarchyItem from 'models/ui/HierarchicalSelector/HierarchyItem';
import type { NamedItem } from 'models/ui/HierarchicalSelector/types';

/**
 * The MutableHierarchyItem is used to efficiently build a HierarchyTree without
 * the cost and complexity of an immutable ZenModel HierarchyItem. When the tree
 * is finished being built, it will call `finalize()` on the
 * MutableHierarchyItem to produce an immutable HierarchyItem. Once `finalize`
 * has been called, you can no longer mutate the MutableHierarchyItem instance.
 * Subsequent calls to `finalize` will return the same immutable instance.
 */
export default class MutableHierarchyItem<T: NamedItem> {
  +id: string;
  +children: Array<MutableHierarchyItem<T>>;
  +metadata: T;
  immutableItem: HierarchyItem<T> | void = undefined;

  constructor(
    id: string,
    children: Array<MutableHierarchyItem<T>> = [],
    metadata: any = undefined,
  ) {
    this.id = id;
    this.children = children;
    this.metadata = metadata;
  }

  addChild(child: MutableHierarchyItem<T>): void {
    if (this.immutableItem !== undefined) {
      throw new Error(
        '[MutableHierarchyItem] Attempting to add child to finalized node.',
      );
    }

    this.children.push(child);
  }

  // Sorting when finalizing is optional since sometimes it's sorted as it's built. Within sorting,
  // it's possible to only sort by tag (put the sub-folders first) and/ or by name (sort by name
  // alphabetically).
  finalize(
    sortChildrenByTag: boolean = false,
    sortChildrenByName: boolean = false,
  ): HierarchyItem<T> {
    if (this.immutableItem === undefined) {
      // Dont set children if none exist.
      const maybeSortedChildren =
        sortChildrenByTag || sortChildrenByName
          ? this.children.sort((a, b) => {
              // If not sorting by tag or tags are the same, then sort by name.
              // Tag sorting takes first priority.
              if (!sortChildrenByTag || a.metadata.tag === b.metadata.tag) {
                // If not sorting by name, then the records are equal. Otherwise,
                // sort by name.
                return !sortChildrenByName
                  ? 0
                  : a.metadata.name().localeCompare(b.metadata.name());
              }
              // If sorting by tag, then LINKED_CATEGORY should come first.
              return sortChildrenByTag && a.metadata.tag === 'LINKED_CATEGORY'
                ? -1
                : 1;
            })
          : this.children;

      const finalChildren =
        maybeSortedChildren.length > 0
          ? Zen.Array.create(
              maybeSortedChildren.map(child =>
                child.finalize(sortChildrenByTag, sortChildrenByName),
              ),
            )
          : undefined;

      this.immutableItem = HierarchyItem.create({
        children: finalChildren,
        id: this.id,
        metadata: this.metadata,
      });
    }
    return this.immutableItem;
  }
}
