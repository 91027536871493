// @flow
import Promise from 'bluebird';

import * as Zen from 'lib/Zen';
import CohortSegment from 'models/core/wip/Calculation/CohortCalculation/CohortSegment';
import type { Serializable } from 'lib/Zen';
import type { SerializedCohortSegmentForQuery } from 'models/core/wip/Calculation/CohortCalculation/CohortSegment';
import type { SetOperation } from 'models/core/wip/Calculation/CohortCalculation/types';

type DefaultValues = {
  +additionalSegments: Zen.Array<CohortSegment>,
  +innerOperation: SetOperation,
  /* A human-readable name for the cohort group */
  +name: string | void,
  +outerOperation: SetOperation,
  +primarySegment: CohortSegment,
};

type SerializedCohortGroup = {
  additionalSegments: $ReadOnlyArray<Zen.Serialized<CohortSegment>>,
  innerOperation: SetOperation,
  name: string | void,
  outerOperation: SetOperation,
  primarySegment: Zen.Serialized<CohortSegment>,
};

export type SerializedCohortGroupForQuery = {
  additionalSegments: $ReadOnlyArray<SerializedCohortSegmentForQuery>,
  innerOperation: SetOperation,
  outerOperation: SetOperation,
  primarySegment: SerializedCohortSegmentForQuery,
};

/**
 * A cohort group is used to find values that match a complex set of segment
 * rules. It is essentially a simplified interface for combining cohort segments
 * with set operations.
 *
 * Example:
 *   Primary segment:
 *     - Select all rows where a sex worker was visited in the last 14 days in
 *       Province X.
 *   AND [Outer operation] (
 *     - Select all rows where a sex worker tested negative for HIV in the last
 *       12 months.
 *     OR [Inner operation]
 *     - Select all rows where a sex worker attended group counseling in the
 *       last 2 months.
 *     OR [Inner operation]
 *     - Select all rows where a sex worker received condoms from an outreach in
 *       the last 2 months.
 *   )
 */
class CohortGroup extends Zen.BaseModel<CohortGroup, {}, DefaultValues>
  implements Serializable<SerializedCohortGroup> {
  static defaultValues: DefaultValues = {
    additionalSegments: Zen.Array.create(),
    innerOperation: 'INTERSECT',
    name: undefined,
    outerOperation: 'INTERSECT',
    primarySegment: CohortSegment.create({}),
  };

  static deserializeAsync(
    values: SerializedCohortGroup,
  ): Promise<Zen.Model<CohortGroup>> {
    const {
      additionalSegments,
      innerOperation,
      name,
      outerOperation,
      primarySegment,
    } = values;
    return Promise.all([
      CohortSegment.deserializeAsync(primarySegment),
      Promise.all(additionalSegments.map(CohortSegment.deserializeAsync)),
    ]).then(([deserializedPrimarySegment, deserializedAdditionalSegments]) =>
      CohortGroup.create({
        innerOperation,
        name,
        outerOperation,
        additionalSegments: Zen.Array.create(deserializedAdditionalSegments),
        primarySegment: deserializedPrimarySegment,
      }),
    );
  }

  static UNSAFE_deserialize(
    values: SerializedCohortGroup,
  ): Zen.Model<CohortGroup> {
    const {
      additionalSegments,
      innerOperation,
      name,
      outerOperation,
      primarySegment,
    } = values;
    return CohortGroup.create({
      innerOperation,
      name,
      outerOperation,
      additionalSegments: Zen.Array.create(
        additionalSegments.map(CohortSegment.UNSAFE_deserialize),
      ),
      primarySegment: CohortSegment.UNSAFE_deserialize(primarySegment),
    });
  }

  serialize(): SerializedCohortGroup {
    return {
      additionalSegments: this._.additionalSegments().mapValues(s =>
        s.serialize(),
      ),
      innerOperation: this._.innerOperation(),
      name: this._.name(),
      outerOperation: this._.outerOperation(),
      primarySegment: this._.primarySegment().serialize(),
    };
  }

  serializeForQuery(): SerializedCohortGroupForQuery {
    return {
      additionalSegments: this._.additionalSegments().mapValues(s =>
        s.serializeForQuery(),
      ),
      innerOperation: this._.innerOperation(),
      outerOperation: this._.outerOperation(),
      primarySegment: this._.primarySegment().serializeForQuery(),
    };
  }
}

export default ((CohortGroup: $Cast): Class<Zen.Model<CohortGroup>>);
