// @flow
import * as React from 'react';

import type { SVGProps } from 'components/ui/Icon/internal/SVGs/types';

export default function HistogramVisualization(
  props: SVGProps,
): React.Element<'svg'> {
  return (
    <svg
      color="#2D80C2"
      height="64px"
      viewBox="0 0 64 64"
      width="64px"
      {...props}
    >
      <g fill="currentColor">
        <path d="M64 64H0v-4.46a1.36 1.36 0 011.36-1.36H3.1v-7.6a1.36 1.36 0 011.36-1.35h1.75v-10a1.36 1.36 0 011.35-1.36h2.33a1.36 1.36 0 011.36 1.35v2.88H13a1.35 1.35 0 011.35 1.35v6h1.75a1.35 1.35 0 011.35 1.36v1.27h1.75a1.36 1.36 0 011.36 1.35v2.42h1.16v-.19a1.36 1.36 0 011.36-1.36h2.33a1.35 1.35 0 011.35 1.36v.58h1.75a1.36 1.36 0 011.36 1.36v.58H31v-2.52a1.36 1.36 0 011.35-1.36h1.75v-18a1.36 1.36 0 011.36-1.35h1.75v-23.9a1.34 1.34 0 011.38-1.35h1.75V1.35A1.36 1.36 0 0141.69 0H44a1.36 1.36 0 011.36 1.36v1.55h1.75a1.35 1.35 0 011.35 1.35v12.22h1.75a1.36 1.36 0 011.36 1.36v3.49h1.75a1.36 1.36 0 011.35 1.36v24.83h1.75a1.34 1.34 0 011.35 1.35v7.37h1.75a1.36 1.36 0 011.38 1.36v1.55h1.74A1.36 1.36 0 0164 60.51zM1.94 62.06h60.12v-1H59v-2.88h-3.15v-8.73h-3.1V23.27h-3.1v-4.85h-3.1V4.85h-3.11V1.94h-1.16v9.7h-3.1v25.21h-3.11v19.39H33v3.88h-5v-1.94h-3.18v-1.94h-1.16v1.55h-5V54h-3.1v-2.61h-3.15V44h-3.1v-4.24H8.15v11.41H5v8.95H1.94z" />
        <path
          d="M62.64 60.12h-2.71V57.6a.39.39 0 00-.39-.39h-2.72v-8.34a.39.39 0 00-.38-.39h-2.72V22.69a.39.39 0 00-.38-.39h-2.72v-4.46a.39.39 0 00-.39-.39h-2.71V4.26a.39.39 0 00-.39-.38h-2.72V1.36A.38.38 0 0044 1h-2.31a.38.38 0 00-.38.38v9.32h-2.72a.38.38 0 00-.38.38v24.8h-2.72a.39.39 0 00-.39.38v19h-2.72a.38.38 0 00-.38.39v3.49h-3.1V57.6a.39.39 0 00-.39-.39h-2.72v-1.55a.39.39 0 00-.38-.39h-2.33a.38.38 0 00-.39.39v1.16h-3.1v-3.39a.38.38 0 00-.39-.38h-2.72v-2.24a.39.39 0 00-.38-.39h-2.72v-7a.38.38 0 00-.38-.38h-2.72v-3.9a.38.38 0 00-.39-.38H7.56a.38.38 0 00-.38.39v11H4.46a.39.39 0 00-.39.38v8.57H1.36a.39.39 0 00-.39.39V63H63v-2.49a.38.38 0 00-.36-.39z"
          fillOpacity="0.4"
        />
      </g>
    </svg>
  );
}
