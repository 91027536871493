// @flow
import * as React from 'react';
import Promise from 'bluebird';

import * as Zen from 'lib/Zen';
import CaseManagementService from 'services/CaseManagementService';
import DruidCaseType from 'models/CaseManagementApp/DruidCaseType';
import getCaseManagementEnabledStatus from 'services/CaseManagementService/getCaseManagementEnabledStatus';

/**
 * This context holds global case management info for use in a QueryResult
 * and its SettingsModal.
 */
type CaseManagementInfo = {
  // only used for case management-related settings
  +allDruidCaseTypes: Zen.Map<DruidCaseType>,

  // does the user have access to case management?
  +canUserViewCaseManagement: boolean,
};

// HACK(stephen): Cache the case management info response so that we don't have
// to hit the server a bunch of times to receive the same information. Note that
// this would mean a user would need to refresh the page after they are granted
// access to case management (or after it is enabled). But that is the
// assumption already.
let CACHED_CASE_MANAGEMENT_INFO: CaseManagementInfo | void;

// TODO(pablo): using this function is an antipattern and ideally should be
// extracted to a custom hook, but since componeents that use this context are
// not functional components, they cannot use hooks.
export function loadCaseManagementInfo(): Promise<CaseManagementInfo> {
  if (CACHED_CASE_MANAGEMENT_INFO !== undefined) {
    return Promise.resolve(CACHED_CASE_MANAGEMENT_INFO);
  }

  return Promise.all([
    getCaseManagementEnabledStatus(),
    CaseManagementService.canUserViewApp(),
  ])
    .then(([isAppEnabled, canUserViewApp]) =>
      isAppEnabled && canUserViewApp
        ? CaseManagementService.getCaseTypes()
        : undefined,
    )
    .then(caseTypes => {
      if (caseTypes) {
        return {
          allDruidCaseTypes: caseTypes.filterInstance(DruidCaseType),
          canUserViewCaseManagement: true,
        };
      }

      return {
        allDruidCaseTypes: Zen.Map.create(),
        canUserViewCaseManagement: false,
      };
    })
    .then(caseManagementInfo => {
      CACHED_CASE_MANAGEMENT_INFO = caseManagementInfo;
      return caseManagementInfo;
    });
}

export default (React.createContext({
  allDruidCaseTypes: Zen.Map.create(),
  canUserViewCaseManagement: false,
}): React.Context<CaseManagementInfo>);
