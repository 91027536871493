// @flow
import * as React from 'react';

import CohortGroupPill from 'components/common/QueryBuilder/CustomizableIndicatorTag/IndicatorCustomizationModule/CalculationCustomizationBlock/CohortCustomizationBlock/CohortSummaryBar/CohortCalculationFormula/CohortGroupPill';
import SetOperationPill from 'components/common/QueryBuilder/CustomizableIndicatorTag/IndicatorCustomizationModule/CalculationCustomizationBlock/CohortCustomizationBlock/common/SetOperationPill';
import type Cohort from 'models/core/wip/Calculation/CohortCalculation/Cohort';

type Props = {
  cohort: Cohort,
  groupLabels: $ReadOnlyArray<string>,
};

export default function CohortFormula({
  cohort,
  groupLabels,
}: Props): React.Node {
  const groups = cohort.cohortGroups();
  const includeParenthesis = groups.size() > 1;

  return (
    <span>
      {includeParenthesis && (
        <span className="ca-cohort-formula__parenthesis">(</span>
      )}
      {groups.mapValues((group, index) => {
        const groupName = group.name();
        const label = groupLabels[index];

        const displayedGroupName =
          groupName !== undefined ? groupName : groupLabels[index];

        return (
          <React.Fragment key={`${label}--${index}`}>
            {index > 0 && (
              <>
                ---
                <SetOperationPill operation="INTERSECT" />
                ---
              </>
            )}
            <CohortGroupPill label={displayedGroupName} />
          </React.Fragment>
        );
      })}
      {includeParenthesis && (
        <span className="ca-cohort-formula__parenthesis">)</span>
      )}
    </span>
  );
}
