// @flow
import * as React from 'react';

import CategoryFilterTree from 'models/DataCatalogApp/CategoryFilterTree';
import DimensionService, {
  FullDimensionService,
} from 'services/wip/DimensionService';
import Field from 'models/core/wip/Field';
import FieldCategoryService from 'services/wip/FieldCategoryService';
import FieldHierarchyService from 'services/AdvancedQueryApp/FieldHierarchyService';
import FieldService, { FullFieldService } from 'services/wip/FieldService';
import HierarchyItem from 'models/ui/HierarchicalSelector/HierarchyItem';
import LinkedCategory from 'models/core/wip/LinkedCategory';
import { suspendFetcher } from 'util/util';

// Mapping from field ID to the list of dimension IDs usable by that field. If
// a field has an empty array of dimensions, it is unknown which dimensions are
// usable.
type FieldToSupportedDimensionsMap = {
  +[string]: $ReadOnlyArray<string>,
};

// This hook produces a hierarchy tree containing all fields and their
// categories.
export default function useFieldHierarchy(
  includeHiddenDimensions: boolean = false,
): [
  HierarchyItem<LinkedCategory | Field>,
  (HierarchyItem<Field>) => void,
  { +[string]: $ReadOnlyArray<string> },
] {
  const fields = React.useMemo(
    () =>
      suspendFetcher(
        includeHiddenDimensions ? FullFieldService.getAll : FieldService.getAll,
      ),
    [includeHiddenDimensions],
  );
  const categories = React.useMemo(
    () => suspendFetcher(FieldCategoryService.getAll),
    [],
  );

  // Sort fields for the hierarchical selector view. Categories are sorted
  // separately in the tree.
  const sortedFields = React.useMemo(
    () =>
      fields
        .slice()
        .sort((a, b) => a.canonicalName().localeCompare(b.canonicalName())),
    [fields],
  );

  const [hierarchy, setHierarchy] = React.useState<
    HierarchyItem<LinkedCategory | Field>,
  >(HierarchyItem.createRoot());

  const [
    fieldToSupportedDimensionsMap,
    setFieldToSupportedDimensionsMap,
  ] = React.useState<FieldToSupportedDimensionsMap>({});

  // Creates a category tree and a set of hidden categories. Hidden categories
  // may have child categories that aren't explicity hidden but implicitly should
  // be.
  const [categoryFilterTree, hiddenCategories] = React.useMemo(() => {
    const tree = new CategoryFilterTree();
    const hiddenParentCategories = new Set();
    const hiddenCategoriesSet = new Set();

    // Add categories to the tree first, since they form the branches that all
    // fields will connect to.
    categories.forEach(category => {
      const { id, isVisible, name, parentId } = category.modelValues();
      if (!isVisible) {
        hiddenParentCategories.add(id);
      }
      tree.addCategory(id, name, parentId);
    });

    // Recusively traverse children of a hidden category and add ids to a set of
    // hidden category ids.
    function traverseCategoryChildren(id: string): void {
      if (hiddenCategoriesSet.has(id)) {
        return;
      }
      hiddenCategoriesSet.add(id);
      const categoryItem = tree.getCategoryFilterItem(id);
      if (categoryItem === undefined || categoryItem.children.length === 0) {
        return;
      }
      categoryItem.children.forEach(child => {
        const { id: childId } = child;
        if (!hiddenCategoriesSet.has(childId)) {
          traverseCategoryChildren(childId);
        }
      });
    }

    hiddenParentCategories.forEach(traverseCategoryChildren);
    return [tree, hiddenCategoriesSet];
  }, [categories]);

  React.useEffect(() => {
    const cachedCategories = {};

    function buildLinkedCategory(id: string): LinkedCategory | void {
      if (cachedCategories[id] !== undefined) {
        return cachedCategories[id];
      }

      const item = categoryFilterTree.getCategoryFilterItem(id);
      // Return if the CategoryFilterTree doesn't have a category item for the id
      // or if the category is hidden.
      if (item === undefined || hiddenCategories.has(id)) {
        return undefined;
      }

      // NOTE(stephen): For now, we are ignoring categories that might have
      // multiple parents. This functionality has not yet been thought through.
      const parent = Array.from(item.parents)[0];

      const parentCategory =
        parent !== undefined ? buildLinkedCategory(parent.id) : undefined;

      const category = LinkedCategory.create({
        id,
        name: item.metadata.name(),
        parent: parentCategory,
      });
      cachedCategories[id] = category;
      return category;
    }

    const models = [];
    const fieldMap = {};
    const fieldDimensionMap = {};
    const fieldToCategory = {};
    sortedFields.forEach(field => {
      const { dimensionIds, fieldCategoryMappings, id } = field.modelValues();

      // NOTE(stephen): Only using the first category and the first pipeline
      // datasource for now since that is what the original Field model could
      // support. Hopefully we can loosen these restrictions soon.
      const fieldCategoryMapping = fieldCategoryMappings.first();
      const category = fieldCategoryMapping
        ? buildLinkedCategory(fieldCategoryMapping.categoryId())
        : undefined;

      // If this field does not have a category mapping, it cannot be added to
      // the tree.
      if (category === undefined) {
        return;
      }

      if (fieldCategoryMapping.isVisible()) {
        fieldMap[id] = field;
        fieldToCategory[id] = category;
        models.push(field);
      }

      const dimensionService = includeHiddenDimensions
        ? FullDimensionService
        : DimensionService;
      if (dimensionIds) {
        fieldDimensionMap[id] = dimensionIds.map(dimensionId =>
          dimensionService.UNSAFE_forceGetById(dimensionId).dimensionCode(),
        );
      }
    });

    setHierarchy(
      FieldHierarchyService.initializeFieldHierarchy(
        models,
        fieldMap,
        fieldToCategory,
        true,
      ),
    );

    setFieldToSupportedDimensionsMap(fieldDimensionMap);
  }, [
    categoryFilterTree,
    hiddenCategories,
    includeHiddenDimensions,
    sortedFields,
  ]);

  // Update the most recently used fields section of the hierarchy tree.
  const trackItemSelected = React.useCallback(
    (item: HierarchyItem<Field>) =>
      setHierarchy(FieldHierarchyService.addSelectedItem(item)),
    [],
  );

  return [hierarchy, trackItemSelected, fieldToSupportedDimensionsMap];
}
