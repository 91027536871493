// @flow
import type Promise from 'bluebird';

// @flow
import Query from 'components/visualizations/common/Query/Query';
import { API_VERSION } from 'services/APIService';
import type CohortCalculation, {
  SerializedCohortCalculationForQuery,
} from 'models/core/wip/Calculation/CohortCalculation';

// TODO(stephen, david): These types are listed here for convenience since it is
// very early in development. They don't need to live here forever and might
// also be useful as ZenModels.

// This is the summary of all the individual pieces of a CohortGroup along with
// the total unique count for the combined group.
export type CohortGroupSummary = {
  // Each additional segment's unique count. The order of this array matches the
  // order the additional segments were defined.
  additionalSegments?: $ReadOnlyArray<number>,

  // The unique count that matches the combined block of additional segments.
  additionalSegmentsSummary?: number,

  // NOTE(stephen): The following properties (primary segment, additional
  // segments) only exist if there was at least one additional segment in the
  // original CohortGroup. If there was only a primary segment in the original
  // group, then these are not split out and the `value` property is all that
  // is returned.

  // The unique count that matches the primary segment.
  primarySegment?: number,

  // The unique count of dimension values that match all the conditions for a
  // CohortGroup.
  value: number,
};

// The summary of all the individual CohortGroups defined on a Cohort along with
// the total unique count for the full Cohort.
export type CohortSummary = {
  // The summary breakdown of each CohortGroup in the original Cohort. The order
  // of this array matches the order that the CohortGroups were originally
  // defined in the original Cohort.
  groups: $ReadOnlyArray<CohortGroupSummary>,

  // The unique count of dimension values that match all the conditions for a
  // Cohort.
  value: number,
};

// The summary of all the individual Cohorts defined on the original
// CohortCalculation along with the total unique count for the full calculation.
export type CohortCalculationSummary = {
  // The summary breakdown of each Cohort in the original calculation. The order
  // of this array matches the order that the Cohorts were originally defined on
  // the full CohortCalculation.
  cohorts: $ReadOnlyArray<CohortSummary>,

  // The unique count of dimension values that match all conditions of the
  // CohortCalculation.
  value: number,
};

type CohortCalculationSummaryRequest = {
  calculation: SerializedCohortCalculationForQuery,
  id: string,
};

const ENDPOINT = 'query/cohort_summary';

class CohortCalculationSummaryService {
  fetchSummary(
    id: string,
    calculation: CohortCalculation,
  ): Promise<CohortCalculationSummary> {
    const request: CohortCalculationSummaryRequest = {
      id,
      calculation: calculation.serializeForQuery(),
    };

    return Query.create(ENDPOINT, request, API_VERSION.V2).run();
  }
}

export default (new CohortCalculationSummaryService(): CohortCalculationSummaryService);
