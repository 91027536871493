// @flow
import * as React from 'react';

type Props = {
  children: React.Node,
  className?: string,
};

export default function SegmentOptionTitle({
  children,
  className = '',
}: Props): React.Node {
  return <div className={`segment-option-title ${className}`}>{children}</div>;
}

type ActionProps = {
  children: React.Node,
  onClick: () => void,
};

SegmentOptionTitle.Action = ({
  children,
  onClick,
}: ActionProps): React.Node => (
  <div className="segment-option-title__action" onClick={onClick} role="button">
    {children}
  </div>
);
