// @flow
import APIService from 'services/APIService';
import type { HTTPService } from 'services/APIService';

/**
 * MapDimensionService is used to fetch information about which dimensions
 * can be displayed on a map.
 *
 * TODO: actually implement this service properly with caching
 */
class MapDimensionService {
  _httpService: HTTPService;
  constructor(httpService: HTTPService) {
    this._httpService = httpService;
  }

  /**
   * These are the dimensions that can appear on a map because they have
   * associated latitude and longitude dimensions.
   */
  getGeographyDimensions(): $ReadOnlyArray<string> {
    return (window.__JSON_FROM_BACKEND || {}).geoDimensions || [];
  }

  /**
   * Check whether the provided dimension can appear on a map.
   */
  isGeographyDimension(dimensionCode: string): boolean {
    return this.getGeographyDimensions().includes(dimensionCode);
  }

  /**
   * Order the dimensions from least granular to most granular.
   *
   * @param {boolean} hasShapeFiles
   *    Whether to skip the most granular hierarchical dimension. This is a
   *    hack to try to guess which dimensions have shape files until that info
   *    is properly fetched.
   *
   * TODO: This is currently duplicated from `compute_mappable_dimension_priority`
   * on the backend and reversed. Replace with a proper API call.
   */
  getOrderedGeographyDimensions(
    hasShapeFiles: boolean = false,
  ): $ReadOnlyArray<string> {
    const geoDimensions =
      (window.__JSON_FROM_BACKEND || {}).geoDimensions || [];
    const hierarchicalDimensions =
      (window.__JSON_FROM_BACKEND || {}).hierarchical || [];

    let hierarchicalGeos = hierarchicalDimensions.filter(dimension =>
      geoDimensions.includes(dimension),
    );
    hierarchicalGeos = hasShapeFiles
      ? hierarchicalGeos.slice(0, -1)
      : hierarchicalGeos;

    return [
      ...hierarchicalGeos,
      ...geoDimensions.filter(
        dimension => !hierarchicalDimensions.includes(dimension),
      ),
    ];
  }
}

export default (new MapDimensionService(APIService): MapDimensionService);
