// @flow
import * as React from 'react';

import CohortGroupDiagram from 'components/common/QueryBuilder/CustomizableIndicatorTag/IndicatorCustomizationModule/CalculationCustomizationBlock/CohortCustomizationBlock/CohortSidePanel/CohortDiagram/CohortGroupSection/CohortGroupDiagram';
import CohortGroupSummarySection from 'components/common/QueryBuilder/CustomizableIndicatorTag/IndicatorCustomizationModule/CalculationCustomizationBlock/CohortCustomizationBlock/CohortSidePanel/CohortDiagram/CohortGroupSection/CohortGroupSummarySection';
import Spacing from 'components/ui/Spacing';
import type CohortGroup from 'models/core/wip/Calculation/CohortCalculation/CohortGroup';
import type { CohortGroupSummary } from 'services/AdvancedQueryApp/CohortCalculationSummaryService';

type Props = {
  cohortGroup: CohortGroup,
  cohortGroupSummary: CohortGroupSummary | void,
  label: string,
};

export default function CohortGroupSection({
  cohortGroup,
  cohortGroupSummary,
  label,
}: Props): React.Node {
  return (
    <Spacing className="ca-cohort-group-section" padding="xs">
      <CohortGroupSummarySection
        cohortGroup={cohortGroup}
        cohortGroupSummary={cohortGroupSummary}
        label={label}
      />
      <CohortGroupDiagram
        cohortGroup={cohortGroup}
        cohortGroupSummary={cohortGroupSummary}
        label={label}
      />
    </Spacing>
  );
}
