// @flow
import type { SetOperation } from 'models/core/wip/Calculation/CohortCalculation/types';

export const CATEGORICAL_FILTER_ITEM_ID = 'CATEGORY';
export const NUMERICAL_FILTER_ITEM_ID = 'NUMERIC';

export const SET_OPERATION_LABEL: { +[SetOperation]: string, ... } = {
  INTERSECT: 'AND',
  UNION: 'OR',
};

export const GROUP_SET_OPERATION_LABEL: { +[SetOperation]: string, ... } = {
  INTERSECT: 'ALL',
  UNION: 'ANY',
};
