// @flow
import * as React from 'react';

import Dropdown from 'components/ui/Dropdown';
import type Dimension from 'models/core/wip/Dimension';

type Props = {
  buttonWidth?: string | number,
  defaultDisplayContent?: React.Node,
  dimensions: $ReadOnlyArray<Dimension>,
  onChange: string => void,
  value: string,
};

export default function DimensionListDropdown({
  buttonWidth,
  defaultDisplayContent = null,
  dimensions,
  onChange,
  value,
}: Props): React.Node {
  const dropdownOptions = React.useMemo(
    () =>
      [...dimensions]
        .sort((a, b) => a.name().localeCompare(b.name()))
        .map((dimension: Dimension) => (
          <Dropdown.Option
            key={dimension.id()}
            searchableText={dimension.name()}
            value={dimension.dimensionCode()}
          >
            {dimension.name()}
          </Dropdown.Option>
        )),
    [dimensions],
  );

  return (
    <Dropdown
      buttonWidth={buttonWidth}
      defaultDisplayContent={defaultDisplayContent}
      enableSearch
      menuWidth="100%"
      onSelectionChange={onChange}
      value={value}
    >
      {dropdownOptions}
    </Dropdown>
  );
}
