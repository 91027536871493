// @flow
import * as React from 'react';

type Props = {
  label: string,
};

export default function CohortGroupPill({
  label,
}: Props): React.Element<'span'> {
  return <span className="ca-cohort-group-pill">{label}</span>;
}
