// @flow
import HierarchyItem from 'models/ui/HierarchicalSelector/HierarchyItem';
import I18N from 'lib/I18N';
import getFilterHierarchy from 'components/common/QueryBuilder/FilterSelector/getFilterHierarchy';
import {
  CATEGORICAL_FILTER_ITEM_ID,
  NUMERICAL_FILTER_ITEM_ID,
} from 'components/common/QueryBuilder/CustomizableIndicatorTag/IndicatorCustomizationModule/CalculationCustomizationBlock/CohortCustomizationBlock/CohortCreationPanel/constants';
import type Dimension from 'models/core/wip/Dimension';
import type LinkedCategory from 'models/core/wip/LinkedCategory';

export type CohortFilterCategory = {
  name: () => string,
  tag: 'COHORT_FILTER_CATEGORY',
};

export default function getCohortFilterHierarchy(
  dimensions: $ReadOnlyArray<Dimension>,
): HierarchyItem<LinkedCategory | Dimension | CohortFilterCategory> {
  const categoricalFilterHierarchyItem = HierarchyItem.create({
    children: getFilterHierarchy(dimensions, true).children(),
    id: CATEGORICAL_FILTER_ITEM_ID,
    metadata: {
      name: () => I18N.text('Categorical Filter'),
      tag: 'COHORT_FILTER_CATEGORY',
    },
  });

  const numericalFilterHierarchyItem = HierarchyItem.create({
    id: NUMERICAL_FILTER_ITEM_ID,
    metadata: {
      name: () => I18N.text('Numerical Filter'),
      tag: 'COHORT_FILTER_CATEGORY',
    },
  });

  return HierarchyItem.createRoot()
    .addChild(categoricalFilterHierarchyItem)
    .addChild(numericalFilterHierarchyItem);
}
