// @flow
import * as React from 'react';

import HierarchyItem from 'models/ui/HierarchicalSelector/HierarchyItem';
import type Dimension from 'models/core/wip/Dimension';
import type DimensionValue from 'models/core/wip/Dimension/DimensionValue';
import type Field from 'models/core/wip/Field';
import type LinkedCategory from 'models/core/wip/LinkedCategory';
import type { CohortFilterCategory } from 'components/common/QueryBuilder/CustomizableIndicatorTag/IndicatorCustomizationModule/CalculationCustomizationBlock/CohortCustomizationBlock/CohortCreationPanel/getCohortFilterHierarchy';

/**
 * This context holds global state shared among the CohortCreationPanel
 * component hierarchy.
 */
type CohortCreationContext = {
  dimensionValueMap: {
    +[dimensionId: string]: $ReadOnlyArray<DimensionValue>,
    ...,
  },
  fieldHierarchyLoaded: boolean,
  fieldHierarchyRoot: HierarchyItem<LinkedCategory | Field>,
  filterHierarchyLoaded: boolean,
  filterHierarchyRoot: HierarchyItem<
    LinkedCategory | Dimension | CohortFilterCategory,
  >,

  /**
   * Keep the MRU indicator list updated when a user selects a new field to add
   * to the cohort.
   */
  updateFieldHierarchyOnItemSelect: (HierarchyItem<Field>) => void,
};

export default (React.createContext({
  dimensionValueMap: {},
  fieldHierarchyLoaded: false,
  fieldHierarchyRoot: HierarchyItem.createRoot<Field | LinkedCategory>(),
  filterHierarchyLoaded: false,
  filterHierarchyRoot: HierarchyItem.createRoot(),
  updateFieldHierarchyOnItemSelect: () => {},
}): React.Context<CohortCreationContext>);
