// @flow
import * as React from 'react';

import CohortDiagramNumber from 'components/common/QueryBuilder/CustomizableIndicatorTag/IndicatorCustomizationModule/CalculationCustomizationBlock/CohortCustomizationBlock/CohortSidePanel/CohortDiagram/CohortGroupSection/CohortDiagramNumber';
import I18N from 'lib/I18N';
import type CohortGroup from 'models/core/wip/Calculation/CohortCalculation/CohortGroup';
import type CohortSegment from 'models/core/wip/Calculation/CohortCalculation/CohortSegment';
import type { CohortGroupSummary } from 'services/AdvancedQueryApp/CohortCalculationSummaryService';

type Props = {
  cohortGroup: CohortGroup,
  cohortGroupSummary: CohortGroupSummary | void,
  label: string,
};

export default function SegmentInfoSection({
  cohortGroup,
  cohortGroupSummary,
  label,
}: Props): React.Node {
  const renderLabel = (segment: CohortSegment, rowNumber: number) => {
    const rawLabel = `${label}${rowNumber}`;

    const maybeInvertedLabel = segment.invert()
      ? `${I18N.text('Not')} ${rawLabel}`
      : rawLabel;

    const gridRowStart = 1 + (rowNumber - 1) * 2;
    const gridRowEnd = gridRowStart + 2;

    return (
      <div
        key={rawLabel}
        className="ca-cohort-group-diagram__segment-label"
        style={{ gridColumn: 1, gridRow: `${gridRowStart} / ${gridRowEnd}` }}
      >
        {maybeInvertedLabel}
      </div>
    );
  };

  const renderSegmentValue = (value: number | void, segmentIdx: number) => {
    const gridRowStart = 1 + segmentIdx * 2;
    const gridRowEnd = gridRowStart + 2;

    return (
      <CohortDiagramNumber
        className="ca-cohort-group-diagram__segment-value"
        fontSize={12}
        style={{ gridColumn: 2, gridRow: `${gridRowStart} / ${gridRowEnd}` }}
        value={value}
      />
    );
  };

  const renderAdditionalSegmentInfo = (
    segment: CohortSegment,
    additionalSegmentIdx: number,
  ) => {
    const segmentValue =
      cohortGroupSummary !== undefined &&
      cohortGroupSummary.additionalSegments !== undefined
        ? cohortGroupSummary.additionalSegments[additionalSegmentIdx]
        : undefined;

    return (
      <React.Fragment key={additionalSegmentIdx}>
        {renderLabel(segment, additionalSegmentIdx + 2)}
        {renderSegmentValue(segmentValue, additionalSegmentIdx + 1)}
      </React.Fragment>
    );
  };

  const renderPrimarySegmentInfo = () => {
    const segment = cohortGroup.primarySegment();
    let segmentValue;
    if (
      cohortGroupSummary !== undefined &&
      cohortGroupSummary.primarySegment !== undefined
    ) {
      segmentValue = cohortGroupSummary.primarySegment;
    } else if (cohortGroupSummary !== undefined) {
      // TODO(david): Convert CohortGroupSummary to a zen model and implement
      // this logic in the deserialization method
      segmentValue = cohortGroupSummary.value;
    }

    return (
      <React.Fragment>
        {renderLabel(segment, 1)}
        {renderSegmentValue(segmentValue, 0)}
      </React.Fragment>
    );
  };

  return (
    <React.Fragment>
      {renderPrimarySegmentInfo()}
      {cohortGroup
        .additionalSegments()
        .arrayView()
        .map(renderAdditionalSegmentInfo)}
    </React.Fragment>
  );
}
