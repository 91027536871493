// @flow
import * as React from 'react';

import CohortGroupSection from 'components/common/QueryBuilder/CustomizableIndicatorTag/IndicatorCustomizationModule/CalculationCustomizationBlock/CohortCustomizationBlock/CohortSidePanel/CohortDiagram/CohortGroupSection';
import SetOperationPill from 'components/common/QueryBuilder/CustomizableIndicatorTag/IndicatorCustomizationModule/CalculationCustomizationBlock/CohortCustomizationBlock/common/SetOperationPill';
import type Cohort from 'models/core/wip/Calculation/CohortCalculation/Cohort';
import type { CohortSummary } from 'services/AdvancedQueryApp/CohortCalculationSummaryService';

type Props = {
  cohort: Cohort,
  cohortSummary: CohortSummary | void,
  groupLabels: $ReadOnlyArray<string>,
};

export default function CohortDiagram({
  cohort,
  cohortSummary,
  groupLabels,
}: Props): React.Node {
  return (
    <div className="ca-cohort-diagram__groups-container">
      {cohort.cohortGroups().map((cohortGroup, idx) => {
        const cohortGroupSummary =
          cohortSummary !== undefined ? cohortSummary.groups[idx] : undefined;

        const label = groupLabels[idx];

        return (
          <React.Fragment key={label}>
            {idx > 0 && (
              <div className="ca-cohort-diagram__set-operation-row">
                <SetOperationPill
                  className="ca-cohort-diagram__group-operation-pill"
                  operation="INTERSECT"
                />
                <div className="ca-cohort-diagram__divider-line" />
              </div>
            )}
            <CohortGroupSection
              cohortGroup={cohortGroup}
              cohortGroupSummary={cohortGroupSummary}
              label={label}
            />
          </React.Fragment>
        );
      })}
    </div>
  );
}
