// @flow
import * as Zen from 'lib/Zen';
import type { Serializable } from 'lib/Zen';

type Values = {
  +lowerBound?: number,

  /** Perform strict comparison on the lower bound (">" instead of ">=") */
  +lowerBoundStrict?: boolean,
  +upperBound?: number,

  /** Perform strict comparison on the upper bound ("<" instead of "<=") */
  +upperBoundStrict?: boolean,
};

type SerializedNumericValueCohortFilter = Values;

/**
 * A filter for the number of times an id has to appear for it to be included in
 * a cohort segment.
 */
class NumericValueCohortFilter
  extends Zen.BaseModel<NumericValueCohortFilter, Values>
  implements Serializable<SerializedNumericValueCohortFilter> {
  static deserialize(
    serializedNumericValueCohortFilter: SerializedNumericValueCohortFilter,
  ): Zen.Model<NumericValueCohortFilter> {
    return NumericValueCohortFilter.create({
      ...serializedNumericValueCohortFilter,
    });
  }

  serialize(): SerializedNumericValueCohortFilter {
    return { ...this.modelValues() };
  }
}

export default ((NumericValueCohortFilter: $Cast): Class<
  Zen.Model<NumericValueCohortFilter>,
>);
