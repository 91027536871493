// @flow
import * as React from 'react';
import invariant from 'invariant';

import CohortCreationContext from 'components/common/QueryBuilder/CustomizableIndicatorTag/IndicatorCustomizationModule/CalculationCustomizationBlock/CohortCustomizationBlock/CohortCreationPanel/CohortCreationContext';
import DimensionValueFilterItem from 'models/core/wip/QueryFilterItem/DimensionValueFilterItem';
import HierarchicalSelector from 'components/ui/HierarchicalSelector';
import I18N from 'lib/I18N';
import Popover from 'components/ui/Popover';
import QueryItemTag from 'components/common/QueryBuilder/QueryItemTag';
import SegmentOptionBlock from 'components/common/QueryBuilder/CustomizableIndicatorTag/IndicatorCustomizationModule/CalculationCustomizationBlock/CohortCustomizationBlock/CohortCreationPanel/CohortSegmentRow/SegmentOptionBlock';
import { NUMERICAL_FILTER_ITEM_ID } from 'components/common/QueryBuilder/CustomizableIndicatorTag/IndicatorCustomizationModule/CalculationCustomizationBlock/CohortCustomizationBlock/CohortCreationPanel/constants';
import { uniqueId } from 'util/util';
import type Dimension from 'models/core/wip/Dimension';
import type HierarchyItem from 'models/ui/HierarchicalSelector/HierarchyItem';
import type LinkedCategory from 'models/core/wip/LinkedCategory';
import type { CohortFilterCategory } from 'components/common/QueryBuilder/CustomizableIndicatorTag/IndicatorCustomizationModule/CalculationCustomizationBlock/CohortCustomizationBlock/CohortCreationPanel/getCohortFilterHierarchy';
import type { QueryFilterItem } from 'models/core/wip/QueryFilterItem/types';

type Props = {
  onFilterItemAdd: QueryFilterItem => void,
  onFilterItemRemove: () => void,
  onNumericFilterAdd: () => void,
};

export default function NewFilterOption({
  onFilterItemAdd,
  onFilterItemRemove,
  onNumericFilterAdd,
}: Props): React.Node {
  const { filterHierarchyLoaded, filterHierarchyRoot } = React.useContext(
    CohortCreationContext,
  );
  const [anchorElt, setAnchorElt] = React.useState<?HTMLDivElement>(null);

  const onFilterItemSelect = React.useCallback(
    (
      item: HierarchyItem<LinkedCategory | Dimension | CohortFilterCategory>,
    ) => {
      if (!item.isLeafItem()) {
        return;
      }

      if (item.id() === NUMERICAL_FILTER_ITEM_ID) {
        onNumericFilterAdd();
        return;
      }

      const metadata = item.metadata();
      invariant(
        metadata.tag === 'DIMENSION',
        'Selected filter item must be a dimension',
      );

      onFilterItemAdd(
        DimensionValueFilterItem.create({
          dimension: metadata.dimensionCode(),
          id: `${metadata.id()}__${uniqueId()}`,
        }),
      );
    },
    [onFilterItemAdd, onNumericFilterAdd],
  );

  const option = (
    <div ref={setAnchorElt}>
      <QueryItemTag
        className="new-filter-option__tag"
        clickable={false}
        item={undefined}
        removable={false}
        showDragHandle={false}
        text={I18N.text('Select a filter to apply')}
      />
      <Popover
        anchorElt={anchorElt}
        anchorOrigin={Popover.Origins.BOTTOM_LEFT}
        containerType={Popover.Containers.NONE}
        isOpen={!!anchorElt}
        keepInWindow
        onRequestClose={onFilterItemRemove}
        popoverOrigin={Popover.Origins.TOP_LEFT}
      >
        <HierarchicalSelector
          columnWidth={400}
          hierarchyLoaded={filterHierarchyLoaded}
          hierarchyRoot={filterHierarchyRoot}
          maxHeight={400}
          maxWidth={1000}
          onItemClick={onFilterItemSelect}
        />
      </Popover>
    </div>
  );

  return (
    <div className="new-filter-option">
      <SegmentOptionBlock option={option} title="" />
    </div>
  );
}
