// @flow
import * as React from 'react';

import HierarchicalSelector from 'components/ui/HierarchicalSelector';
import I18N from 'lib/I18N';
import Popover from 'components/ui/Popover';
import QueryItemTag from 'components/common/QueryBuilder/QueryItemTag';
import SegmentOptionBlock from 'components/common/QueryBuilder/CustomizableIndicatorTag/IndicatorCustomizationModule/CalculationCustomizationBlock/CohortCustomizationBlock/CohortCreationPanel/CohortSegmentRow/SegmentOptionBlock';
import SegmentOptionTitle from 'components/common/QueryBuilder/CustomizableIndicatorTag/IndicatorCustomizationModule/CalculationCustomizationBlock/CohortCustomizationBlock/CohortCreationPanel/CohortSegmentRow/SegmentOptionTitle';
import Tooltip from 'components/ui/Tooltip';
import type CohortSegment from 'models/core/wip/Calculation/CohortCalculation/CohortSegment';
import type Field from 'models/core/wip/Field';
import type HierarchyItem from 'models/ui/HierarchicalSelector/HierarchyItem';
import type LinkedCategory from 'models/core/wip/LinkedCategory';

type Props = {
  hierarchyLoaded: boolean,
  hierarchyRoot: HierarchyItem<Field | LinkedCategory>,
  onSegmentChange: CohortSegment => void,
  segment: CohortSegment,
  title: React.Node,
};

const MAX_FIELD_LENGTH = 60;

export default function IndicatorOption({
  hierarchyLoaded,
  hierarchyRoot,
  onSegmentChange,
  segment,
  title,
}: Props): React.Node {
  const [showSelector, setShowSelector] = React.useState<boolean>(false);
  const ref = React.useRef();

  const field = segment.field();
  const fullFieldName =
    field !== undefined ? field.name : I18N.text('Any event');
  const shouldTruncateField = fullFieldName.length > MAX_FIELD_LENGTH;
  const fieldName = shouldTruncateField
    ? `${fullFieldName.substr(0, MAX_FIELD_LENGTH)}...`
    : fullFieldName;

  const onItemSelect = React.useCallback(
    (item: HierarchyItem<Field | LinkedCategory>) => {
      if (!item.isLeafItem()) {
        return;
      }

      const metadata = item.metadata();
      if (metadata.tag === 'FIELD') {
        onSegmentChange(
          segment.field({
            filter: metadata.calculation().get('filter'),
            name: metadata.name(),
          }),
        );
        setShowSelector(false);
      }
    },
    [segment, onSegmentChange],
  );
  const onSelectorClose = React.useCallback(() => setShowSelector(false), []);
  const onTagClick = React.useCallback(() => setShowSelector(true), []);

  const invertSegment = segment.invert();
  const fullTitle = (
    <SegmentOptionTitle className="indicator-option__title">
      {title}
      <SegmentOptionTitle.Action
        onClick={() => onSegmentChange(segment.invert(!invertSegment))}
      >
        {!invertSegment
          ? I18N.text('that had')
          : I18N.text('that did not have')}
      </SegmentOptionTitle.Action>
    </SegmentOptionTitle>
  );
  const option = (
    <div ref={ref} className="indicator-option">
      <Tooltip
        content={shouldTruncateField ? fullFieldName : undefined}
        popoverClassName="indicator-option__tooltip-popover"
      >
        <QueryItemTag
          className="indicator-option__tag"
          item={field}
          onRemoveTagClick={() => onSegmentChange(segment.field(undefined))}
          onTagClick={onTagClick}
          removable={field !== undefined}
          showDragHandle={false}
          text={fieldName}
        />
      </Tooltip>
      <Popover
        anchorElt={ref.current}
        anchorOrigin={Popover.Origins.BOTTOM_LEFT}
        containerType={Popover.Containers.NONE}
        isOpen={showSelector}
        keepInWindow
        onRequestClose={onSelectorClose}
        popoverOrigin={Popover.Origins.TOP_LEFT}
      >
        <HierarchicalSelector
          columnWidth={400}
          enableSearch
          hierarchyLoaded={hierarchyLoaded}
          hierarchyRoot={hierarchyRoot}
          maxHeight={400}
          maxWidth={1000}
          onItemClick={onItemSelect}
        />
      </Popover>
    </div>
  );
  return <SegmentOptionBlock option={option} title={fullTitle} />;
}
