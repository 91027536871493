// @flow
import * as React from 'react';

import type { SVGProps } from 'components/ui/Icon/internal/SVGs/types';

export default function AIContextualize(props: SVGProps): React.Element<'svg'> {
  return (
    <svg
      fill="none"
      height="16"
      width="16"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M12.087 11.005c0 .63-.511 1.142-1.143 1.142H1.802a1.143 1.143 0 0 1-1.143-1.143V1.863A1.143 1.143 0 0 1 1.802.719h9.142a1.143 1.143 0 0 1 1.143 1.143v9.143Z"
        fill="#D7E0FF"
      />
      <path
        d="M7.23 6.433a1.429 1.429 0 1 0 0-2.857 1.429 1.429 0 0 0 0 2.857ZM8.848 12.148H1.802a1.143 1.143 0 0 1-1.143-1.143v-3.31a7.43 7.43 0 0 1 8.19 4.453Z"
        fill="#fff"
      />
      <path
        d="M.659 7.695a7.472 7.472 0 0 1 1.33-.119c1.805 0 3.46.644 4.747 1.714"
        stroke="#4147D5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.802 12.147h-4a1.143 1.143 0 0 1-1.143-1.143V1.863A1.143 1.143 0 0 1 1.802.719h9.142a1.143 1.143 0 0 1 1.143 1.143v4"
        stroke="#4147D5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.23 6.433a1.429 1.429 0 1 0 0-2.857 1.429 1.429 0 0 0 0 2.857Z"
        stroke="#4147D5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.388 12.072c-.4-.07-.4-.645 0-.715a3.63 3.63 0 0 0 2.924-2.8l.024-.11c.087-.397.652-.4.742-.004l.03.129a3.649 3.649 0 0 0 2.931 2.783c.403.07.403.649 0 .719a3.649 3.649 0 0 0-2.932 2.783l-.03.129c-.09.395-.654.393-.74-.003l-.025-.11a3.63 3.63 0 0 0-2.924-2.801Z"
        fill="#fff"
      />
      <path
        d="M8.388 12.072c-.4-.07-.4-.645 0-.715a3.63 3.63 0 0 0 2.924-2.8l.024-.11c.087-.397.652-.4.742-.004l.03.129a3.649 3.649 0 0 0 2.931 2.783c.403.07.403.649 0 .719a3.649 3.649 0 0 0-2.932 2.783l-.03.129c-.09.395-.654.393-.74-.003l-.025-.11a3.63 3.63 0 0 0-2.924-2.801Z"
        stroke="#4147D5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
