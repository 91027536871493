// @flow
import * as React from 'react';

import type { SVGProps } from 'components/ui/Icon/internal/SVGs/types';

export default function AICustom(props: SVGProps): React.Element<'svg'> {
  return (
    <svg
      fill="none"
      height="16"
      width="16"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M6.882 4.983c-.733.552-1.479 1.2-2.206 1.927C1.426 10.16-.22 13.78.998 15c1.22 1.218 4.841-.428 8.09-3.678a21.09 21.09 0 0 0 1.928-2.206"
        stroke="#4147D5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.015 9.116c1.917 2.544 2.697 4.938 1.751 5.884-1.218 1.218-4.84-.428-8.09-3.678S-.22 4.45.998 3.232c.946-.946 3.34-.166 5.884 1.75"
        stroke="#4147D5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.311 9.116a.571.571 0 1 0 1.143 0 .571.571 0 0 0-1.143 0Z"
        stroke="#4147D5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.179 4.112c-.36-.063-.36-.578 0-.64A3.25 3.25 0 0 0 11.796.964l.022-.1c.078-.354.583-.356.664-.002l.026.115a3.266 3.266 0 0 0 2.624 2.492c.361.063.361.58 0 .643a3.266 3.266 0 0 0-2.624 2.492l-.026.115c-.081.354-.586.352-.664-.003l-.022-.099A3.25 3.25 0 0 0 9.18 4.112Z"
        fill="#D7E0FF"
      />
      <path
        d="M9.179 4.112c-.36-.063-.36-.578 0-.64A3.25 3.25 0 0 0 11.796.964l.022-.1c.078-.354.583-.356.664-.002l.026.115a3.266 3.266 0 0 0 2.624 2.492c.361.063.361.58 0 .643a3.266 3.266 0 0 0-2.624 2.492l-.026.115c-.081.354-.586.352-.664-.003l-.022-.099A3.25 3.25 0 0 0 9.18 4.112Z"
        stroke="#4147D5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
