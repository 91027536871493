// @flow
import * as Zen from 'lib/Zen';
import AverageCalculation from 'models/core/wip/Calculation/AverageCalculation';
import AverageOverTimeCalculation from 'models/core/wip/Calculation/AverageOverTimeCalculation';
import Cohort from 'models/core/wip/Calculation/CohortCalculation/Cohort';
import CohortCalculation from 'models/core/wip/Calculation/CohortCalculation';
import CohortGroup from 'models/core/wip/Calculation/CohortCalculation/CohortGroup';
import CountCalculation from 'models/core/wip/Calculation/CountCalculation';
import CountDistinctCalculation from 'models/core/wip/Calculation/CountDistinctCalculation';
import FormulaCalculation from 'models/core/wip/Calculation/FormulaCalculation';
import LastValueCalculation from 'models/core/wip/Calculation/LastValueCalculation';
import MaxCalculation from 'models/core/wip/Calculation/MaxCalculation';
import MinCalculation from 'models/core/wip/Calculation/MinCalculation';
import SumCalculation from 'models/core/wip/Calculation/SumCalculation';
import WindowCalculation from 'models/core/wip/Calculation/WindowCalculation';
import type Dimension from 'models/core/wip/Dimension';
import type {
  Calculation,
  CalculationType,
} from 'models/core/wip/Calculation/types';
import type { QueryFilter } from 'models/core/wip/QueryFilter/types';

/**
 * @param {Calculation} calculation The calculation we are casting
 * @param {CalculationType} calculationType The type we are casting to
 * @param {Dimension} defaultDimension The default dimension to use for a
 * calculation if one is needed.
 * @param {string} fieldName The field name
 * @param {QueryFilter} filter The filter to use
 */
export default function convertCalculationToNewType(
  calculation: Calculation,
  calculationType: CalculationType,
  defaultDimension: Dimension,
  fieldName: string,
  filter: QueryFilter,
): Calculation {
  if (calculationType === 'COUNT_DISTINCT') {
    return CountDistinctCalculation.create({
      filter,
      dimension: defaultDimension.dimensionCode(),
    });
  }

  if (calculationType === 'COHORT') {
    // Quality of life improvement: preserve the dimension being operated on
    // if casting from a COUNT DISTINCT calculation to a COHORT calculation.
    const dimensionId =
      calculation.tag === 'COUNT_DISTINCT'
        ? calculation.dimension()
        : defaultDimension.dimensionCode();

    // NOTE(stephen): It is kind of inconvenient to have to pass the field
    // name into the calculation customization block just so that cohort
    // segments are created cleanly.
    const initialCohortGroup = CohortGroup.create({})
      .deepUpdate()
      .primarySegment()
      .field({
        filter,
        name: fieldName,
      });

    return CohortCalculation.create({
      cohorts: Zen.Array.create([
        Cohort.create({
          cohortGroups: Zen.Array.create([initialCohortGroup]),
        }),
      ]),
      dimension: dimensionId,
      // NOTE(stephen): We have to drop the original filter since it is now
      // being applied to an inner cohort segmenet. If we apply the filter to
      // the full calculation then the full calculation will be wrong.
      filter: null,
    });
  }

  if (calculation instanceof FormulaCalculation) {
    throw new Error('Cannot convert from a FormulaCalculation.');
  }

  const vals: { filter: QueryFilter } = { filter };
  switch (calculationType) {
    case 'AVG':
      return AverageCalculation.create(vals);
    case 'AVERAGE_OVER_TIME':
      return AverageOverTimeCalculation.create(vals);
    case 'COUNT':
      return CountCalculation.create(vals);
    case 'FORMULA':
      throw new Error('Cannot convert to a FormulaCalculation');
    case 'LAST_VALUE':
      return LastValueCalculation.create(vals);
    case 'MAX':
      return MaxCalculation.create(vals);
    case 'MIN':
      return MinCalculation.create(vals);
    case 'SUM':
      return SumCalculation.create(vals);
    case 'WINDOW':
      return WindowCalculation.create(vals);
    default:
      (calculationType: empty);
      throw new Error(
        `[CalculationUtil] Invalid type provided during deserialization: ${calculationType}`,
      );
  }
}
