// @flow
import * as React from 'react';

import * as Zen from 'lib/Zen';
import CohortCalculationFormula from 'components/common/QueryBuilder/CustomizableIndicatorTag/IndicatorCustomizationModule/CalculationCustomizationBlock/CohortCustomizationBlock/CohortSummaryBar/CohortCalculationFormula';
import DimensionListDropdown from 'components/common/QueryBuilder/CustomizableIndicatorTag/IndicatorCustomizationModule/CalculationCustomizationBlock/DimensionListDropdown';
import Group from 'components/ui/Group';
import I18N from 'lib/I18N';
import SummaryNumberPill from 'components/common/QueryBuilder/CustomizableIndicatorTag/IndicatorCustomizationModule/CalculationCustomizationBlock/CohortCustomizationBlock/common/SummaryNumberPill';
import type Cohort from 'models/core/wip/Calculation/CohortCalculation/Cohort';
import type Dimension from 'models/core/wip/Dimension';
import type { CohortCalculationSummary } from 'services/AdvancedQueryApp/CohortCalculationSummaryService';

type Props = {
  cohortCalculationSummary: CohortCalculationSummary | void,
  cohorts: Zen.Array<Cohort>,
  dimensionId: string,
  dimensions: $ReadOnlyArray<Dimension>,
  groupLabelsPerCohort: $ReadOnlyArray<$ReadOnlyArray<string>>,
  onDimensionIdChange: string => void,
};

export default function CohortSummaryBar({
  cohortCalculationSummary,
  cohorts,
  dimensionId,
  dimensions,
  groupLabelsPerCohort,
  onDimensionIdChange,
}: Props): React.Node {
  const cohortCalculationValue =
    cohortCalculationSummary !== undefined
      ? cohortCalculationSummary.value
      : undefined;

  return (
    <div className="ca-cohort-summary-bar">
      <Group.Horizontal flexValue="1" spacing="xs">
        <SummaryNumberPill value={cohortCalculationValue} />
        <span className="ca-cohort-summary-bar__text">
          {I18N.text('Total, defined by')}
        </span>
        <CohortCalculationFormula
          cohorts={cohorts}
          groupLabelsPerCohort={groupLabelsPerCohort}
        />
      </Group.Horizontal>
      <DimensionListDropdown
        buttonWidth="200px"
        defaultDisplayContent={I18N.text('Select a dimension')}
        dimensions={dimensions}
        onChange={onDimensionIdChange}
        value={dimensionId}
      />
    </div>
  );
}
