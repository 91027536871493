// @flow
import * as Zen from 'lib/Zen';
import NumericValueCohortFilter from 'models/core/wip/Calculation/CohortCalculation/NumericValueCohortFilter';
import type { NumericValueCohortFilterWrapper } from 'models/core/wip/Calculation/CohortCalculation/NumericValueCohortFilterItem/NumericValueCohortFilterItemUtil';
import type { Serializable } from 'lib/Zen';

type Values = {
  value: number,
};

type SerializedGreaterThanFilter = {
  type: 'GREATER_THAN',
  value: number,
};

class GreaterThanFilter extends Zen.BaseModel<GreaterThanFilter, Values>
  implements
    Serializable<SerializedGreaterThanFilter>,
    NumericValueCohortFilterWrapper {
  static tag: 'GREATER_THAN' = 'GREATER_THAN';
  tag: 'GREATER_THAN' = 'GREATER_THAN';

  static deserialize(
    serializedGreaterThanFilter: SerializedGreaterThanFilter,
  ): Zen.Model<GreaterThanFilter> {
    const { value } = serializedGreaterThanFilter;

    return GreaterThanFilter.create({ value });
  }

  filter(): NumericValueCohortFilter {
    return NumericValueCohortFilter.create({
      lowerBound: this._.value(),
      lowerBoundStrict: true,
    });
  }

  serialize(): SerializedGreaterThanFilter {
    return { type: this.tag, value: this._.value() };
  }
}

export default ((GreaterThanFilter: $Cast): Class<
  Zen.Model<GreaterThanFilter>,
>);
