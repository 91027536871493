// @flow
import * as React from 'react';

import * as Zen from 'lib/Zen';
import CohortFormula from 'components/common/QueryBuilder/CustomizableIndicatorTag/IndicatorCustomizationModule/CalculationCustomizationBlock/CohortCustomizationBlock/CohortSummaryBar/CohortCalculationFormula/CohortFormula';
import Group from 'components/ui/Group';
import SetOperationPill from 'components/common/QueryBuilder/CustomizableIndicatorTag/IndicatorCustomizationModule/CalculationCustomizationBlock/CohortCustomizationBlock/common/SetOperationPill';
import type Cohort from 'models/core/wip/Calculation/CohortCalculation/Cohort';

type Props = {
  cohorts: Zen.Array<Cohort>,
  groupLabelsPerCohort: $ReadOnlyArray<$ReadOnlyArray<string>>,
};

export default function CohortCalculationFormula({
  cohorts,
  groupLabelsPerCohort,
}: Props): React.Node {
  const nodes = [];
  cohorts.forEach((cohort, index) => {
    if (index !== 0) {
      // eslint-disable-next-line react/no-array-index-key
      nodes.push(<SetOperationPill key={`union-${index}`} operation="UNION" />);
    }

    nodes.push(
      <CohortFormula
        key={groupLabelsPerCohort[index].join()}
        cohort={cohort}
        groupLabels={groupLabelsPerCohort[index]}
      />,
    );
  });

  return <Group.Horizontal spacing="m">{nodes}</Group.Horizontal>;
}
