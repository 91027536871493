// @flow
import * as React from 'react';

import AdditionalSegmentsCondition from 'components/common/QueryBuilder/CustomizableIndicatorTag/IndicatorCustomizationModule/CalculationCustomizationBlock/CohortCustomizationBlock/CohortCreationPanel/CohortGroupBlock/AdditionalSegmentsCondition';
import CohortSegmentRow from 'components/common/QueryBuilder/CustomizableIndicatorTag/IndicatorCustomizationModule/CalculationCustomizationBlock/CohortCustomizationBlock/CohortCreationPanel/CohortSegmentRow';
import Group from 'components/ui/Group';
import GroupTitle from 'components/common/QueryBuilder/CustomizableIndicatorTag/IndicatorCustomizationModule/CalculationCustomizationBlock/CohortCustomizationBlock/CohortCreationPanel/CohortGroupBlock/GroupTitle';
import autobind from 'decorators/autobind';
import { SET_OPERATION_LABEL } from 'components/common/QueryBuilder/CustomizableIndicatorTag/IndicatorCustomizationModule/CalculationCustomizationBlock/CohortCustomizationBlock/CohortCreationPanel/constants';
import { getFullDimensionName } from 'models/core/wip/Dimension';
import type CohortGroup from 'models/core/wip/Calculation/CohortCalculation/CohortGroup';
import type CohortSegment from 'models/core/wip/Calculation/CohortCalculation/CohortSegment';

type DefaultProps = {
  onRemoveCohortGroup: CohortGroup => void,
  removable: boolean,
};

type Props = {
  ...DefaultProps,
  cohortGroup: CohortGroup,
  dimensionId: string,
  label: string,
  onCohortGroupChange: CohortGroup => void,
};

export default class CohortGroupBlock extends React.PureComponent<Props> {
  static defaultProps: DefaultProps = {
    onRemoveCohortGroup: () => {},
    removable: false,
  };

  @autobind
  onPrimarySegmentChange(segment: CohortSegment) {
    const { cohortGroup, onCohortGroupChange } = this.props;
    onCohortGroupChange(cohortGroup.primarySegment(segment));
  }

  @autobind
  onAdditionalSegmentChange(
    newSegment: CohortSegment,
    currentSegment: CohortSegment,
  ) {
    const { cohortGroup, onCohortGroupChange } = this.props;
    const additionalSegments = cohortGroup.additionalSegments();
    const idx = additionalSegments.indexOf(currentSegment);
    // TODO(stephen): Handle edge case where current segment is somehow missing
    // from the array.
    if (idx !== -1) {
      onCohortGroupChange(
        cohortGroup.additionalSegments(additionalSegments.set(idx, newSegment)),
      );
    }
  }

  @autobind
  onRemoveAdditionalSegment(segment: CohortSegment) {
    const { cohortGroup, onCohortGroupChange } = this.props;
    const additionalSegments = cohortGroup.additionalSegments();
    onCohortGroupChange(
      cohortGroup.additionalSegments(
        additionalSegments.filter(s => s !== segment),
      ),
    );
  }

  @autobind
  onRemoveCohortGroup() {
    const { cohortGroup, onRemoveCohortGroup } = this.props;
    onRemoveCohortGroup(cohortGroup);
  }

  maybeRenderAdditionalSegments(): React.Node {
    const { cohortGroup, label, onCohortGroupChange } = this.props;
    const additionalSegments = cohortGroup.additionalSegments();
    if (additionalSegments.isEmpty()) {
      return null;
    }

    const indicatorTitle = (
      <span className="cohort-group-block__additional-segment-operation">
        {SET_OPERATION_LABEL[cohortGroup.innerOperation()]}
      </span>
    );

    // NOTE(stephen): The set operation title should be shown for all rows
    // *except* the first one. If the set operation shows up for the first row
    // title, it is a little confusing and it breaks up the sentence-like
    // structure that is being built.
    const rows = additionalSegments.mapValues((segment, idx) => (
      <CohortSegmentRow
        key={`additional-segment--${idx}`}
        indicatorTitle={idx !== 0 && indicatorTitle}
        onRemoveSegment={this.onRemoveAdditionalSegment}
        onSegmentChange={this.onAdditionalSegmentChange}
        removable
        rowName={`${label}${idx + 2}`}
        segment={segment}
      />
    ));
    return (
      <Group.Vertical
        className="cohort-group-block__additional-segments-block"
        spacing="m"
      >
        <AdditionalSegmentsCondition
          cohortGroup={cohortGroup}
          onCohortGroupChange={onCohortGroupChange}
        />
        {rows}
      </Group.Vertical>
    );
  }

  renderPrimarySegment(): React.Node {
    const { cohortGroup, dimensionId } = this.props;
    return (
      <CohortSegmentRow
        indicatorTitle={getFullDimensionName(dimensionId)}
        onSegmentChange={this.onPrimarySegmentChange}
        segment={cohortGroup.primarySegment()}
      />
    );
  }

  render(): React.Node {
    const { cohortGroup, label, onCohortGroupChange, removable } = this.props;

    return (
      <div className="cohort-group-block">
        <GroupTitle
          cohortGroup={cohortGroup}
          label={label}
          onCohortGroupChange={onCohortGroupChange}
          onRemoveCohortGroup={this.onRemoveCohortGroup}
          removable={removable}
        />
        <Group.Vertical className="cohort-group-block__segments" spacing="m">
          {this.renderPrimarySegment()}
          {this.maybeRenderAdditionalSegments()}
        </Group.Vertical>
      </div>
    );
  }
}
