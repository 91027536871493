// @flow
import * as Zen from 'lib/Zen';
import NumericValueCohortFilter from 'models/core/wip/Calculation/CohortCalculation/NumericValueCohortFilter';
import type { NumericValueCohortFilterWrapper } from 'models/core/wip/Calculation/CohortCalculation/NumericValueCohortFilterItem/NumericValueCohortFilterItemUtil';
import type { Serializable } from 'lib/Zen';

type Values = {
  value: number,
};

type SerializedEqualToFilter = {
  type: 'EQUAL_TO',
  value: number,
};

class EqualToFilter extends Zen.BaseModel<EqualToFilter, Values>
  implements
    Serializable<SerializedEqualToFilter>,
    NumericValueCohortFilterWrapper {
  static tag: 'EQUAL_TO' = 'EQUAL_TO';
  tag: 'EQUAL_TO' = 'EQUAL_TO';

  static deserialize(
    serializedEqualToFilter: SerializedEqualToFilter,
  ): Zen.Model<EqualToFilter> {
    const { value } = serializedEqualToFilter;

    return EqualToFilter.create({ value });
  }

  filter(): NumericValueCohortFilter {
    return NumericValueCohortFilter.create({
      lowerBound: this._.value(),
      lowerBoundStrict: false,
      upperBound: this._.value(),
      upperBoundStrict: false,
    });
  }

  serialize(): SerializedEqualToFilter {
    return { type: this.tag, value: this._.value() };
  }
}

export default ((EqualToFilter: $Cast): Class<Zen.Model<EqualToFilter>>);
