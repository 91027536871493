// @flow
import * as React from 'react';

import type { SVGProps } from 'components/ui/Icon/internal/SVGs/types';

export default function AIQuality(props: SVGProps): React.Element<'svg'> {
  return (
    <svg
      fill="none"
      height="16"
      width="16"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M12 2.857H4c-.631 0-1.143.512-1.143 1.143v8c0 .631.512 1.143 1.143 1.143h8c.631 0 1.143-.512 1.143-1.143V4c0-.631-.512-1.143-1.143-1.143Z"
        fill="#D7E0FF"
      />
      <path
        d="M12 2.857H4c-.631 0-1.143.512-1.143 1.143v8c0 .631.512 1.143 1.143 1.143h8c.631 0 1.143-.512 1.143-1.143V4c0-.631-.512-1.143-1.143-1.143ZM2.857 10.095H.775M15.224 10.095h-2.082M2.857 5.905H.775M15.224 5.905h-2.082M5.905 2.857V.775M5.905 15.224v-2.082M10.095 2.857V.775M10.095 15.224v-2.082"
        stroke="#4147D5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.333 8.287c-.321-.056-.321-.518 0-.574A2.91 2.91 0 0 0 7.677 5.47l.02-.089c.07-.318.522-.32.594-.003l.024.104a2.925 2.925 0 0 0 2.35 2.23c.323.057.323.52 0 .577a2.925 2.925 0 0 0-2.35 2.231l-.024.103c-.072.318-.525.316-.594-.002l-.02-.089a2.91 2.91 0 0 0-2.344-2.244Z"
        fill="#fff"
      />
      <path
        d="M5.333 8.287c-.321-.056-.321-.518 0-.574A2.91 2.91 0 0 0 7.677 5.47l.02-.089c.07-.318.522-.32.594-.003l.024.104a2.925 2.925 0 0 0 2.35 2.23c.323.057.323.52 0 .577a2.925 2.925 0 0 0-2.35 2.231l-.024.103c-.072.318-.525.316-.594-.002l-.02-.089a2.91 2.91 0 0 0-2.344-2.244Z"
        stroke="#4147D5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
