// @flow
import * as React from 'react';
import classNames from 'classnames';

import SetOperationPill from 'components/common/QueryBuilder/CustomizableIndicatorTag/IndicatorCustomizationModule/CalculationCustomizationBlock/CohortCustomizationBlock/common/SetOperationPill';
import SummaryNumberPill from 'components/common/QueryBuilder/CustomizableIndicatorTag/IndicatorCustomizationModule/CalculationCustomizationBlock/CohortCustomizationBlock/common/SummaryNumberPill';
import type { CohortSummary } from 'services/AdvancedQueryApp/CohortCalculationSummaryService';

type Props = {
  cohortSummary: CohortSummary | void,
  includeOperator?: boolean,
};

export default function CohortHeaderRow({
  cohortSummary,
  includeOperator = false,
}: Props): React.Node {
  const cohortValue =
    cohortSummary !== undefined ? cohortSummary.value : undefined;

  const className = classNames('ca-cohort-header-row', {
    'ca-cohort-header-row--no-operator': !includeOperator,
  });
  return (
    <div className={className}>
      <SummaryNumberPill
        className="ca-cohort-header-row__summary-number"
        value={cohortValue}
      />
      {includeOperator && (
        <React.Fragment>
          <div className="ca-cohort-header-row__operator-line" />
          <SetOperationPill
            className="ca-cohort-header-row__set-operation"
            operation="UNION"
          />
        </React.Fragment>
      )}
    </div>
  );
}
