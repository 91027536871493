// @flow
import Promise from 'bluebird';
import 'url-search-params-polyfill';
import invariant from 'invariant';

import * as Zen from 'lib/Zen';
import APIService, { API_VERSION } from 'services/APIService';
import AlertCase from 'models/CaseManagementApp/AlertCase';
import AlertCaseCoreInfo from 'models/CaseManagementApp/AlertCaseCoreInfo';
import AlertCaseType from 'models/CaseManagementApp/AlertCaseType';
import AlertDefinition from 'models/AlertsApp/AlertDefinition';
import AuthorizationService from 'services/AuthorizationService';
import CaseEvent from 'models/CaseManagementApp/CaseEvent';
import DruidCase from 'models/CaseManagementApp/DruidCase';
import DruidCaseCoreInfo from 'models/CaseManagementApp/DruidCaseCoreInfo';
import DruidCaseType from 'models/CaseManagementApp/DruidCaseType';
import ExternalAlert from 'models/CaseManagementApp/ExternalAlert';
// TODO(pablo): fix this cyclical dependency
// eslint-disable-next-line import/no-cycle
import ExternalAlertsService, {
  doAlertsMatch,
} from 'services/CaseManagementService/ExternalAlertsService';
import Moment from 'models/core/wip/DateTime/Moment';
import autobind from 'decorators/autobind';
import getCaseManagementEnabledStatus from 'services/CaseManagementService/getCaseManagementEnabledStatus';
import { convertIDToURI } from 'services/wip/util';
import type { HTTPService } from 'services/APIService';

export const ALL_DATASETS_KEY = '__all__';

// Saved case types.
let CASE_TYPES_CACHE: Zen.Map<AlertCaseType | DruidCaseType> | void;

export function convertAlertNotificationIDToURI(id: number): string {
  return convertIDToURI(String(id), API_VERSION.V2, 'alert_notifications');
}

/**
 * This class contains all necessary service calls to load druid cases and
 * alert cases.
 *
 * When loading alert cases, we have a special situation where alerts sometimes
 * need to be merged with an external alert. Look out for the
 * `mergeExternalAlert` calls to see what I mean. In `ExternalAlertsService`
 * you can find a longer comment explaining why and how we use the external
 * alerts.
 *
 * TODO(pablo, moriah): a lot of this code needs to be cleaned up due to the
 * emergency situation it was written in. Refactor this once things settle.
 */
class CaseManagementService {
  _httpService: HTTPService;
  constructor(httpService: HTTPService) {
    this._httpService = httpService;
  }

  isAppEnabled(): Promise<boolean> {
    return getCaseManagementEnabledStatus();
  }

  /**
   * Check if the active user has permissions to view the Case Management app
   */
  canUserViewApp(): Promise<boolean> {
    // HACK(abby): This is the same endpoint that gates access to the Case Management
    // App, which requires authentication. So we need to ensure we don't check for
    // authorization if the user is not logged in (like on public dashboards). The
    // request will fail and error (even if the user is just viewing a dashboard).
    if (!window.__JSON_FROM_BACKEND.user.isAuthenticated) {
      return Promise.resolve(false);
    }
    return AuthorizationService.isAuthorized('view_case_management', 'SITE');
  }

  /**
   * Get all the cases represented by a DruidCaseType.
   * If a `datasetId` is provided then we should filter by that source.
   *
   * TODO(abby): For some reason this returns slightly more druid cases than
   * staging. Also the dates are all off by one day.
   * Check get_core_druid_info_for_druid_cases in
   * routes/views/case_management.py for how the cases are fetched
   */
  @autobind
  getAllDruidCases(
    caseTypeURI: string,
    datasetId?: string,
  ): Promise<Array<DruidCaseCoreInfo>> {
    return this.getCaseTypeByURI(caseTypeURI).then(caseType => {
      invariant(
        caseType.tag === 'DRUID',
        `'${caseTypeURI}' must be a Druid case type`,
      );

      const requestPath = `case/lightweight/druid?caseTypeUri=${caseTypeURI}${
        datasetId && datasetId !== ALL_DATASETS_KEY
          ? `&datasetId=${datasetId}`
          : ''
      }`;
      return this._httpService
        .get<{
          allFieldData: { [string]: { [fieldId: string]: ?string, ... } },
          cases: Array<{
            dimensionValues: Array<?(string | Array<string>)>,
            lastDate: number,
            statusId: number,
          }>,
          caseTypeURI: string,
          dimensionIds: Array<string>,
          statusURIs: { [statusId: string]: string, ... },
        }>(API_VERSION.V2, requestPath)
        .then(({ allFieldData, cases, dimensionIds, statusURIs }) => {
          return cases.map(caseInfo => {
            const { dimensionValues, lastDate, statusId } = caseInfo;
            const metadataDimensionValues: {
              [string]: ?(string | Array<string>),
            } = {};
            dimensionIds.forEach((dimId, i) => {
              metadataDimensionValues[dimId] = dimensionValues[i];
            });

            const serializedCaseCoreInfo = {
              caseTypeURI,
              metadataDimensionValues,
              lastDateAvailable: lastDate,
              statusURI: statusURIs[String(statusId)],
            };

            const caseHash = caseType.getUniqueCaseKey(metadataDimensionValues);
            const fieldData = allFieldData[caseHash];
            return DruidCaseCoreInfo.deserialize(serializedCaseCoreInfo, {
              caseType,
              druidFieldValues: fieldData,
            });
          });
        });
    });
  }

  /**
   * Given a case type's primary dimension and set of primary druid dimension
   * values, load the full DruidCase.
   * 1. If the DruidCase exists in the db, load it.
   * 2. Else, create it with default values.
   * 3. Return it and deserialize it here.
   *
   * We persist the DruidCase to the backend when a user loads a full druid
   * case.
   */
  @autobind
  getFullDruidCase(
    caseTypePrimaryDimension: string,
    primaryDruidDimensionValues: { +[string]: string },
  ): Promise<DruidCase> {
    const apiCall = this._httpService.post<{
      $uri: string,
      additionalEvents: $ReadOnlyArray<Zen.Serialized<CaseEvent>>,
      caseTypeURI: string,
      dataSubmissionDates: $ReadOnlyArray<number>,
      fieldData: { [fieldId: string]: ?string, ... } | void,
      lastDateAvailable: number,
      metadataDimensionValues: {
        [dimensionName: string]: ?(string | Array<string>),
      },
      metadataValuesFromUser: $ReadOnlyArray<{
        caseMetadataType: string,
        value: string,
      }>,
      statusURI: string,
    }>(API_VERSION.V2, 'case/maybe_add_druid_case', {
      caseTypePrimaryDimension,
      primaryDruidDimensionValues,
    });

    return Promise.all([apiCall, this.getCaseTypes()]).then(
      serializedCaseInfo => {
        const caseInfo = serializedCaseInfo[0];
        const {
          $uri,
          additionalEvents,
          caseTypeURI,
          dataSubmissionDates,
          fieldData,
          lastDateAvailable,
          metadataDimensionValues,
          metadataValuesFromUser,
          statusURI,
        } = caseInfo;
        const caseTypeMap = serializedCaseInfo[1];
        const caseType = caseTypeMap.forceGet(caseTypeURI);
        invariant(
          caseType.tag === 'DRUID',
          `'${caseTypeURI}' must be a Druid case type`,
        );

        const primaryDimensionValue =
          metadataDimensionValues[caseType.primaryDruidDimension()] || '';

        return this.getAllAlertCases({
          druidDimension: caseTypePrimaryDimension,
          druidDimensionValue:
            typeof primaryDimensionValue === 'string'
              ? primaryDimensionValue
              : primaryDimensionValue[0],
        }).then(alertCases => {
          const druidCase: DruidCase = DruidCase.deserialize(
            {
              $uri,
              caseTypeURI,
              lastDateAvailable,
              metadataDimensionValues,
              statusURI,
            },
            {
              caseType,
              metadataValuesFromUser,
              druidFieldValues: fieldData,
              events: additionalEvents,
            },
          );

          // TODO(abby): move alert events creation to the backend

          // Create all the alert events on the fly
          const alertEvents = alertCases.map(CaseEvent.createAlertEvent);
          const dataEvents = dataSubmissionDates.map(
            CaseEvent.createDataSubmissionEvent,
          );

          // now merge all the events together
          const { events } = druidCase.modelValues();
          const allEvents = Zen.Array.create([
            ...dataEvents,
            ...alertEvents,
            ...events,
          ]);

          return druidCase.events(CaseEvent.sortEvents(allEvents));
        });
      },
    );
  }

  /**
   * Get all the cases represented by an AlertCaseType.
   */
  @autobind
  getAllAlertCases(
    alertCaseInfo?:
      | {
          alertDefinitionUri: string,
          druidDimensionValue: string,
        }
      | {
          druidDimension: string,
          druidDimensionValue: string,
        },
  ): Promise<Array<AlertCaseCoreInfo>> {
    return this.getCaseTypes().then(
      (caseTypeMap: Zen.Map<AlertCaseType | DruidCaseType>) => {
        const alertCaseType = Zen.cast<AlertCaseType | void>(
          caseTypeMap
            .values()
            .find(caseType => caseType.get('caseType') === 'ALERT'),
        );

        if (alertCaseType === undefined) {
          return Promise.resolve([]);
        }

        const reqParams = new URLSearchParams();
        reqParams.set('caseTypeUri', alertCaseType.uri());

        if (alertCaseInfo) {
          const { druidDimensionValue } = alertCaseInfo;
          reqParams.set('druidDimensionValue', druidDimensionValue);

          if (alertCaseInfo.alertDefinitionUri) {
            const { alertDefinitionUri } = alertCaseInfo;
            reqParams.set('alertDefinitionUri', alertDefinitionUri);
          } else if (alertCaseInfo.druidDimension) {
            const { druidDimension } = alertCaseInfo;
            reqParams.set('druidDimension', druidDimension);
          }
        }

        const requestPath = `case/lightweight/alert?${reqParams.toString()}`;
        const allAlertsPromise = this._httpService.get(
          API_VERSION.V2,
          requestPath,
        );

        return Promise.all([
          allAlertsPromise,
          ExternalAlertsService.getAllExternalAlerts(),
        ]).then(
          ([lightweightCasesData, externalAlerts: Array<ExternalAlert>]) => {
            // NOTE(stephen): Unpacking result directly instead of inline to allow
            // us to specify flow types.
            const serializedCases: Array<Zen.Serialized<AlertCaseCoreInfo>> =
              lightweightCasesData.cases;
            const serializedDefinitions: Array<
              Zen.Serialized<AlertDefinition>,
            > = lightweightCasesData.alertDefinitions;
            const { lastUserUpdateLookup } = lightweightCasesData;

            const alertCaseTypeEntry = Zen.cast<AlertCaseType>(
              caseTypeMap
                .values()
                .find(caseConfig => caseConfig.get('caseType') === 'ALERT'),
            );

            // Deserialize the alert definitions and build a map of them
            return Promise.all(
              Zen.deserializeAsyncArray(AlertDefinition, serializedDefinitions),
            ).then(alertDefinitions => {
              const alertDefinitionLookup = {};
              alertDefinitions.forEach(alertDefinition => {
                alertDefinitionLookup[alertDefinition.uri()] = alertDefinition;
              });

              // Go through all our alert cases, deserialize them, and merge them
              // with an external case if necessary
              return serializedCases.map(serializedCaseCoreInfo => {
                const alertCaseCoreInfo = AlertCaseCoreInfo.deserialize(
                  serializedCaseCoreInfo,
                  {
                    type: alertCaseTypeEntry,
                  },
                );
                const alertDefinition =
                  alertDefinitionLookup[
                    alertCaseCoreInfo.alertNotification().alertDefinitionUri()
                  ];
                const matchedExtCases = new Set();

                // See if we can find a matching external case
                const matchingExtAlert = externalAlerts.find(extAlert =>
                  doAlertsMatch(
                    alertCaseCoreInfo.alertNotification(),
                    alertDefinition,
                    extAlert,
                  ),
                );

                // Found no match, nothing to do
                if (!matchingExtAlert) {
                  return alertCaseCoreInfo;
                }

                // If we found a matching external alert, make sure it hasn't
                // already been matched to a different case.
                if (matchedExtCases.has(matchingExtAlert)) {
                  // oh no this means we have duplicate alert cases :'(
                  throw new Error(
                    '[CaseManagementService] Something is not right. We should not have one external alert match with multiple alert cases.',
                  );
                  // TODO(pablo): we are not adding anything to the set right now
                  // so this error will never be thrown. Alerts are still being
                  // actively debugged, so this error will go off too frequently.
                  // Once things are stable, enable this.
                }

                const uri = alertCaseCoreInfo.alertNotification().uri();
                const lastUserUpdate = lastUserUpdateLookup[uri];
                return alertCaseCoreInfo.mergeExternalAlert(
                  matchingExtAlert,
                  lastUserUpdate ? Moment.create(lastUserUpdate) : undefined,
                );
              });
            });
          },
        );
      },
    );
  }

  /**
   * Given an alert notification URI, load the full AlertCase.
   * We are:
   *   1. If the AlertCase exists in the db, load it.
   *   2. Else, create it with default values.
   *   3. Return it and deserialize it here.
   *
   *   We persist the AlertCase to the backend when a user loads a full alert
   *   case.
   */
  @autobind
  getFullAlertCase(alertNotificationUri: string): Promise<AlertCase> {
    return this.getCaseTypes().then(
      (caseTypeMap: Zen.Map<AlertCaseType | DruidCaseType>) => {
        const alertCaseType = Zen.cast<AlertCaseType>(
          caseTypeMap
            .values()
            .find(caseType => caseType.get('caseType') === 'ALERT'),
        );
        return new Promise((resolve, reject) => {
          this._httpService
            .post(API_VERSION.V2, 'case/maybe_add_alert_case', {
              alertNotificationUri,
              caseTypeUri: alertCaseType.uri(),
            })
            .then(result => {
              // NOTE(stephen): Unpacking result directly instead of inline to
              // allow us to specify flow types.
              const serializedCase: Zen.Serialized<AlertCase> = result.case;
              const { additionalEvents } = result;

              // merge external alert
              // add external alert activity events
              return AlertCase.deserializeAsync(serializedCase, {
                type: alertCaseType,
              }).then(alertCase => {
                const { coreInfo, events } = alertCase.modelValues();

                // Add back in events that are not persisted to the CaseEvent
                // database such as the alert creation event
                const allEvents = Zen.Array.create([
                  CaseEvent.createAlertEvent(coreInfo),
                  ...events,
                  ...Zen.deserializeArray(CaseEvent, additionalEvents),
                ]);
                const fullAlertCase = alertCase.events(
                  CaseEvent.sortEvents(allEvents),
                );

                return ExternalAlertsService.getMatchingExternalAlert(
                  coreInfo,
                  fullAlertCase.alertDefinition(),
                ).then((externalAlert: ExternalAlert | void) => {
                  if (externalAlert) {
                    // found a matching alert so merge its info into this case
                    const mergedAlertCase = fullAlertCase.mergeExternalAlert(
                      externalAlert,
                    );

                    if (
                      mergedAlertCase.coreInfo().status() !==
                      fullAlertCase.coreInfo().status()
                    ) {
                      const caseEvent = CaseEvent.createStatusChangeEvent(
                        mergedAlertCase
                          .coreInfo()
                          .status()
                          .label(),
                        '',
                        mergedAlertCase.coreInfo().alertSource(),
                      );
                      this.postCaseEvent(caseEvent, mergedAlertCase.uri());
                      return resolve(
                        mergedAlertCase.events(
                          CaseEvent.sortEvents(
                            mergedAlertCase.events().push(caseEvent),
                          ),
                        ),
                      );
                    }
                    return resolve(mergedAlertCase);
                  }
                  return resolve(fullAlertCase);
                });
              });
            })
            .catch(error => reject(error));
        });
      },
    );
  }

  @autobind
  updateStatus(
    newCaseUnit: AlertCase | DruidCase,
    statusEvent: CaseEvent,
  ): Promise<void> {
    return new Promise((resolve, reject) => {
      this._httpService
        .post(API_VERSION.V2, `case/status_maybe_create_event`, {
          caseUri: newCaseUnit.get('uri'),
          event: statusEvent.serialize(),
          statusUri: newCaseUnit
            .get('coreInfo')
            .get('status')
            .uri(),
        })
        .catch(error => reject(error));
    });
  }

  /**
   * Update a case's metadata and add a new event to the case (if the new
   * metadata value is different from the old value - this is checked in
   * the backend).
   *
   * TODO(pablo): also check on the frontend if the new value is the same
   * as the old value, that way we don't waste time sending an HTTP request.
   *
   * @param {string} caseURI The unique URI for the case to update
   * @param {string} metadataTypeURI The unique URI for the metadata type we're
   * updating
   * @param {string} value The new value for the metadata entry we're changing
   * @param {CaseEvent} metadataChangeEvent A CaseEvent model representing this
   * metadata change.
   */
  @autobind
  updateMetadata(
    caseURI: string,
    metadataTypeURI: string,
    value: string,
    metadataChangeEvent: CaseEvent,
  ): Promise<void> {
    return new Promise((resolve, reject) => {
      this._httpService
        .post(API_VERSION.V2, `case/metadata_maybe_create_event`, {
          caseUri: caseURI,
          event: metadataChangeEvent.serialize(),
          metadataTypeUri: metadataTypeURI,
          metadataValue: value,
        })
        .catch(error => reject(error));
    });
  }

  @autobind
  getCaseTypes(): Promise<Zen.Map<AlertCaseType | DruidCaseType>> {
    if (CASE_TYPES_CACHE) {
      return Promise.resolve(CASE_TYPES_CACHE);
    }
    return new Promise((resolve, reject) => {
      this._httpService
        .get(API_VERSION.V2, 'case_type')
        .then(caseTypes => {
          const caseTypeMap = {};
          caseTypes.forEach(caseType => {
            caseTypeMap[caseType.$uri] =
              caseType.caseType === 'ALERT'
                ? AlertCaseType.deserialize(caseType)
                : DruidCaseType.deserialize(caseType);
          });
          CASE_TYPES_CACHE = Zen.Map.create(caseTypeMap);
          return resolve(CASE_TYPES_CACHE);
        })
        .catch(error => reject(error));
    });
  }

  getCaseTypeByURI(
    caseTypeURI: string,
  ): Promise<AlertCaseType | DruidCaseType> {
    return this.getCaseTypes().then(caseTypeMap =>
      caseTypeMap.forceGet(caseTypeURI),
    );
  }

  @autobind
  postCaseEvent(caseEvent: CaseEvent, caseUri?: string): Promise<void> {
    return new Promise((resolve, reject) => {
      const serializedCaseEvent = caseEvent.serialize();
      if (caseUri) {
        serializedCaseEvent.caseUri = caseUri;
      }
      this._httpService
        .post(API_VERSION.V2, `case_event`, serializedCaseEvent)
        .catch(error => reject(error));
    });
  }
}

export default (new CaseManagementService(APIService): CaseManagementService);
