// @flow
import * as React from 'react';

import RiskScoreTable from 'components/visualizations/Table/RiskScore/RiskScoreTable';
import Table from 'components/visualizations/Table';
import { ENABLE_RISK_SCORE_TABLE } from 'components/visualizations/Table/RiskScore/defaults';
import type { VisualizationProps } from 'components/visualizations/common/commonTypes';

type Props = VisualizationProps<'TABLE'>;

export default function TableWrapper(
  props: Props,
): React.Element<typeof Table> | React.Element<typeof RiskScoreTable> {
  // HACK(nina): The 'Risk Score Table' is a wrapper component for the table
  // component that enables users to select individual values on the table
  // and open a modal describing information about that value. This is a
  // ZA-specific feature, so it didn't make sense to automatically wrap the
  // table component and build the wrapper component into the AQT hierarchy.
  if (ENABLE_RISK_SCORE_TABLE) {
    return <RiskScoreTable {...props} />;
  }
  return <Table {...props} />;
}
