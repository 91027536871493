// @flow
import * as React from 'react';

import type { SVGProps } from 'components/ui/Icon/internal/SVGs/types';

export default function NoNotifications(props: SVGProps): React.Element<'svg'> {
  return (
    <svg
      fill="none"
      height="114"
      viewBox="0 0 114 114"
      width="114"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle cx="57" cy="57" fill="#EFF1F5" r="57" />
      <path
        clipRule="evenodd"
        d="M53 33a1.32 1.32 0 01-1.333 1.333A1.32 1.32 0 0150.333 33a1.32 1.32 0 011.334-1.333A1.32 1.32 0 0153 33zm9.333 1.333A1.32 1.32 0 0063.667 33a1.32 1.32 0 00-1.334-1.333A1.32 1.32 0 0061 33a1.32 1.32 0 001.333 1.333zM59.667 41A2.667 2.667 0 1165 41a2.667 2.667 0 01-5.333 0zm2.666 14.667l-.533-.054a3.961 3.961 0 01-3.413-3.413l-.054-.533c0-2.214 1.787-4 4-4 2.214 0 4 1.786 4 4 0 2.213-1.786 4-4 4zM73 49a2.667 2.667 0 100 5.333A2.667 2.667 0 0073 49zm-2.667-8a2.667 2.667 0 115.334 0 2.667 2.667 0 01-5.334 0zM81 53a1.32 1.32 0 001.333-1.333A1.32 1.32 0 0081 50.333a1.32 1.32 0 00-1.333 1.334A1.32 1.32 0 0081 53zM49 41a2.667 2.667 0 115.333 0A2.667 2.667 0 0149 41zm24 18.667A2.667 2.667 0 1073 65a2.667 2.667 0 000-5.333zM38.333 73a2.667 2.667 0 115.334 0 2.667 2.667 0 01-5.334 0zm24 6.667A1.32 1.32 0 0061 81a1.32 1.32 0 001.333 1.333A1.32 1.32 0 0063.667 81a1.32 1.32 0 00-1.334-1.333zm17.334-17.334A1.32 1.32 0 0181 61a1.32 1.32 0 011.333 1.333A1.32 1.32 0 0181 63.667a1.32 1.32 0 01-1.333-1.334zM33 61a1.32 1.32 0 00-1.333 1.333A1.32 1.32 0 0033 63.667a1.32 1.32 0 001.333-1.334A1.32 1.32 0 0033 61zm16 12a2.667 2.667 0 115.333 0A2.667 2.667 0 0149 73zM33 50.333a1.32 1.32 0 00-1.333 1.334A1.32 1.32 0 0033 53a1.32 1.32 0 001.333-1.333A1.32 1.32 0 0033 50.333zM50.333 81a1.32 1.32 0 011.334-1.333A1.32 1.32 0 0153 81a1.32 1.32 0 01-1.333 1.333A1.32 1.32 0 0150.333 81zM41 59.667A2.667 2.667 0 1041 65a2.667 2.667 0 000-5.333zm0-11.28l-9.333-9.334 3.76-3.76 43.28 43.28-3.76 3.76h-.027l-10.08-10.08c.08.24.16.48.16.747 0 1.467-1.2 2.667-2.667 2.667A2.674 2.674 0 0159.667 73c0-1.467 1.2-2.667 2.666-2.667.267 0 .507.08.747.16L55.587 63c-.294 1.893-1.947 3.333-3.92 3.333-2.214 0-4-1.786-4-4 0-1.973 1.44-3.626 3.333-3.92l-7.493-7.493c.08.24.16.48.16.747 0 1.466-1.2 2.666-2.667 2.666a2.674 2.674 0 01-2.667-2.666C38.333 50.2 39.533 49 41 49l.747.133-.747-.746z"
        fill="#ABAEB4"
        fillRule="evenodd"
      />
    </svg>
  );
}
