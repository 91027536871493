// @flow
import Promise from 'bluebird';

import ConfigurationService from 'services/ConfigurationService';

/**
 * Checks if the case management app has been enabled.
 * This is a standalone function, separate from the CaseManagementService
 * so that other apps can import this function without having to import the
 * entire bundle.
 */
export default function getCaseManagementEnabledStatus(): Promise<boolean> {
  // HACK(stephen): Due to limitations in the Configuration endpoint
  // permissions, we need to ensure we don't check for configuration values if
  // the user is not logged in (like on public dashboards). The request will
  // fail.
  if (!window.__JSON_FROM_BACKEND.user.isAuthenticated) {
    return Promise.resolve(false);
  }

  return ConfigurationService.getConfiguration('enable_case_management').then(
    config => !!config.value(),
  );
}
