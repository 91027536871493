// @flow
import * as React from 'react';

import MatchingOperatorsDiagram from 'components/common/QueryBuilder/CustomizableIndicatorTag/IndicatorCustomizationModule/CalculationCustomizationBlock/CohortCustomizationBlock/CohortSidePanel/CohortDiagram/CohortGroupSection/CohortGroupDiagram/MatchingOperatorsDiagram';
import OppositeOperatorsDiagram from 'components/common/QueryBuilder/CustomizableIndicatorTag/IndicatorCustomizationModule/CalculationCustomizationBlock/CohortCustomizationBlock/CohortSidePanel/CohortDiagram/CohortGroupSection/CohortGroupDiagram/OppositeOperatorsDiagram';
import SegmentInfoSection from 'components/common/QueryBuilder/CustomizableIndicatorTag/IndicatorCustomizationModule/CalculationCustomizationBlock/CohortCustomizationBlock/CohortSidePanel/CohortDiagram/CohortGroupSection/CohortGroupDiagram/SegmentInfoSection';
import type CohortGroup from 'models/core/wip/Calculation/CohortCalculation/CohortGroup';
import type { CohortGroupSummary } from 'services/AdvancedQueryApp/CohortCalculationSummaryService';

type Props = {
  cohortGroup: CohortGroup,
  cohortGroupSummary: CohortGroupSummary | void,
  label: string,
};

export default function CohortGroupDiagram({
  cohortGroup,
  cohortGroupSummary,
  label,
}: Props): React.Node {
  const numSegments = cohortGroup.additionalSegments().size() + 1;
  const numGridRows = numSegments * 2;

  const DiagramComponent =
    cohortGroup.innerOperation() === cohortGroup.outerOperation()
      ? MatchingOperatorsDiagram
      : OppositeOperatorsDiagram;

  return (
    <div
      className="ca-cohort-group-diagram"
      style={{
        gridTemplateColumns: `60px 1fr repeat(3, 32px) 1fr`,
        gridTemplateRows: `repeat(${numGridRows}, 12px)`,
      }}
    >
      <SegmentInfoSection
        cohortGroup={cohortGroup}
        cohortGroupSummary={cohortGroupSummary}
        label={label}
      />
      <DiagramComponent
        cohortGroup={cohortGroup}
        cohortGroupSummary={cohortGroupSummary}
      />
    </div>
  );
}
