// @flow
import APIService, { API_VERSION } from 'services/APIService';
// eslint-disable-next-line import/no-cycle
import Field from 'models/core/wip/Field';
// eslint-disable-next-line import/no-cycle
import FieldService from 'services/wip/FieldService';
import LinkedCategory from 'models/core/wip/LinkedCategory';
import { CategoryServiceClass } from 'services/wip/CategoryService';

class FieldCategoryService extends CategoryServiceClass {
  endpoint: string = 'query/field_categories';

  relocateTo(
    newCategory: LinkedCategory,
    oldCategory: LinkedCategory,
    categories: $ReadOnlyArray<LinkedCategory>,
    fields: $ReadOnlyArray<Field>,
  ): Promise<void> {
    return this._httpService
      .post(API_VERSION.V2, `${this.endpoint}/${newCategory.id()}/relocate`, {
        childrenCategoryIds: categories.map(c => c.id()),
        childrenFieldIds: fields.map(f => f.id()),
        newCategoryName: newCategory.name(),
        oldParentCategoryId: oldCategory.id(),
      })
      .then(() => {
        this.updateInternal([
          newCategory,
          ...categories.map(c => c.parentId(newCategory.id())),
        ]);
        FieldService.updateInternal(
          fields.map(f =>
            f.fieldCategoryMappings(
              f
                .fieldCategoryMappings()
                .map(fcm =>
                  fcm.categoryId() === oldCategory.id()
                    ? fcm.categoryId(newCategory.id())
                    : fcm,
                ),
            ),
          ),
        );
      });
  }
}

const FieldCategoryServiceImpl: FieldCategoryService = new FieldCategoryService(
  APIService,
);
export default FieldCategoryServiceImpl;
