// @flow
import * as React from 'react';

import { SET_OPERATION_LABEL } from 'components/common/QueryBuilder/CustomizableIndicatorTag/IndicatorCustomizationModule/CalculationCustomizationBlock/CohortCustomizationBlock/CohortCreationPanel/constants';
import type { SetOperation } from 'models/core/wip/Calculation/CohortCalculation/types';

type Props = {
  className?: string,
  operation: SetOperation,
};

export default function SetOperationPill({
  className = '',
  operation,
}: Props): React.Node {
  return (
    <span className={`ca-set-operation-pill ${className}`}>
      {SET_OPERATION_LABEL[operation]}
    </span>
  );
}
