// @flow
import * as React from 'react';

import type { SVGProps } from 'components/ui/Icon/internal/SVGs/types';

export default function Characters(props: SVGProps): React.Element<'svg'> {
  return (
    <svg
      height="28"
      viewBox="0 0 28 28"
      width="28"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M29,23H24a2.0027,2.0027,0,0,1-2-2V15a2.0023,2.0023,0,0,1,2-2h5v2H24v6h5Z" />
      <path d="M18,13H14V9H12V23h6a2.0027,2.0027,0,0,0,2-2V15A2.0023,2.0023,0,0,0,18,13Zm-4,8V15h4v6Z" />
      <path d="M8,13H3v2H8v2H4a2,2,0,0,0-2,2v2a2,2,0,0,0,2,2h6V15A2.0023,2.0023,0,0,0,8,13Zm0,8H4V19H8Z" />
    </svg>
  );
}
