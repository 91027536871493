// @flow
import * as React from 'react';

import type { SVGProps } from 'components/ui/Icon/internal/SVGs/types';

export default function AIInterpret(props: SVGProps): React.Element<'svg'> {
  return (
    <svg
      fill="none"
      height="16"
      width="16"
      {...props}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="m7.816 14.915-3.429.298.297-3.429 7.132-7.086a1.142 1.142 0 0 1 1.634 0l1.452 1.463a1.144 1.144 0 0 1 0 1.623l-7.086 7.131Z"
        fill="#D7E0FF"
      />
      <path
        d="m7.816 14.915-3.429.298.297-3.429 7.132-7.086a1.142 1.142 0 0 1 1.634 0l1.452 1.463a1.144 1.144 0 0 1 0 1.623l-7.086 7.131Z"
        stroke="#4147D5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.061 4.712c-.401-.07-.401-.645 0-.715a3.63 3.63 0 0 0 2.924-2.8l.024-.11c.087-.397.651-.4.742-.004l.029.129a3.649 3.649 0 0 0 2.932 2.783c.403.07.403.649 0 .719A3.649 3.649 0 0 0 4.78 7.497l-.03.129c-.09.396-.654.393-.74-.003l-.025-.11a3.63 3.63 0 0 0-2.924-2.8Z"
        fill="#fff"
      />
      <path
        d="M1.061 4.712c-.401-.07-.401-.645 0-.715a3.63 3.63 0 0 0 2.924-2.8l.024-.11c.087-.397.651-.4.742-.004l.029.129a3.649 3.649 0 0 0 2.932 2.783c.403.07.403.649 0 .719A3.649 3.649 0 0 0 4.78 7.497l-.03.129c-.09.396-.654.393-.74-.003l-.025-.11a3.63 3.63 0 0 0-2.924-2.8Z"
        stroke="#4147D5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
