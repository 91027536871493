// @flow
import * as React from 'react';

import type { SVGProps } from 'components/ui/Icon/internal/SVGs/types';

export default function AskAI(props: SVGProps): React.Element<'svg'> {
  return (
    <svg
      fill="none"
      height="20"
      width="20"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M14.754 9.023a.11.11 0 0 1-.094.11c-4.828.926-5.402 1.672-6.133 7.96a.111.111 0 0 1-.113.102.111.111 0 0 1-.113-.101c-.715-6.29-1.285-7.035-6.117-7.973a.11.11 0 0 1-.09-.11c0-.054.039-.1.09-.113C7.016 7.973 7.59 7.23 8.316.938A.114.114 0 0 1 8.43.84c.062 0 .11.043.117.101.715 6.29 1.285 7.036 6.113 7.973.055.012.094.055.094.11ZM17.914 14.828c0 .027-.02.05-.047.055-2.414.449-2.703.808-3.066 3.851-.004.028-.028.047-.059.047-.027 0-.055-.02-.055-.047-.359-3.043-.644-3.406-3.058-3.859a.054.054 0 0 1-.047-.05c0-.028.02-.052.047-.055 2.414-.45 2.703-.81 3.066-3.852a.055.055 0 0 1 .055-.047c.031 0 .055.02.059.047.359 3.043.644 3.406 3.058 3.855.028.008.047.032.047.055Zm0 0"
        fill="currentColor"
      />
    </svg>
  );
}
