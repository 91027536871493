// @flow
import * as React from 'react';

import Dimension from 'models/core/wip/Dimension';
import DimensionService from 'services/wip/DimensionService';
import GranularityService from 'services/wip/GranularityService';
import HierarchyItem from 'models/ui/HierarchicalSelector/HierarchyItem';
import LinkedCategory from 'models/core/wip/LinkedCategory';
import { buildGroupingHierarchy } from 'models/AdvancedQueryApp/QueryFormPanel/HierarchyTree';
import { cancelPromise } from 'util/promiseUtil';
import { noop } from 'util/util';
import type Granularity from 'models/core/wip/Granularity';
import type { GroupingItem } from 'models/core/wip/GroupingItem/types';

export default function useGroupingHierarchy(
  includeGranularities?: boolean = true,
): HierarchyItem<LinkedCategory | GroupingItem> {
  const [granularities, setGranularities] = React.useState<
    $ReadOnlyArray<Granularity>,
  >([]);

  const [dimensions, setDimensions] = React.useState<$ReadOnlyArray<Dimension>>(
    [],
  );

  React.useEffect(() => {
    const promise = DimensionService.getAll().then(setDimensions);
    return () => cancelPromise(promise);
  }, []);

  React.useEffect(() => {
    if (includeGranularities) {
      const promise = GranularityService.getAll().then(setGranularities);
      return () => cancelPromise(promise);
    }
    return noop;
  }, [includeGranularities]);

  const hierarchy = React.useMemo(
    () => buildGroupingHierarchy(dimensions, granularities),
    [dimensions, granularities],
  );
  return hierarchy;
}
