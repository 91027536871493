// @flow
import * as React from 'react';

import Button from 'components/ui/Button';
import Group from 'components/ui/Group';
import Icon from 'components/ui/Icon';

type Props = {
  className?: string,
  onClick: () => void,
  text: string,
};

function AddItemButton({ className, onClick, text }: Props) {
  return (
    <Button className={className} minimal onClick={onClick}>
      <Group.Horizontal alignItems="center" flex spacing="xxs">
        <Icon className="ca-add-item-button" type="svg-add" />
        {text}
      </Group.Horizontal>
    </Button>
  );
}

export default (React.memo(AddItemButton): React.AbstractComponent<Props>);
