// @flow
import { useEffect, useState, useMemo } from 'react';

import DataUploadService from 'services/DataUploadApp/DataUploadService';
import Moment from 'models/core/wip/DateTime/Moment';
import { cancelPromise } from 'util/promiseUtil';
import type { PipelineRunMetadata } from 'models/DataUploadApp/types';

export default function usePipelineTimes(): {
  lastPipelineRuntime: Moment | void,
  nextPipelineRuntime: Moment | void,
} {
  const [
    pipelineRunMetadata,
    setpipelineRunMetadata,
  ] = useState<PipelineRunMetadata | void>(undefined);

  useEffect(() => {
    const promise = DataUploadService.getPipelineRunMetadata().then(
      setpipelineRunMetadata,
    );
    return () => cancelPromise(promise);
  }, []);

  return useMemo(() => {
    const lastSuccessfulPipelineData = pipelineRunMetadata?.lastPipelineRuntime;
    const nextPipelineRuntime = pipelineRunMetadata?.nextPipelineRuntime;

    return {
      lastPipelineRuntime: lastSuccessfulPipelineData
        ? Moment.utc(lastSuccessfulPipelineData).local()
        : undefined,
      nextPipelineRuntime: nextPipelineRuntime
        ? Moment.utc(nextPipelineRuntime).local()
        : undefined,
    };
  }, [pipelineRunMetadata]);
}
