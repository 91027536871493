// @flow
import 'url-search-params-polyfill';

import { IS_ENG_USER, IS_ZENYSIS_USER } from 'util/util';

export const ENABLED_DATA_CATALOG_APP: boolean =
  IS_ZENYSIS_USER || window.__JSON_FROM_BACKEND.deploymentName === 'rw';

// TODO: Dimension management is being soft launched to internal users. Once
// validated, remove Data Catalog gating.
export const ENABLED_DIMENSION_MANAGEMENT: boolean = IS_ZENYSIS_USER;

export const ENABLED_EDITING_PRIVILEGED_PROPERTIES: boolean = IS_ENG_USER;
