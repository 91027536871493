// @flow
import * as React from 'react';

import Button from 'components/ui/Button';
import CohortCalculation from 'models/core/wip/Calculation/CohortCalculation';
import CohortCreationModal from 'components/common/QueryBuilder/CustomizableIndicatorTag/IndicatorCustomizationModule/CalculationCustomizationBlock/CohortCustomizationBlock/CohortCreationModal';
import I18N from 'lib/I18N';
import IndicatorSelector from 'components/AdvancedQueryApp/QueryFormPanel/QueryBuilder/IndicatorSelectionBlock/IndicatorSelector';
import type Dimension from 'models/core/wip/Dimension';

type Props = {
  calculation: CohortCalculation,
  calculationId: string,
  dimensions: $ReadOnlyArray<Dimension>,
  dimensionValueMap: $PropertyType<
    React.ElementConfig<typeof CohortCreationModal>,
    'dimensionValueMap',
  >,
  fieldHierarchyRoot: $PropertyType<
    React.ElementConfig<typeof IndicatorSelector>,
    'hierarchyRoot',
  >,
  onCalculationChange: CohortCalculation => void,
  trackItemSelected: $PropertyType<
    React.ElementConfig<typeof CohortCreationModal>,
    'trackItemSelected',
  >,
};

function CohortCustomizationBlock({
  calculation,
  calculationId,
  dimensionValueMap,
  dimensions,
  fieldHierarchyRoot,
  onCalculationChange,
  trackItemSelected,
}: Props): React.Node {
  const [showModal, setShowModal] = React.useState<boolean>(false);
  return (
    <div className="cohort-customization-block">
      <Button
        className="cohort-customization-block__button"
        onClick={() => setShowModal(true)}
      >
        {I18N.text('Build cohort')}
      </Button>
      <CohortCreationModal
        calculation={calculation}
        calculationId={calculationId}
        dimensions={dimensions}
        dimensionValueMap={dimensionValueMap}
        fieldHierarchyRoot={fieldHierarchyRoot}
        onCalculationChange={onCalculationChange}
        onCloseModal={() => setShowModal(false)}
        show={showModal}
        trackItemSelected={trackItemSelected}
      />
    </div>
  );
}

export default (React.memo(
  CohortCustomizationBlock,
): React.AbstractComponent<Props>);
