// @flow
import * as React from 'react';

import type { SVGProps } from 'components/ui/Icon/internal/SVGs/types';

export default function Dashboards(props: SVGProps): React.Element<'svg'> {
  return (
    <svg
      fill="none"
      viewBox="0 0 60 60"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0)" fill="#266CA5">
        <path d="M47.498 58.721c-.299 0-.596-.04-.885-.118a3.295 3.295 0 01-2.036-1.554l-11.36-19.672h7.78l9.413 16.298a3.374 3.374 0 01-1.23 4.594c-.509.3-1.09.457-1.682.452zM36.62 39.344l9.659 16.722a1.397 1.397 0 002.321.15 1.396 1.396 0 00.098-1.557l-8.842-15.315H36.62z" />
        <path d="M30 60a3.364 3.364 0 01-3.364-3.364V37.377h6.728v19.26A3.364 3.364 0 0130 60zm-1.397-20.656v17.292a1.396 1.396 0 102.794 0V39.344h-2.794z" />
        <path d="M12.502 58.721a3.265 3.265 0 01-1.673-.452 3.374 3.374 0 01-1.239-4.603l9.413-16.289h7.78L15.434 57.05a3.346 3.346 0 01-2.931 1.672zm7.642-19.377L11.292 54.66a1.386 1.386 0 00.096 1.556c.113.147.255.27.415.362a1.397 1.397 0 001.967-.511l9.66-16.722h-3.286z" />
        <path d="M53.508 38.843H6.492a2.419 2.419 0 01-2.42-2.45V6.886h51.856v29.509a2.419 2.419 0 01-2.42 2.449zM5.056 7.869v28.525a1.436 1.436 0 001.436 1.465h47.016a1.434 1.434 0 001.436-1.465V7.869H5.056z" />
        <path d="M53.508 39.344H6.492a2.95 2.95 0 01-2.951-2.95V6.364H56.42v30.03a2.951 2.951 0 01-2.912 2.95zM5.548 8.331v28.063a.984.984 0 00.983.983h46.977a.984.984 0 00.984-.983V8.33H5.548z" />
        <path d="M55.436 8.331H4.564a4.17 4.17 0 110-8.331h50.872a4.17 4.17 0 110 8.331zM4.564 1.967a2.203 2.203 0 100 4.397h50.872a2.203 2.203 0 100-4.397H4.564zM18.875 32.97a4.16 4.16 0 114.161-4.16 4.17 4.17 0 01-4.16 4.16zm0-6.354a2.193 2.193 0 100 4.386 2.193 2.193 0 000-4.386zM35.134 20.656a.983.983 0 01-.688-1.672l5.616-5.627a.982.982 0 011.397 0 .984.984 0 010 1.387l-5.626 5.627a.982.982 0 01-.699.285z" />
        <path d="M40.76 20.656a.982.982 0 01-.698-.285l-5.636-5.617a.984.984 0 011.387-1.387l5.626 5.627a.983.983 0 010 1.386.983.983 0 01-.678.276zM35.921 32.528a2.715 2.715 0 01-1.967-.895L19.298 15.63a.767.767 0 00-1.072-.06l-5.065 4.437a.984.984 0 01-1.299-1.476l5.076-4.436a2.754 2.754 0 013.816.207L35.41 30.315a.768.768 0 00.934.147l3.846-2.272a.983.983 0 111.003 1.692l-3.875 2.292c-.426.238-.908.36-1.397.354z" />
        <path d="M42.65 32.882a1.14 1.14 0 01-.984-.55l-3.217-5.49a1.121 1.121 0 010-1.11 1.15 1.15 0 01.984-.561h6.364a1.111 1.111 0 01.983 1.652l-3.157 5.538a1.102 1.102 0 01-.974.521zm-1.722-5.754l1.711 2.95 1.672-2.95h-3.383z" />
      </g>
      <defs>
        <clipPath id="clip0">
          <path d="M0 0h60v60H0V0z" fill="#fff" />
        </clipPath>
      </defs>
    </svg>
  );
}
