// @flow
import * as React from 'react';

import type { SVGProps } from 'components/ui/Icon/internal/SVGs/types';

export default function AIHypothesis(props: SVGProps): React.Element<'svg'> {
  return (
    <svg
      fill="none"
      height="16"
      width="16"
      {...props}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.873 5.75a5.144 5.144 0 1 0-7.429 4.571v1.714a.571.571 0 0 0 .572.572h3.428a.571.571 0 0 0 .572-.572v-1.714a5.12 5.12 0 0 0 2.857-4.571Z"
        fill="#D7E0FF"
      />
      <path
        d="M9.598 9.109a5.12 5.12 0 0 1-1.582 1.212v1.714a.571.571 0 0 1-.572.572H4.016a.571.571 0 0 1-.572-.572v-1.714a5.143 5.143 0 0 1 4.404-9.293M3.444 15.429h4.572"
        stroke="#4147D5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.46 5.076c-.402-.07-.402-.645 0-.715a3.63 3.63 0 0 0 2.923-2.8l.025-.11c.086-.397.65-.4.741-.004l.03.129a3.649 3.649 0 0 0 2.931 2.783c.404.07.404.65 0 .72a3.649 3.649 0 0 0-2.932 2.783l-.029.128c-.09.396-.655.393-.741-.003l-.024-.11a3.63 3.63 0 0 0-2.925-2.8Z"
        fill="#fff"
      />
      <path
        d="M8.46 5.076c-.402-.07-.402-.645 0-.715a3.63 3.63 0 0 0 2.923-2.8l.025-.11c.086-.397.65-.4.741-.004l.03.129a3.649 3.649 0 0 0 2.931 2.783c.404.07.404.65 0 .72a3.649 3.649 0 0 0-2.932 2.783l-.029.128c-.09.396-.655.393-.741-.003l-.024-.11a3.63 3.63 0 0 0-2.925-2.8Z"
        stroke="#4147D5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
