// @flow
import * as React from 'react';

import type { SVGProps } from 'components/ui/Icon/internal/SVGs/types';

export default function Refresh(props: SVGProps): React.Element<'svg'> {
  return (
    <svg
      height="17"
      viewBox="0 0 16 17"
      width="16"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M6 5.49986H3.39C4.03667 4.50602 4.98721 3.74777 6.09991 3.33815C7.21261 2.92852 8.4279 2.88944 9.56462 3.22675C10.7013 3.56405 11.6986 4.25967 12.4078 5.20992C13.117 6.16017 13.5001 7.31415 13.5 8.49986H14.5C14.5014 7.1626 14.0903 5.85747 13.3227 4.76241C12.5552 3.66736 11.4686 2.83568 10.2111 2.38079C8.95358 1.9259 7.58637 1.86994 6.29589 2.22053C5.00541 2.57112 3.85447 3.3112 3 4.33986V2.49986H2V6.49986H6V5.49986Z"
        fill="currentColor"
      />
      <path
        d="M10 11.5H12.61C11.9633 12.4938 11.0128 13.2521 9.90009 13.6617C8.78739 14.0713 7.5721 14.1104 6.43539 13.7731C5.29867 13.4358 4.30139 12.7402 3.59221 11.7899C2.88303 10.8397 2.49993 9.68571 2.5 8.5H1.5C1.49863 9.83726 1.90973 11.1424 2.67727 12.2374C3.4448 13.3325 4.53141 14.1642 5.78892 14.6191C7.04642 15.074 8.41363 15.1299 9.70411 14.7793C10.9946 14.4287 12.1455 13.6887 13 12.66V14.5H14V10.5H10V11.5Z"
        fill="currentColor"
      />
    </svg>
  );
}
