// @flow
import type Promise from 'bluebird';

import * as Zen from 'lib/Zen';
import AlertCaseCoreInfo from 'models/CaseManagementApp/AlertCaseCoreInfo';
import AlertCaseType from 'models/CaseManagementApp/AlertCaseType';
import AlertDefinition from 'models/AlertsApp/AlertDefinition';
import CaseEvent from 'models/CaseManagementApp/CaseEvent';
import ExternalAlert from 'models/CaseManagementApp/ExternalAlert';
import type AlertNotification from 'models/AlertsApp/AlertNotification';
import type DruidCaseType from 'models/CaseManagementApp/DruidCaseType';
import type { Deserializable } from 'lib/Zen';
import type { ExternalAlertActivity } from 'models/CaseManagementApp/ExternalAlert';

type Values = {
  /** Full alert definition for this alert */
  alertDefinition: AlertDefinition,

  coreInfo: AlertCaseCoreInfo,

  /** URI for this case. */
  uri: string,
};

type DefaultValues = {
  events: Zen.Array<CaseEvent>,

  /**
   * A map of URI to values of metadata rows held by this case.
   * In the Alert's case, this is a misnomer because some metadata here also
   * comes from external alerts.
   *
   * TODO(pablo): this should be split up so that metadata from external alerts
   * gets tracked separately.
   */
  metadataValuesFromUser: Zen.Map<string>,
};

// Move some commonly accessed pieces of information from `coreInfo` to a
// top-level `CaseUnit` value, so that we don't have to do things like
// case.coreInfo().name() every time.
type DerivedValues = {
  alertNotification: AlertNotification,
  caseType: 'alert',
  name: string,
  type: AlertCaseType,
};

type SerializedAlertCase = {
  ...Zen.Serialized<AlertCaseCoreInfo>,
  $uri: string,
  alertDefinition: Zen.Serialized<AlertDefinition>,
  events: Array<Zen.Serialized<CaseEvent>>,
  metadataValues: { +[string]: string, ... },
};

type AlertDeserializationConfig = Zen.DeserializationConfig<AlertCaseCoreInfo>;

class AlertCase
  extends Zen.BaseModel<AlertCase, Values, DefaultValues, DerivedValues>
  implements Deserializable<SerializedAlertCase, AlertDeserializationConfig> {
  tag: 'ALERT' = 'ALERT';

  static defaultValues: DefaultValues = {
    events: Zen.Array.create(),
    metadataValuesFromUser: Zen.Map.create(),
  };

  static derivedConfig: Zen.DerivedConfig<AlertCase, DerivedValues> = {
    alertNotification: [
      Zen.hasChangedDeep('coreInfo.alertNotification'),
      (caseUnit: Zen.Model<AlertCase>) =>
        caseUnit.coreInfo().alertNotification(),
    ],
    caseType: [
      Zen.hasChangedDeep('coreInfo.type'),
      (caseUnit: Zen.Model<AlertCase>) =>
        caseUnit
          .coreInfo()
          .type()
          .caseType()
          .toLowerCase(),
    ],
    name: [
      Zen.hasChangedDeep('coreInfo.name'),
      (caseUnit: Zen.Model<AlertCase>) => caseUnit.coreInfo().name(),
    ],
    type: [
      Zen.hasChangedDeep('coreInfo.type'),
      (caseUnit: Zen.Model<AlertCase>) => caseUnit.coreInfo().type(),
    ],
  };

  static deserializeAsync(
    serializedAlertCase: SerializedAlertCase,
    alertDeserializationConfig: AlertDeserializationConfig,
  ): Promise<Zen.Model<AlertCase>> {
    const {
      $uri,
      alertDefinition,
      events,
      metadataValues,
      ...serializedAlertCaseCoreInfo
    } = serializedAlertCase;
    return AlertDefinition.deserializeAsync(alertDefinition).then(
      deserializedDefinition => {
        // TODO(toshi): Fix metadata later
        return AlertCase.create({
          alertDefinition: deserializedDefinition,
          coreInfo: AlertCaseCoreInfo.deserialize(
            serializedAlertCaseCoreInfo,
            alertDeserializationConfig,
          ),
          events: Zen.deserializeToZenArray(CaseEvent, events),
          metadataValuesFromUser: Zen.Map.create(),
          uri: $uri,
        });
      },
    );
  }

  getLinkedDimension(
    caseConfigMap: Zen.Map<AlertCaseType | DruidCaseType>,
  ): { +dimensionDisplayValue: string, +dimensionId: string } {
    const {
      dimensionId,
      dimensionValue,
    } = this._.alertNotification().modelValues();
    if (
      caseConfigMap
        .values()
        .some(
          caseConfig =>
            caseConfig.get('caseType') === 'DRUID' &&
            Zen.cast<DruidCaseType>(caseConfig).primaryDruidDimension() ===
              dimensionId,
        )
    ) {
      return {
        dimensionId,
        dimensionDisplayValue: dimensionValue,
      };
    }

    throw new Error(
      '[AlertCase] This AlertCase is linked to a dimension that is not in the Case Management app config. All dimensions related to an Alert must have a case config.',
    );
  }

  mergeExternalAlert(externalAlert: ExternalAlert): Zen.Model<AlertCase> {
    const { activities, alertSource } = externalAlert.modelValues();
    const type = this._.type();
    const internalMetadata = this._.metadataValuesFromUser();
    const internalEvents = this._.events();
    const { externalAlertTypes, metadataDescriptors } = type.modelValues();
    const externalAlertType = externalAlertTypes.forceGet(alertSource);

    // pull all the necessary metadata from the external alert
    // NOTE(abby): this is dependent on the case metadata descriptors matching
    // the label of the external alerts metadata and the metadata not being
    // editable
    const externalCaseMetadata = {};
    metadataDescriptors.values().forEach(metadata => {
      if (
        metadata.label() in externalAlert &&
        internalMetadata.get(metadata.uri(), metadata.emptyDisplayValue()) !==
          externalAlert[metadata.label()]
      ) {
        externalCaseMetadata[metadata.label()] =
          externalAlert[metadata.label()];
      }
    });

    // pull all the events from the external alert's activities
    const externalEvents = [];
    activities.forEach((externalActivity: ExternalAlertActivity) => {
      const { action } = externalActivity;
      if (!externalAlertType.activitiesToIgnore.includes(action)) {
        externalEvents.push(
          CaseEvent.createExternalAlertActivityEvent(
            externalActivity,
            alertSource,
          ),
        );
      }
    });

    const lastInternalUpdateDate = internalEvents.isEmpty()
      ? undefined
      : internalEvents.last().created();
    return this.modelValues({
      coreInfo: this._.coreInfo().mergeExternalAlert(
        externalAlert,
        lastInternalUpdateDate,
      ),
      events: CaseEvent.sortEvents(internalEvents.concat(externalEvents)),
      metadataValuesFromUser: internalMetadata.merge(externalCaseMetadata),
    });
  }
}

export default ((AlertCase: $Cast): Class<Zen.Model<AlertCase>>);
