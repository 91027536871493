// @flow
import Promise from 'bluebird';

import APIService, { API_VERSION } from 'services/APIService';
import DimensionValue from 'models/core/wip/Dimension/DimensionValue';
import autobind from 'decorators/autobind';
import type { APIVersion, HTTPService } from 'services/APIService';

// TODO(david): Add a cache so we do not have to make repeat requests for the
// same search term. (The current cache does not do this - it only ensures
// that we do not have multiple DimensionValue instances representing the same
// dimension value).
class DimensionValueSearchService {
  apiVersion: APIVersion = API_VERSION.V2;
  endpoint: string = 'query/dimension_values/search';
  _cache: { [dimensionId: string]: DimensionValue, ... };
  _httpService: HTTPService;

  constructor(httpService: HTTPService) {
    this._httpService = httpService;
    this._cache = {};
  }

  /**
   * Search for matching DimensionValues with a given search term in a
   * Dimension. If the search term is an empty string, this will
   * automatically return an empty array.
   */
  @autobind
  get(searchTerm: string, dimensionId: string): Promise<Array<DimensionValue>> {
    // empty string is not a valid search term for a dimension value
    if (searchTerm === '') {
      return Promise.resolve([]);
    }

    const queryString = `?where={"name":{"$icontains":"${searchTerm}"}}`;

    const uri = `${this.endpoint}/${dimensionId}${queryString}`;
    return this._httpService
      .get(this.apiVersion, uri)
      .then(rawDimensionValuesList =>
        Promise.all(
          rawDimensionValuesList.map(DimensionValue.deserializeAsync),
        ),
      )
      .then(dimensionValues =>
        // Note(david): This cache ensures that we do not construct
        // a new object representing the same dimension value as one that has
        // previously been searched. This is needed as the filter selection
        // block relies on referential equality between identical dimension
        // values.
        dimensionValues.map(dimensionValue => {
          if (!this._cache[dimensionValue.id()]) {
            this._cache[dimensionValue.id()] = dimensionValue;
          }
          return this._cache[dimensionValue.id()];
        }),
      );
  }

  /**
   * Find the matching dimension value from a set of dimension<>value key
   * pairs. If no matching dimension value is found, return undefined.
   */
  @autobind
  getFromDimensionValueNames(
    dimensionValueNames: {
      +[dimensionID: string]: string | null,
      ...,
    },
    dimensionIdToSearch: string,
  ): Promise<DimensionValue | void> {
    const dimensionValueName = dimensionValueNames[dimensionIdToSearch];

    if (dimensionValueName) {
      return this.get(dimensionValueName, dimensionIdToSearch).then(
        dimensionValues => {
          const matchingValues = dimensionValues.filter(
            dimensionValue => dimensionValue.name() === dimensionValueName,
          );

          if (matchingValues.length === 0) {
            return undefined;
          }

          // If there is only one matching value, assume it is the correct
          // match.
          if (matchingValues.length === 1) {
            return matchingValues[0];
          }

          // If there is more than one matching value, we have to parse the
          // filter to ensure we select the correct one.

          // We can only ensure that it is the correct value if the filter
          // follows the expected type: AndFilter containing SelectorFilters.
          const seriesDimensionCount = Object.keys(dimensionValueNames).length;
          return matchingValues.find(dimensionValue => {
            const queryFilter = dimensionValue.filter();
            // If the filter is not an AndFilter, then we cannot safely match.
            if (!(queryFilter.tag === 'AND')) {
              return false;
            }

            // If the number of inner filters is different from the number of
            // dimensions in the data point, then this filter is filtering too
            // much and is not a match.
            const filterFields = queryFilter.fields();
            if (filterFields.size() !== seriesDimensionCount) {
              return false;
            }

            // If all the inner filters are SelectorFilters AND each value
            // being filtered on is the same value in the data point's
            // dimensions, then we have a match.
            return filterFields.every(
              filter =>
                filter.tag === 'SELECTOR' &&
                filter.value() === dimensionValueNames[filter.dimension()],
            );
          });
        },
      );
    }
    return Promise.resolve(undefined);
  }
}

export default (new DimensionValueSearchService(
  APIService,
): DimensionValueSearchService);
