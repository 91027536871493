// @flow
import * as React from 'react';

import type { SVGProps } from 'components/ui/Icon/internal/SVGs/types';

export default function QuestionMark(props: SVGProps): React.Element<'svg'> {
  return (
    <svg
      height="24"
      viewBox="0 0 34 59"
      width="24"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M16.98.362C8.61.362 1.786 5.814.088 12.612c-.402 1.529.635 3.285 2.168 3.672 1.533.387 3.279-.667 3.65-2.203.913-3.65 5.43-7.719 11.076-7.719 2.781 0 5.7 1.021 7.759 2.688 2.058 1.666 3.253 3.812 3.253 6.312 0 3.283-.67 4.757-1.564 5.906-.894 1.15-2.326 2.043-4.192 3.375-1.866 1.333-4.087 3.173-5.694 6.157-1.607 2.984-2.534 6.979-2.534 12.562-.156 1.657 1.323 3.281 2.987 3.281 1.665 0 3.144-1.623 2.988-3.28 0-4.917.828-7.828 1.846-9.72 1.018-1.89 2.276-2.926 3.91-4.093 1.635-1.168 3.713-2.368 5.444-4.594C32.916 22.73 34 19.579 34 15.362c0-4.5-2.315-8.385-5.506-10.969C25.303 1.81 21.193.363 16.98.363zm.032 50a4.002 4.002 0 00-4.004 4c0 2.21 1.792 4 4.004 4a4.002 4.002 0 004.004-4c0-2.209-1.793-4-4.004-4z"
        fill="#000"
        fillRule="nonzero"
      />
    </svg>
  );
}
