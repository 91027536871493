// @flow
import * as React from 'react';

import type { SVGProps } from 'components/ui/Icon/internal/SVGs/types';

export default function Resize(props: SVGProps): React.Element<'svg'> {
  return (
    <svg
      height="24px"
      viewBox="0 0 24 24"
      width="24px"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M10.5857864,13.4142136 C9.80473785,12.633165 9.80473785,11.366835 10.5857864,10.5857864 C11.366835,9.80473785 12.633165,9.80473785 13.4142136,10.5857864 C14.1952621,11.366835 14.1952621,12.633165 13.4142136,13.4142136 C12.633165,14.1952621 11.366835,14.1952621 10.5857864,13.4142136 Z M19,20 L13,20 C12.4477153,20 12,19.5522847 12,19 C12,18.4477153 12.4477153,18 13,18 L18,18 L18,13 C18,12.4477153 18.4477153,12 19,12 C19.5522847,12 20,12.4477153 20,13 L20,19 C20,19.5522847 19.5522847,20 19,20 Z M5,4 L11,4 C11.5522847,4 12,4.44771525 12,5 C12,5.55228475 11.5522847,6 11,6 L6,6 L6,11 C6,11.5522847 5.55228475,12 5,12 C4.44771525,12 4,11.5522847 4,11 L4,5 C4,4.44771525 4.44771525,4 5,4 Z" />
    </svg>
  );
}
