// @flow
import * as Zen from 'lib/Zen';
import NumericValueCohortFilter from 'models/core/wip/Calculation/CohortCalculation/NumericValueCohortFilter';
import type { NumericValueCohortFilterWrapper } from 'models/core/wip/Calculation/CohortCalculation/NumericValueCohortFilterItem/NumericValueCohortFilterItemUtil';
import type { Serializable } from 'lib/Zen';

type Values = {
  value: number,
};

type SerializedAtLeastFilter = {
  type: 'AT_LEAST',
  value: number,
};

class AtLeastFilter extends Zen.BaseModel<AtLeastFilter, Values>
  implements
    Serializable<SerializedAtLeastFilter>,
    NumericValueCohortFilterWrapper {
  static tag: 'AT_LEAST' = 'AT_LEAST';
  tag: 'AT_LEAST' = 'AT_LEAST';

  static deserialize(
    serializedAtLeastFilter: SerializedAtLeastFilter,
  ): Zen.Model<AtLeastFilter> {
    const { value } = serializedAtLeastFilter;

    return AtLeastFilter.create({ value });
  }

  filter(): NumericValueCohortFilter {
    return NumericValueCohortFilter.create({
      lowerBound: this._.value(),
      lowerBoundStrict: false,
    });
  }

  serialize(): SerializedAtLeastFilter {
    return { type: this.tag, value: this._.value() };
  }
}

export default ((AtLeastFilter: $Cast): Class<Zen.Model<AtLeastFilter>>);
