// @flow
import * as React from 'react';

import type { SVGProps } from 'components/ui/Icon/internal/SVGs/types';

export default function EyeDisabled(props: SVGProps): React.Element<'svg'> {
  return (
    <svg
      fill="none"
      height="14"
      viewBox="0 0 16 14"
      width="16"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        clipRule="evenodd"
        d="M3.1265 3.34997L1.33984 1.5633L2.27984 0.616638L14.0998 12.4433L13.1598 13.3833L10.8798 11.1033C9.9865 11.4566 9.01317 11.65 7.99984 11.65C4.6665 11.65 1.81984 9.57664 0.666504 6.64997C1.17984 5.33664 2.03984 4.2033 3.1265 3.34997ZM7.99984 2.9833C10.5265 2.9833 12.7798 4.4033 13.8798 6.64997C13.4865 7.4633 12.9332 8.1633 12.2732 8.72997L13.2132 9.66997C14.1398 8.84997 14.8732 7.8233 15.3332 6.64997C14.1798 3.7233 11.3332 1.64997 7.99984 1.64997C7.15317 1.64997 6.33984 1.7833 5.57317 2.02997L6.67317 3.12997C7.1065 3.0433 7.5465 2.9833 7.99984 2.9833ZM7.2865 3.7433L8.6665 5.1233C9.0465 5.28997 9.35317 5.59664 9.51984 5.97664L10.8998 7.35664C10.9532 7.12997 10.9932 6.88997 10.9932 6.6433C10.9998 4.98997 9.65317 3.64997 7.99984 3.64997C7.75317 3.64997 7.51984 3.6833 7.2865 3.7433ZM6.33984 6.5633L8.07984 8.3033C8.05317 8.30997 8.0265 8.31664 7.99984 8.31664C7.07984 8.31664 6.33317 7.56997 6.33317 6.64997C6.33317 6.6333 6.33484 6.61997 6.3365 6.60664C6.33817 6.5933 6.33984 6.57997 6.33984 6.5633ZM5.23984 5.4633L4.07317 4.29664C3.2665 4.90997 2.5865 5.69664 2.11984 6.64997C3.21984 8.89664 5.47317 10.3166 7.99984 10.3166C8.63317 10.3166 9.2465 10.2233 9.83317 10.0633L9.17984 9.40997C8.81984 9.5633 8.41984 9.64997 7.99984 9.64997C6.3465 9.64997 4.99984 8.3033 4.99984 6.64997C4.99984 6.22997 5.0865 5.82997 5.23984 5.4633Z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  );
}
