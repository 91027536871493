// @flow
import * as Zen from 'lib/Zen';
import CaseEvent from 'models/CaseManagementApp/CaseEvent';
import CaseMetadataDescriptor from 'models/CaseManagementApp/CaseMetadataDescriptor';
import CaseStatusDescriptor from 'models/CaseManagementApp/CaseStatusDescriptor';
import type {
  CaseType,
  SerializedCaseType,
} from 'models/CaseManagementApp/util';
import type { Serializable } from 'lib/Zen';

export type ExternalAlertType = {
  /**
   * External alerts can have a lot of activity, and not necessarily all of
   * them should be things we care about adding to our case timeline. This
   * is a set of activity IDs to ignore.
   */
  activitiesToIgnore: Zen.Array<string>,

  /**
   * The druid dimension where we dump all our external Alert JSON data.
   * E.g. in MZ this is 'AlertData'. This holds all relevant external
   * alert information, like generation date, status, etc.
   */
  // TODO(pablo): this is not a good name. Rename to externalAlertDataDimension
  dataDimension: string,

  /**
   * The dimension this alert is being triggered for. E.g. FacilityName,
   * DistrictName, ProvinceName, etc.
   */
  druidDimension: string,

  /**
   * The druid field ID we use to store an alert.
   * E.g. in MZ this is `ewars_alert_count`
   */
  fieldId: string,
};

type SerializedExternalAlertType = {
  activitiesToIgnore: $ReadOnlyArray<string>,
  dataDimension: string,
  druidDimension: string,
  fieldId: string,
  name: string,
};

type RequiredValues = {
  ...CaseType,

  /** Map of alert source name to configs for external alerts that will be
   * merged. */
  externalAlertTypes: Zen.Map<ExternalAlertType>,
};

type DefaultValues = {
  /**
   * This flag determines if an alert status set through Zenysis should always
   * take priority over an external alert that matched with this alert.
   * If true, then the moment the user makes any change to an alert status
   * through Zenysis, we will stop merging in the status from any external
   * sources. If false, then even if the user has changed the status internally,
   * we will go through the normal merging flow to decide if we should take the
   * Zenysis or External Alert's status.
   */
  alwaysUseZenysisAlertStatus: boolean,

  /**
   * The default alert source. E.g. in MZ they refer to Zenysis as 'mAlert',
   * so we set 'mAlert' as the defaultAlertSource on the backend.
   */
  defaultAlertSource: string,
};

type SerializedAlertCaseType = {
  ...SerializedCaseType,
  externalAlertTypes: Array<SerializedExternalAlertType>,
  spec: {
    +alwaysUseZenysisAlertStatus?: boolean,
    +defaultAlertSource?: string,
  } | null,
};

/**
 * AlertCaseType is a description of the configs and default values for an
 * alert case.
 */
class AlertCaseType
  extends Zen.BaseModel<AlertCaseType, RequiredValues, DefaultValues>
  implements Serializable<SerializedAlertCaseType> {
  tag: 'ALERT' = 'ALERT';

  static defaultValues: DefaultValues = {
    alwaysUseZenysisAlertStatus: false,
    defaultAlertSource: 'Zenysis',
  };

  static deserialize(
    serializedAlertCaseType: SerializedAlertCaseType,
  ): Zen.Model<AlertCaseType> {
    const {
      $uri,
      canUsersAddEvents,
      caseType,
      defaultDashboardQueries,
      defaultEvents,
      defaultStatusUri,
      externalAlertTypes,
      isMetadataExpandable,
      metadataDescriptors,
      quickStatsFields,
      showCaseTypeInDossier,
      spec,
      statusDescriptors,
    } = serializedAlertCaseType;

    const metadataDescriptorsMap = {};
    Zen.deserializeArray(CaseMetadataDescriptor, metadataDescriptors).forEach(
      metadata => {
        metadataDescriptorsMap[metadata.uri()] = metadata;
      },
    );

    const statusDescriptorsMap = {};
    Zen.deserializeArray(CaseStatusDescriptor, statusDescriptors).forEach(
      status => {
        statusDescriptorsMap[status.uri()] = status;
      },
    );
    const statusDescriptorsZenMap = Zen.Map.create(statusDescriptorsMap);

    const externalAlertTypesMap = {};
    externalAlertTypes.forEach(externalAlertType => {
      externalAlertTypesMap[externalAlertType.name] = {
        activitiesToIgnore: Zen.Array.create(
          externalAlertType.activitiesToIgnore,
        ),
        dataDimension: externalAlertType.dataDimension,
        druidDimension: externalAlertType.druidDimension,
        fieldId: externalAlertType.fieldId,
      };
    });

    return AlertCaseType.create({
      canUsersAddEvents,
      caseType,
      isMetadataExpandable,
      showCaseTypeInDossier,
      alwaysUseZenysisAlertStatus: spec
        ? spec.alwaysUseZenysisAlertStatus
        : undefined,
      defaultAlertSource: spec ? spec.defaultAlertSource : undefined,
      defaultDashboardQueries: Zen.Array.create(
        defaultDashboardQueries.map(dashboardQuery => ({
          fields: Zen.Array.create(dashboardQuery.fields || []),
          granularity: dashboardQuery.granularity,
          viewType: dashboardQuery.viewType,
        })),
      ),
      defaultEvents: Zen.deserializeToZenArray(CaseEvent, defaultEvents),
      defaultStatus: statusDescriptorsZenMap.forceGet(defaultStatusUri),
      externalAlertTypes: Zen.Map.create(externalAlertTypesMap),
      metadataDescriptors: Zen.Map.create(metadataDescriptorsMap),
      quickStatsFields: Zen.Array.create(quickStatsFields),
      statusDescriptors: statusDescriptorsZenMap,
      uri: $uri,
    });
  }

  serialize(): SerializedAlertCaseType {
    const {
      alwaysUseZenysisAlertStatus,
      canUsersAddEvents,
      caseType,
      defaultAlertSource,
      defaultDashboardQueries,
      defaultEvents,
      defaultStatus,
      externalAlertTypes,
      isMetadataExpandable,
      metadataDescriptors,
      quickStatsFields,
      showCaseTypeInDossier,
      statusDescriptors,
      uri,
    } = this.modelValues();

    return {
      canUsersAddEvents,
      caseType,
      isMetadataExpandable,
      showCaseTypeInDossier,
      $uri: uri,
      defaultDashboardQueries: defaultDashboardQueries
        .arrayView()
        .map(dashboardQuery => ({
          fields: dashboardQuery.fields.arrayView(),
          granularity: dashboardQuery.granularity,
          viewType: dashboardQuery.viewType,
        })),
      defaultEvents: Zen.serializeArray(defaultEvents),
      defaultStatusUri: defaultStatus.uri(),
      externalAlertTypes: externalAlertTypes.entries().map(entry => {
        const [name, type] = entry;
        return {
          name,
          activitiesToIgnore: type.activitiesToIgnore.arrayView(),
          dataDimension: type.dataDimension,
          druidDimension: type.druidDimension,
          fieldId: type.fieldId,
        };
      }),
      metadataDescriptors: Zen.serializeArray(metadataDescriptors.zenValues()),
      quickStatsFields: quickStatsFields.arrayView(),
      spec: {
        alwaysUseZenysisAlertStatus,
        defaultAlertSource,
      },
      statusDescriptors: Zen.serializeArray(statusDescriptors.zenValues()),
    };
  }
}

export default ((AlertCaseType: $Cast): Class<Zen.Model<AlertCaseType>>);
