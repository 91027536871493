// @flow
import GroupingDimension from 'models/core/wip/GroupingItem/GroupingDimension';
import GroupingGranularity from 'models/core/wip/GroupingItem/GroupingGranularity';
import LinkedCategory from 'models/core/wip/LinkedCategory';
import MutableHierarchyItem from 'models/ui/HierarchicalSelector/MutableHierarchyItem';
import { getFullDimensionName } from 'models/core/wip/Dimension';
import type Dimension from 'models/core/wip/Dimension';
import type DimensionValue from 'models/core/wip/Dimension/DimensionValue';
import type Field from 'models/core/wip/Field';
import type Granularity from 'models/core/wip/Granularity';
import type HierarchyItem from 'models/ui/HierarchicalSelector/HierarchyItem';
import type { NamedItem } from 'models/ui/HierarchicalSelector/types';

/**
 * HierarchyTree is a *mutable* model. It is not intended to be passed around
 * React components, but is instead a helper model to easily take flattened
 * arrays of HierarchyItems and generate a tree.
 *
 * Once the tree is created we can take the root of this model (which is an
 * immutable HierarchyItem) and use that in our React components.
 *
 */

const ROOT_ID = 'root';

function dimensionComparator(
  a: [any, LinkedCategory, number],
  b: [any, LinkedCategory, number],
): number {
  const aCategoryOrdering = a[1].ordering();
  const bCategoryOrdering = b[1].ordering();
  const aMappingOrdering = a[2];
  const bMappingOrdering = b[2];
  if (
    aCategoryOrdering === bCategoryOrdering ||
    aCategoryOrdering === undefined ||
    bCategoryOrdering === undefined
  ) {
    return aMappingOrdering - bMappingOrdering;
  }
  return aCategoryOrdering - bCategoryOrdering;
}

export default class HierarchyTree<T: NamedItem> {
  cache: { [string]: MutableHierarchyItem<T>, ... } = {
    [ROOT_ID]: new MutableHierarchyItem(ROOT_ID),
  };

  _finalized: boolean = true;

  finalize(
    sortByTag: boolean = false,
    sortByName: boolean = false,
  ): HierarchyItem<LinkedCategory | T> {
    Object.keys(this.cache).forEach((key: string) => {
      const item = this.cache[key];
      item.finalize(sortByTag, sortByName);
    });
    this._finalized = false;
    return this.cache[ROOT_ID].finalize(sortByTag, sortByName);
  }

  add(item: MutableHierarchyItem<T>, parent?: LinkedCategory | void): void {
    if (!this._finalized) {
      throw new Error(
        '[HierarchyTree] Attempting to add nodes to a completed tree.',
      );
    }

    this.cache[item.id] = item;

    let currNode = item;
    let parentCategory = parent;

    // eslint-disable-next-line no-constant-condition
    while (true) {
      // If this node has no parent category, we should attach this to the root
      // of the tree
      if (parentCategory === undefined) {
        this.cache[ROOT_ID].addChild(currNode);
        break;
      }

      // If the parent category is already in the cache, attach the current
      // node to it as a child
      const parentId = parentCategory.id();
      if (this.cache[parentId]) {
        this.cache[parentId].addChild(currNode);
        break;
      }

      // The parent category is not in the cache, so let's make it into a
      // node in the tree
      this.cache[parentId] = new MutableHierarchyItem(
        parentId,
        [currNode],
        parentCategory,
      );

      // Store this new node as the current node, and now process its parent
      currNode = this.cache[parentId];
      parentCategory = parentCategory.parent();
    }
  }
}

export function buildFieldHierarchy(
  fields: $ReadOnlyArray<Field>,
  fieldCategoryMapping: { +[fieldId: string]: LinkedCategory },
  sortValues: boolean = false,
): HierarchyItem<LinkedCategory | Field> {
  const tree = new HierarchyTree();
  fields.forEach(field => {
    const category = fieldCategoryMapping[field.id()];
    if (category !== undefined) {
      tree.add(new MutableHierarchyItem(field.id(), [], field), category);
    }
  });
  return tree.finalize(sortValues, sortValues);
}

export function buildDimensionHierarchy(
  dimensions: $ReadOnlyArray<Dimension>,
  flattenHierarchy?: boolean = false,
): HierarchyItem<LinkedCategory | Dimension> {
  if (flattenHierarchy) {
    const tree = new HierarchyTree();
    dimensions.forEach(dimension => {
      tree.add(
        new MutableHierarchyItem(dimension.dimensionCode(), [], dimension),
        undefined,
      );
    });
    return tree.finalize(true, true);
  }

  const toAdd = [];
  dimensions.forEach(dimension => {
    dimension.categories().forEach(({ category, ordering }) => {
      toAdd.push([dimension, category, ordering]);
    });
  });
  const tree = new HierarchyTree();
  toAdd.sort(dimensionComparator).forEach(([dimension, category]) => {
    tree.add(
      new MutableHierarchyItem(dimension.dimensionCode(), [], dimension),
      category,
    );
  });
  return tree.finalize(true, false);
}

export function buildDimensionValueHierarchy(
  dimensionsToInclude: $ReadOnlyArray<Dimension>,
  dimensionValues: $ReadOnlyArray<DimensionValue>,
): HierarchyItem<LinkedCategory | DimensionValue> {
  const tree = new HierarchyTree<DimensionValue>();

  const dimensionIds = dimensionsToInclude.map(dimension =>
    dimension.dimensionCode(),
  );

  dimensionValues.forEach(dimensionValue => {
    const dimensionId = dimensionValue.dimension();
    if (dimensionIds.includes(dimensionId)) {
      const parentDimensionCategory = LinkedCategory.create({
        id: dimensionId,
        name: getFullDimensionName(dimensionId),
      });
      tree.add(
        new MutableHierarchyItem(dimensionValue.id(), [], dimensionValue),
        parentDimensionCategory,
      );
    }
  });

  return tree.finalize();
}

export function buildGroupingHierarchy(
  dimensions: $ReadOnlyArray<Dimension>,
  granularities: $ReadOnlyArray<Granularity>,
): HierarchyItem<LinkedCategory | GroupingDimension | GroupingGranularity> {
  const tree = new HierarchyTree();

  const toAdd = [];
  dimensions.forEach(dimension => {
    dimension.categories().forEach(({ category, ordering }) => {
      toAdd.push([
        new MutableHierarchyItem(
          dimension.dimensionCode(),
          [],
          GroupingDimension.create({
            dimension: dimension.dimensionCode(),
            name: dimension.name(),
          }),
        ),
        category,
        ordering,
      ]);
    });
  });
  toAdd.sort(dimensionComparator).forEach(([hierarchyItem, category]) => {
    tree.add(hierarchyItem, category);
  });

  // Granularities do not yet use the same sorting as dimensions so add them last so
  // they're at the end
  granularities.forEach(granularity =>
    granularity
      .categories()
      .forEach(({ category }) =>
        tree.add(
          new MutableHierarchyItem(
            granularity.id(),
            [],
            GroupingGranularity.createFromGranularity(granularity),
          ),
          category,
        ),
      ),
  );

  return tree.finalize(true, false);
}
