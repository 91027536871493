// @flow
import * as React from 'react';
import numeral from 'numeral';

import LoadingSpinner from 'components/ui/LoadingSpinner';

type Props = {
  className?: string,
  value: number | void,
};

export default function SummaryNumberPill({
  className = '',
  value,
}: Props): React.Element<'div'> {
  const content =
    value !== undefined ? (
      numeral(value).format('0,0')
    ) : (
      <LoadingSpinner color="white" size={10} />
    );

  return <div className={`ca-summary-number-pill ${className}`}>{content}</div>;
}
