// @flow

export const ENABLE_JOINS =
  window.__JSON_FROM_BACKEND.deploymentName === 'br_covid';

const MORTALITY_RATE_INDICATOR_ID = 'mortality_rate';
const NORMALIZED_MORTALITY_RATE_INDICATOR_ID = 'normalized_mortality_rate';
export const isJoinField = (fieldId: string): boolean =>
  fieldId === MORTALITY_RATE_INDICATOR_ID ||
  fieldId === NORMALIZED_MORTALITY_RATE_INDICATOR_ID;
