// @flow
import * as React from 'react';

import CohortDiagramNumber from 'components/common/QueryBuilder/CustomizableIndicatorTag/IndicatorCustomizationModule/CalculationCustomizationBlock/CohortCustomizationBlock/CohortSidePanel/CohortDiagram/CohortGroupSection/CohortDiagramNumber';
import CohortGroupDiagramSetOperationPill from 'components/common/QueryBuilder/CustomizableIndicatorTag/IndicatorCustomizationModule/CalculationCustomizationBlock/CohortCustomizationBlock/CohortSidePanel/CohortDiagram/CohortGroupSection/CohortGroupDiagram/CohortGroupDiagramSetOperationPill';
import type CohortGroup from 'models/core/wip/Calculation/CohortCalculation/CohortGroup';
import type { CohortGroupSummary } from 'services/AdvancedQueryApp/CohortCalculationSummaryService';

type Props = {
  cohortGroup: CohortGroup,
  cohortGroupSummary: CohortGroupSummary | void,
};

/** See README.md file for documentation */
export default function MatchingOperatorsDiagram({
  cohortGroup,
  cohortGroupSummary,
}: Props): React.Node {
  const allSegments = React.useMemo(
    () => [
      cohortGroup.primarySegment(),
      ...cohortGroup.additionalSegments().arrayView(),
    ],
    [cohortGroup],
  );

  const numSegments = allSegments.length;

  const centerRowOfSegments = numSegments;

  const groupValue =
    cohortGroupSummary !== undefined ? cohortGroupSummary.value : undefined;

  const groupValuePill = (
    <CohortDiagramNumber
      className="ca-cohort-group-diagram__summary-value"
      fontSize={12}
      style={{
        gridColumn: '6',
        gridRow: `${centerRowOfSegments} / ${centerRowOfSegments + 2}`,
      }}
      value={groupValue}
    />
  );

  const setOperationPill = (
    <CohortGroupDiagramSetOperationPill
      operations={[
        { isGroupOperation: false, operator: cohortGroup.outerOperation() },
        { isGroupOperation: true, operator: cohortGroup.innerOperation() },
      ]}
      style={{
        alignSelf: 'center',
        gridColumn: '4 / 6',
        gridRow: `${centerRowOfSegments} / ${centerRowOfSegments + 2}`,
        justifySelf: 'center',
      }}
    />
  );

  const maybeRenderSegmentOperationBorderlessPill = (segmentIndex: number) => {
    if (segmentIndex === 0) {
      return null;
    }

    const startRow = segmentIndex * 2;
    const endRow = startRow + 2;

    return (
      <CohortGroupDiagramSetOperationPill
        borderless
        operations={[
          { isGroupOperation: false, operator: cohortGroup.innerOperation() },
        ]}
        style={{
          gridColumn: '3',
          gridRow: `${startRow} / ${endRow}`,
          margin: 'auto',
        }}
      />
    );
  };

  return (
    <React.Fragment>
      {allSegments.map((segment, idx) => (
        // eslint-disable-next-line react/no-array-index-key
        <React.Fragment key={idx}>
          <div
            className="ca-cohort-group-diagram__horizontal-line"
            style={{ gridColumn: 3, gridRow: 1 + idx * 2 }}
          />
          {maybeRenderSegmentOperationBorderlessPill(idx)}
        </React.Fragment>
      ))}
      {numSegments > 1 && (
        <div
          className="ca-cohort-group-diagram__vertical-line"
          style={{
            gridColumn: 4,
            gridRow: `2 / ${numSegments * 2}`,
          }}
        />
      )}
      <div
        className="ca-cohort-group-diagram__horizontal-line"
        style={{ gridColumn: '4 / 7', gridRow: centerRowOfSegments }}
      />
      {numSegments > 1 && setOperationPill}
      {groupValuePill}
    </React.Fragment>
  );
}
