// @flow
import * as React from 'react';

import type { SVGProps } from 'components/ui/Icon/internal/SVGs/types';

export default function EntityMatching(props: SVGProps): React.Element<'svg'> {
  return (
    <svg
      fill="none"
      height="90"
      viewBox="0 0 90 90"
      width="90"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <ellipse
        cx="44.2623"
        cy="45.7377"
        fill="#EFF1F5"
        rx="44.2623"
        ry="44.2623"
      />
      <g
        fill="#BFC2C9"
        transform="scale(0.45,0.45)
      translate(35,37)"
      >
        <path d="M5.7 24h47.7V5.7A5.7 5.7 0 0 1 59 0h58.2c1.5 0 2.9.6 3.9 1.6s1.6 2.5 1.6 4v87.6a5.7 5.7 0 0 1-5.5 5.7H69.5v18.3a5.7 5.7 0 0 1-5.6 5.7H5.7c-1.5 0-2.9-.6-3.9-1.7-1-1-1.7-2.4-1.7-4V74.4a3.7 3.7 0 0 1 0-2V29.8A5.7 5.7 0 0 1 5.7 24zM94.1 29a3.7 3.7 0 1 1 5.3 5L88.5 45.7h27.1V7.3h-55V24h3.3c1.5 0 2.9.6 3.9 1.6s1.7 2.5 1.7 4v62h46.1V53H88.5l11 11.4a3.7 3.7 0 1 1-5.4 5.1L77.3 51.8a3.7 3.7 0 0 1 0-5L94 29zM23 58.3a3.7 3.7 0 0 1 5.4-5L45 70.9a3.7 3.7 0 0 1 0 5L28.3 93.7a3.7 3.7 0 0 1-5.4-5L34 77H7.2v38.5h55.2V31.3H7.2v38.5H34l-11-11.5z" />
      </g>
    </svg>
  );
}
