// @flow
import * as React from 'react';

import Dropdown from 'components/ui/Dropdown';
import Group from 'components/ui/Group';
import I18N from 'lib/I18N';
import {
  GROUP_SET_OPERATION_LABEL,
  SET_OPERATION_LABEL,
} from 'components/common/QueryBuilder/CustomizableIndicatorTag/IndicatorCustomizationModule/CalculationCustomizationBlock/CohortCustomizationBlock/CohortCreationPanel/constants';
import type CohortGroup from 'models/core/wip/Calculation/CohortCalculation/CohortGroup';
import type { SetOperation } from 'models/core/wip/Calculation/CohortCalculation/types';

type Props = {
  cohortGroup: CohortGroup,
  onCohortGroupChange: CohortGroup => void,
};

const DROPDOWN_ORDER: $ReadOnlyArray<[SetOperation, SetOperation]> = [
  ['INTERSECT', 'INTERSECT'],
  ['INTERSECT', 'UNION'],
  ['UNION', 'INTERSECT'],
  ['UNION', 'UNION'],
];

function packOperationValue(
  outerOperation: SetOperation,
  innerOperation: SetOperation,
): string {
  return `${outerOperation}--${innerOperation}`;
}

function unpackOperationValue(
  operationValue: string,
): [SetOperation, SetOperation] {
  const pieces = operationValue.split('--');

  // Refine to make Flow happy.
  if (
    (pieces[0] === 'INTERSECT' || pieces[0] === 'UNION') &&
    (pieces[1] === 'INTERSECT' || pieces[1] === 'UNION')
  ) {
    return [pieces[0], pieces[1]];
  }

  throw new Error(
    `Somehow received illegal operation value: ${operationValue}`,
  );
}

export default function AdditionalSegmentsCondition({
  cohortGroup,
  onCohortGroupChange,
}: Props): React.Node {
  const onSelectionChange = React.useCallback(
    (value: string) => {
      const [outerOperation, innerOperation] = unpackOperationValue(value);
      onCohortGroupChange(
        cohortGroup
          .outerOperation(outerOperation)
          .innerOperation(innerOperation),
      );
    },
    [cohortGroup, onCohortGroupChange],
  );

  const dropdownOptions = DROPDOWN_ORDER.map(([outer, inner]) => {
    const value = packOperationValue(outer, inner);
    return (
      <Dropdown.Option key={value} value={value}>
        {SET_OPERATION_LABEL[outer]} {GROUP_SET_OPERATION_LABEL[inner]}
      </Dropdown.Option>
    );
  });

  return (
    <Group.Horizontal spacing="xxs">
      <Dropdown
        buttonClassName="additional-segments-condition__dropdown-button"
        onSelectionChange={onSelectionChange}
        value={packOperationValue(
          cohortGroup.outerOperation(),
          cohortGroup.innerOperation(),
        )}
      >
        {dropdownOptions}
      </Dropdown>
      <span className="additional-segments-condition__suffix">
        {I18N.text('of the following conditions')}
      </span>
    </Group.Horizontal>
  );
}
