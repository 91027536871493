// @flow
import * as React from 'react';

import * as Zen from 'lib/Zen';
import CohortDiagram from 'components/common/QueryBuilder/CustomizableIndicatorTag/IndicatorCustomizationModule/CalculationCustomizationBlock/CohortCustomizationBlock/CohortSidePanel/CohortDiagram';
import CohortHeaderRow from 'components/common/QueryBuilder/CustomizableIndicatorTag/IndicatorCustomizationModule/CalculationCustomizationBlock/CohortCustomizationBlock/CohortSidePanel/CohortHeaderRow';
import Group from 'components/ui/Group';
import type Cohort from 'models/core/wip/Calculation/CohortCalculation/Cohort';
import type { CohortSummary } from 'services/AdvancedQueryApp/CohortCalculationSummaryService';

type Props = {
  cohorts: Zen.Array<Cohort>,
  cohortsSummary: $ReadOnlyArray<CohortSummary> | void,
  groupLabelsPerCohort: $ReadOnlyArray<$ReadOnlyArray<string>>,
};

export default function CohortSidePanel({
  cohorts,
  cohortsSummary,
  groupLabelsPerCohort,
}: Props): React.Node {
  const cohortDiagrams = cohorts.arrayView().map((cohort, idx) => {
    const cohortSummary =
      cohortsSummary !== undefined ? cohortsSummary[idx] : undefined;

    return (
      <Group.Item key={groupLabelsPerCohort[idx].join()}>
        <CohortHeaderRow
          cohortSummary={cohortSummary}
          includeOperator={idx !== 0}
        />
        <CohortDiagram
          cohort={cohort}
          cohortSummary={cohortSummary}
          groupLabels={groupLabelsPerCohort[idx]}
        />
      </Group.Item>
    );
  });

  return (
    <Group.Vertical
      className="ca-cohort-side-panel"
      paddingX="l"
      paddingY="m"
      spacing="none"
    >
      {cohortDiagrams}
    </Group.Vertical>
  );
}
