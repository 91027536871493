// @flow
import * as React from 'react';

import DimensionValueFilterOptionItem from 'components/common/QueryBuilder/CustomizableIndicatorTag/IndicatorCustomizationModule/CalculationCustomizationBlock/CohortCustomizationBlock/CohortCreationPanel/CohortSegmentRow/DimensionValueFilterOptionItem';
import I18N from 'lib/I18N';
import SegmentOptionBlock from 'components/common/QueryBuilder/CustomizableIndicatorTag/IndicatorCustomizationModule/CalculationCustomizationBlock/CohortCustomizationBlock/CohortCreationPanel/CohortSegmentRow/SegmentOptionBlock';
import SegmentOptionTitle from 'components/common/QueryBuilder/CustomizableIndicatorTag/IndicatorCustomizationModule/CalculationCustomizationBlock/CohortCustomizationBlock/CohortCreationPanel/CohortSegmentRow/SegmentOptionTitle';
import { SET_OPERATION_LABEL } from 'components/common/QueryBuilder/CustomizableIndicatorTag/IndicatorCustomizationModule/CalculationCustomizationBlock/CohortCustomizationBlock/CohortCreationPanel/constants';
import type CohortSegment from 'models/core/wip/Calculation/CohortCalculation/CohortSegment';
import type { QueryFilterItem } from 'models/core/wip/QueryFilterItem/types';

type Props = {
  onSegmentChange: CohortSegment => void,
  segment: CohortSegment,
};

const FILTER_OPERATION_TEXT = {
  INTERSECT: I18N.text('all'),
  UNION: I18N.text('any'),
};

export default function DimensionValueFilterOption({
  onSegmentChange,
  segment,
}: Props): $ReadOnlyArray<React.Element<typeof SegmentOptionBlock>> | null {
  const filters = segment.filters();
  const onFilterOperationToggle = React.useCallback(() => {
    const filterOperation = segment.filterOperation();
    if (filterOperation === 'INTERSECT') {
      onSegmentChange(segment.filterOperation('UNION'));
    } else if (filterOperation === 'UNION') {
      onSegmentChange(segment.filterOperation('INTERSECT'));
    }
  }, [segment, onSegmentChange]);

  const onFilterItemChange = React.useCallback(
    (newFilter: QueryFilterItem, currentFilter: QueryFilterItem) => {
      const idx = filters.indexOf(currentFilter);
      // TODO(stephen): Handle edge case where current filter is somehow not in
      // the array.
      if (idx !== -1) {
        onSegmentChange(segment.filters(filters.set(idx, newFilter)));
      }
    },
    [segment, onSegmentChange, filters],
  );

  const onFilterItemRemove = React.useCallback(
    (filter: QueryFilterItem) =>
      onSegmentChange(segment.filters(filters.filter(f => f !== filter))),
    [segment, onSegmentChange, filters],
  );

  if (filters.isEmpty()) {
    return null;
  }

  const filterOperation = segment.filterOperation();
  const title = (
    <SegmentOptionTitle>
      {I18N.text('Matches')}
      <SegmentOptionTitle.Action onClick={onFilterOperationToggle}>
        {FILTER_OPERATION_TEXT[filterOperation]}
      </SegmentOptionTitle.Action>
      {I18N.text('of the filters')}
    </SegmentOptionTitle>
  );

  const filterCount = filters.size();

  // NOTE(stephen): Returning the list of filters instead of enclosing in a
  // single block since we want the options to wrap naturally to the beginning
  // of the row if there are many filters. If an enclosing div exists, then the
  // wrapping will not work correctly.
  return filters.mapValues((filter, idx) => {
    let itemPrefixText = '';
    const isLastItem = idx === filterCount - 1;
    if (idx === 0) {
      itemPrefixText = I18N.text('where');
    } else if (filterCount > 2 && !isLastItem) {
      itemPrefixText = ',';
    } else if (filterCount > 1 && isLastItem) {
      itemPrefixText = SET_OPERATION_LABEL[filterOperation].toLowerCase();
    }

    const optionTitle = idx === 0 ? title : '';
    const option = (
      <DimensionValueFilterOptionItem
        filter={filter}
        onFilterItemChange={newFilter => onFilterItemChange(newFilter, filter)}
        onFilterItemRemove={() => onFilterItemRemove(filter)}
      />
    );
    return (
      <SegmentOptionBlock
        key={`filter-${idx}`}
        className={idx !== 0 ? 'filter-option__compact-filter' : undefined}
        option={option}
        prefix={itemPrefixText}
        title={optionTitle}
      />
    );
  });
}
