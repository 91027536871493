// @flow
import * as React from 'react';

import type { SVGProps } from 'components/ui/Icon/internal/SVGs/types';

export default function BarLineGraphVisualization(
  props: SVGProps,
): React.Element<'svg'> {
  return (
    <svg
      color="#2D80C2"
      height="64px"
      viewBox="0 0 64 64"
      width="64px"
      {...props}
    >
      <g fill="currentColor">
        <path d="M12.42 64H0V28.78a1.3 1.3 0 011.19-1.42h10a1.3 1.3 0 011.19 1.42zM1.91 62h8.6V29.36h-8.6zm42.99 2H32.48V48.62a1.18 1.18 0 011.19-1.18h10a1.18 1.18 0 011.19 1.18zm-10.51-2H43V49.44h-8.61zM60 21.08H50a1.34 1.34 0 00-1.19 1.5v8l1-.83 1-.83v-5.83h8.59V62h-8.77V36.74l-1 .82-1 .83V64h12.5V22.58a1.34 1.34 0 00-1.13-1.5zM18.15 7.91V2h8.6v8.43l.95 2.07 1 2.06V1.75C28.66.46 28 0 27.47 0h-10c-.55 0-1.19.46-1.19 1.75v7.87l1-.85zm8.6 16.32v37.76h-8.6V15.81l-.96.86-.95.85V64h12.42V28.36l-.96-2.07-.95-2.06z" />
        <path d="M38.69 44.17h-.21a1 1 0 01-.65-.54l-15.7-34L7.31 22.85A.93.93 0 016 22.74a1 1 0 01.11-1.42l15.76-14a.87.87 0 01.81-.22 1 1 0 01.67.54L39 41.58l15.3-13.24a.92.92 0 011.34.14 1 1 0 01-.13 1.41l-16.24 14a.9.9 0 01-.58.28z" />
        <path
          d="M11.23 28.36h-10c-.13 0-.23.19-.23.42V63h10.5V28.78c-.04-.23-.14-.42-.27-.42zm32.48 20.08h-10c-.13 0-.24.08-.24.18V63h10.47V48.62a.21.21 0 00-.23-.18zM17.19 16.67V63H27.7V26.29l-6.22-13.45-4.29 3.83zM23 5.08a2.94 2.94 0 012 1.64l2.67 5.79V1.76c0-.42-.1-.76-.23-.76h-10c-.13 0-.24.34-.24.76v7l3.4-3a2.79 2.79 0 012.4-.68zm37 17.01H50c-.13 0-.24.22-.24.49v7.18l3.43-3a2.77 2.77 0 014 .41 3.13 3.13 0 01-.39 4.24l-7.08 6.12V63h10.46V22.58c0-.27-.11-.49-.18-.49z"
          fillOpacity=".4"
        />
      </g>
    </svg>
  );
}
