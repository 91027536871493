// @flow
import * as React from 'react';

import type { SVGProps } from 'components/ui/Icon/internal/SVGs/types';

export default function Analyze(props: SVGProps): React.Element<'svg'> {
  return (
    <svg
      height="24"
      viewBox="0 0 128 128"
      width="24"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g fill="#000">
        <path d="M17.5 62h9.56c.074-1.355.229-2.688.448-4H17.5a2 2 0 000 4zM17.5 70h10.008a36.06 36.06 0 01-.451-4H17.5a2 2 0 000 4zM17.5 74a2 2 0 000 4h12.327a35.859 35.859 0 01-1.407-4H17.5zM17.5 82a2 2 0 000 4h17.015a36.112 36.112 0 01-2.684-4H17.5zM15.5 92a2 2 0 002 2h25.602a36.128 36.128 0 01-4.985-4H17.5a2 2 0 00-2 2zM73.5 18h-56a2 2 0 000 4h56a2 2 0 000-4zM62.937 28a35.922 35.922 0 0111.374 1.825A1.997 1.997 0 0073.5 26h-56a2 2 0 000 4h33.669a35.922 35.922 0 0111.768-2zM43.106 34H17.5a2 2 0 000 4h20.617a36.164 36.164 0 014.989-4zM34.517 42H17.5a2 2 0 000 4h14.329a36.006 36.006 0 012.688-4zM17.5 54h10.919a35.658 35.658 0 011.407-4H17.5a2 2 0 000 4zM74 50H43.501a23.785 23.785 0 00-2.265 3.848c.236.097.493.152.764.152h32a2 2 0 000-4zM74 58H39.75a23.946 23.946 0 00-.668 4H74a2 2 0 000-4zM74 66H39.084c.114 1.368.338 2.706.673 4H74a2 2 0 000-4zM74 74H41.188a24.05 24.05 0 002.336 4H74a2 2 0 000-4zM74 82H47.156a24.067 24.067 0 006.266 4H74a2 2 0 000-4zM74 42H53.394a23.831 23.831 0 00-6.239 4H74a2 2 0 000-4z" />
        <path d="M124.965 110.614L97.133 83.065l-1.599 1.641-4.782-4.782A31.858 31.858 0 0095 63.947C94.969 46.292 80.65 31.999 63.001 32h-.056C45.273 32.029 30.971 46.384 31 64.054 31.033 81.709 45.351 96 62.997 96h.058a31.847 31.847 0 0017.115-5.002l4.677 4.677-1.796 1.843 27.836 27.549c1.962 1.943 4.454 2.934 6.822 2.934 3.704 0 5.843-2.658 8.211-5.087 3.111-3.186 2.683-8.695-.955-12.3zM63.048 92h-.051C47.588 92 35.029 79.46 35 64.047c-.013-7.478 2.889-14.514 8.168-19.811 5.28-5.298 12.306-8.223 19.785-8.235h.049C78.413 36 90.973 48.54 91 63.952 91.023 79.392 78.484 91.974 63.048 92zm20.396-3.384a32.242 32.242 0 005.055-5.289l4.244 4.244-5.105 5.238-4.194-4.193zm39.612 31.505c-1.472 1.508-3.021 3.879-5.347 3.879-1.407 0-2.868-.646-4.009-1.776L88.685 97.467l8.499-8.723 24.965 24.712c2.043 2.023 2.459 5.074.907 6.665z" />
        <path d="M90.99 86.63H91v-.012zM83 112H8c-2.206 0-4-1.825-4-4.069V8.069C4 5.825 5.794 4 8 4h75c2.206 0 4 1.825 4 4.069v29.106a36.194 36.194 0 014 4.211V8.069C91 3.613 87.418 0 83 0H8C3.582 0 0 3.613 0 8.069v99.861c0 4.457 3.582 8.07 8 8.07h75c3.618 0 6.672-2.425 7.661-5.75h-4.382C85.556 111.305 84.357 112 83 112z" />
      </g>
    </svg>
  );
}
