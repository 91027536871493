// @flow
import * as Zen from 'lib/Zen';
import type { Serializable } from 'lib/Zen';

// Model representation that we receive from the backend
export type SerializedFieldCategoryMapping = {
  categoryId: string,
  isVisible: boolean,
};

type RequiredValues = {
  categoryId: string,
  isVisible: boolean,
};

class FieldCategoryMapping
  extends Zen.BaseModel<FieldCategoryMapping, RequiredValues>
  implements Serializable<SerializedFieldCategoryMapping> {
  static deserialize(
    values: SerializedFieldCategoryMapping,
  ): Zen.Model<FieldCategoryMapping> {
    const { categoryId, isVisible } = values;
    return FieldCategoryMapping.create({ categoryId, isVisible });
  }

  serialize(): SerializedFieldCategoryMapping {
    const { categoryId, isVisible } = this.modelValues();
    return {
      categoryId,
      isVisible,
    };
  }
}

export default ((FieldCategoryMapping: $Cast): Class<
  Zen.Model<FieldCategoryMapping>,
>);
