// @flow
import * as React from 'react';
import type Promise from 'bluebird';

import BaseModal from 'components/ui/BaseModal';
import Group from 'components/ui/Group';
import I18N from 'lib/I18N';
import RiskScoreOverviewSection from 'components/visualizations/Table/RiskScore/RiskScoreModal/RiskScoreOverviewSection';
import RiskScoreTableService from 'services/RiskScoreTableService';
import RiskScoreTables from 'components/visualizations/Table/RiskScore/RiskScoreModal/RiskScoreTables';
import { OVERVIEW_DOC_LINK } from 'components/visualizations/Table/RiskScore/RiskScoreModal/defaults';
import { cancelPromise } from 'util/promiseUtil';
import type { RiskScoreDataPoint } from 'services/RiskScoreTableService/types';

type Props = {
  onRequestClose: () => void,
  score: string | number,
  sexWorkerId: string | number,
};

type State = {
  sortedRiskScoreInfo: $ReadOnlyArray<RiskScoreDataPoint> | void,
};

export default class RiskScoreModal extends React.PureComponent<Props, State> {
  state: State = {
    sortedRiskScoreInfo: undefined,
  };

  _riskScoreInfoPromise: Promise<void> | void = undefined;

  componentDidMount() {
    const { sexWorkerId } = this.props;
    this._riskScoreInfoPromise = RiskScoreTableService.getRiskScoreInfo(
      sexWorkerId.toString(),
    ).then(riskScoreInfo => {
      const data = [...riskScoreInfo];
      this.setState({
        sortedRiskScoreInfo: data.sort((a, b) => b.term - a.term),
      });
    });
  }

  componentWillUnmount() {
    if (this._riskScoreInfoPromise !== undefined) {
      cancelPromise(this._riskScoreInfoPromise);
    }
  }

  render(): React.Node {
    const { onRequestClose, score, sexWorkerId } = this.props;

    return (
      <BaseModal
        onRequestClose={onRequestClose}
        show
        showFooter={false}
        title={I18N.text('What is this score?')}
      >
        <Group.Vertical spacing="l" style={{ padding: 20 }}>
          <RiskScoreOverviewSection itemId={sexWorkerId} score={score} />
          <Group.Item marginBottom="none">
            <RiskScoreTables data={this.state.sortedRiskScoreInfo} />
          </Group.Item>
          <p style={{ fontWeight: 'bold' }}>
            {I18N.text(
              "Definitions for each variable can be found in the 'Model input data' section in the",
            )}
            <a
              href={OVERVIEW_DOC_LINK}
              rel="noopener noreferrer"
              target="_blank"
            >
              {I18N.text('overview doc.')}
            </a>
          </p>
        </Group.Vertical>
      </BaseModal>
    );
  }
}
