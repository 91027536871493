// @flow
import * as React from 'react';

// eslint-disable-next-line import/no-cycle
import FieldService from 'services/wip/FieldService';
import type Field from 'models/core/wip/Field';
import type { Calculation } from 'models/core/wip/Calculation/types';

// Find the constituent field IDs for the provided calculation.
export function getConstituentIds(
  calculation: Calculation,
): $ReadOnlyArray<string> {
  // If the calculation is a "formula" type, we can easily find the
  // constituent field IDs just by inspecting the formula.
  if (calculation.tag === 'FORMULA') {
    return calculation.constituents().map(c => c.id());
  }

  // HACK(stephen): After moving to GraphQL with DataCatalog, we lose access
  // to the `constituentIds` property of `FieldMetadata`. This was originally
  // produced on the backend by inspecting the type of calculation and would
  // generally be produced for calculated and composite indicators. The
  // calculated indicator case is now handled with the FormulaCalculation
  // check above, however we do not have an equivalent test for the
  // composite indicator calculation. This is because composite indicators are
  // not really a thing when it comes to the frontend. Composite indicators
  // basically just take the SUM of the children. This means the calculation
  // type we receive on the frontend will be just a normal calculation. To
  // try and detect if a composite indicator is in use, we look at the
  // calculation filter. If it is a `FieldInFilter` then we are likely working
  // with a composite calculation and can assume that all the fields in the
  // filter are the composite children.
  const calculationFilter = calculation.get('filter');
  if (calculationFilter !== null && calculationFilter.tag === 'FIELD_IN') {
    return calculationFilter.fieldIds();
  }

  return [];
}

// Find any constituent fields for the field.
export default function useFieldConstituents(
  field: Field,
): $ReadOnlyArray<Field> {
  const calculation = field.calculation();

  // Find the constituent field IDs for the provided calculation.
  const constituentIds = React.useMemo(() => getConstituentIds(calculation), [
    calculation,
  ]);

  const constituentFields = React.useMemo(() => {
    if (constituentIds.length === 0) {
      return [];
    }

    const output = [];
    constituentIds.forEach(id => {
      const constituent = FieldService.UNSAFE_get(id);
      if (constituent !== undefined) {
        output.push(constituent);
      }
    });

    return output;
  }, [constituentIds]);

  return constituentFields;
}
