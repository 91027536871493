// @flow
import * as React from 'react';

import Group from 'components/ui/Group';
import I18N from 'lib/I18N';
import { OVERVIEW_DOC_LINK } from 'components/visualizations/Table/RiskScore/RiskScoreModal/defaults';

type Props = {
  itemId: string | number,
  score: string | number,
};

export default function RiskScoreOverviewSection({
  itemId,
  score,
}: Props): React.Node {
  // HACK(nina): Throughout this function, we need to bold parts of the text.
  // We should absolutely look to solutions for better standardizing string/
  // component interpolation.

  function renderExamples() {
    // Overriding font size to match with the default size of the rest of
    // the modal
    return (
      <Group.Vertical className="risk-score-examples" style={{ fontSize: 16 }}>
        <p>
          {I18N.text(
            "The model's goal is to produce an overall prediction leveraging all available data - not to identify or explore causal relationships between individual variables and outcomes. 3 examples to help illustrate this:",
          )}
        </p>
        <ol>
          <li>
            <strong>{I18N.text('Missing variables: ')}</strong>
            {I18N.text(
              'many variables that impact retention were not available or included in the data at all - for example, income. If one of the variables the model does consider happens to be correlated with income, it would have an impact on the score - but only because it correlates with income and not because the variable itself actually has a direct causal impact on probability of retention.',
            )}
          </li>
          <li>
            <strong>{I18N.text('Missing values: ')}</strong>
            {I18N.text(
              'there are many missing values in the data. For example, many individuals have no race specified. If the variable race_black increases probability of retention, it could actually be a reflection of data collection issues, for example whether a peer educator recorded race at all, rather than an individual being a specific race.',
            )}
          </li>
          <li>
            <strong>
              {I18N.text('Training data is not broadly representative: ')}
            </strong>
            {I18N.text(
              'the model has been trained on the data for HIV-positive sex workers who have been seen at a clinic since January 2019. This means even if there were any valid causal relationships identified, they would not necessarily apply to the broader population.',
            )}
          </li>
        </ol>
      </Group.Vertical>
    );
  }

  function renderDisclaimer() {
    return (
      <Group.Vertical spacing="m">
        <p>
          {I18N.text(
            'In an effort to make the model as transparent as possible, you will find the full set of variables that the model takes as input, organized in tabs and sorted by their relative impact on this particular probability score.',
          )}
        </p>
        <p>
          <strong>
            {I18N.text(
              'While we hope this allows for a better sense of what\'s going on "under the hood", it is very important to note that no conclusions can be drawn about causal relationships between each of these specific variables and the overall predicted probability of retention.',
            )}
          </strong>
        </p>
        {renderExamples()}
      </Group.Vertical>
    );
  }
  function renderSummary() {
    return (
      <Group.Horizontal className="risk-score-summary-section" spacing="m">
        <div className="risk-score-summary-section__details">
          <p>{I18N.text('SW ID: %(swId)s', { swId: itemId })}</p>
          <p>{I18N.text('Probability Score: %(score)s', { score })}</p>
        </div>
        <Group.Item className="risk-score-summary-section__summary">
          <p>
            <strong>{I18N.text('Overview: ')}</strong>
            {I18N.text(
              'this probability score is generated by a logistic regression model trained on the data for HIV-positive sex workers who have been seen at a clinic since January 2019. The model has been optimized to accurately identify those who are likely not to be retained. Full details on the overall methodology, metrics on how the model performs, and details on data sources & variables can be found ',
            )}
            <a
              href={OVERVIEW_DOC_LINK}
              rel="noopener noreferrer"
              target="_blank"
            >
              {I18N.text('here.')}
            </a>
          </p>
        </Group.Item>
      </Group.Horizontal>
    );
  }

  return (
    <Group.Vertical className="risk-score-overview-section" spacing="m">
      {renderSummary()}
      {renderDisclaimer()}
      <p>
        {I18N.text(
          "Please don't hesitate to reach out to us with any questions or concerns.",
        )}
      </p>
    </Group.Vertical>
  );
}
