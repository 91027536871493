// @flow
import Promise from 'bluebird';
import invariant from 'invariant';

import APIService, { API_VERSION } from 'services/APIService';
import CachedMapService from 'services/wip/CachedMapService';
import CategoryService from 'services/wip/CategoryService';
import DimensionService from 'services/wip/DimensionService';
import Field from 'models/core/wip/Field';
// eslint-disable-next-line import/no-cycle
import FieldMetadataService from 'services/wip/FieldMetadataService';
import { convertIDToURI, convertURIToID } from 'services/wip/util';
import type FieldMetadata from 'models/core/wip/FieldMetadata';
import type { APIVersion, HTTPService } from 'services/APIService';
import type { Cache, RejectFn, ResolveFn } from 'services/wip/CachedMapService';
import type { URI, URIConverter } from 'services/types/api';

/**
 * The FieldService is used to retrieve all the calculable Fields that exist.
 */
class FieldService extends CachedMapService<Field> implements URIConverter {
  apiVersion: APIVersion = API_VERSION.V2;
  endpoint: string;
  _httpService: HTTPService;
  _parentIdIndex: { [string]: $ReadOnlyArray<Field> } | void;
  _subscribers: Array<() => void>;

  constructor(httpService: HTTPService, includeHiddenDimensions: boolean) {
    super();
    this._httpService = httpService;
    this.endpoint = includeHiddenDimensions
      ? 'query/fields?include_hidden_dimensions=true'
      : 'query/fields';
    this._subscribers = [];
  }

  buildParentIdCache() {
    // TODO: deduplicate this implementation with `CategoryService` through
    // a common ancestor for hierarchical models
    invariant(this._mappingCache, 'Called without main cache populated');
    const parentIdIndex = {};
    Object.keys(this._mappingCache).forEach(id => {
      invariant(this._mappingCache, 'Called without main cache populated');
      const field = this._mappingCache[id];
      invariant(field, 'Can not be here');
      field.fieldCategoryMappings().forEach(mapping => {
        const categoryId = mapping.categoryId();
        if (!parentIdIndex[categoryId]) {
          parentIdIndex[categoryId] = [];
        }
        parentIdIndex[categoryId].push(field);
      });
    });
    this._parentIdIndex = parentIdIndex;
  }

  getChildrenById(parentId: string): $ReadOnlyArray<Field> {
    if (!this._mappingCache) {
      throw this.fetchMapping();
    }
    if (!this._parentIdIndex) {
      this.buildParentIdCache();
    }
    invariant(this._parentIdIndex, 'Can not be here');
    return this._parentIdIndex[parentId] || [];
  }

  buildCache(
    resolve: ResolveFn<Field>,
    reject: RejectFn,
  ): Promise<Cache<Field>> {
    // Performance optimization: preload services needed during deserialization
    // of the field so that we can use the Field.UNSAFE_deserialize synchronous
    // deserialization method. If we use Field.deserializeAsync, thousands of
    // promises will be created, and the resolution of all those promises is
    // slow and noticeable. Using the UNSAFE_deserialize method is preferred
    // in this case.
    const promise = Promise.all([
      this._httpService.get(this.apiVersion, this.endpoint),
      CategoryService.getAll(),
      DimensionService.getAll(),
    ]);
    return promise
      .then(([rawFieldList]) => {
        const fieldMappingCache = {};
        rawFieldList.forEach(serializedField => {
          const field = Field.UNSAFE_deserialize(serializedField);
          fieldMappingCache[field.id()] = field;
        });
        resolve(fieldMappingCache);
      })
      .catch(reject);
  }

  convertURIToID(uri: URI): string {
    return convertURIToID(uri, this.apiVersion, this.endpoint);
  }

  convertIDToURI(id: string): URI {
    return convertIDToURI(id, this.apiVersion, this.endpoint);
  }

  subscribe(listener: () => void): void {
    this._subscribers.push(listener);
  }

  notifyAll(): void {
    this._subscribers.forEach(subscriber => subscriber());
  }

  updateInternal(fields: $ReadOnlyArray<Field>): void {
    const { _mappingCache } = this;
    invariant(_mappingCache, 'Cache must be populated first');
    fields.forEach(field => {
      _mappingCache[field.id()] = field;
    });
    this._setMappingAndResolve(() => {}, _mappingCache);
    this.buildParentIdCache();
    this.notifyAll();
  }

  update(field: Field): Promise<void> {
    return this._httpService
      .patch(
        API_VERSION.V2,
        `query/fields/${field.id()}`,
        field.serializeForUpdate(),
      )
      .then(() => {
        if (this._mappingCache && this._parentIdIndex) {
          this.updateInternal([field]);
        }
      });
  }

  create(field: Field, fieldMetadata: FieldMetadata): Promise<void> {
    return this._httpService
      .post(API_VERSION.V2, `query/fields/${field.id()}`, {
        ...field.serializeForUpdate(),
        ...fieldMetadata.serialize(),
      })
      .then(() => {
        FieldMetadataService.updateInternal(fieldMetadata);
        if (this._mappingCache && this._parentIdIndex) {
          this.updateInternal([field]);
        }
      });
  }

  delete(field: Field): Promise<void> {
    return this._httpService
      .delete(API_VERSION.V2, `query/fields/${field.id()}`)
      .then(() => {
        if (this._mappingCache) {
          delete this._mappingCache[field.id()];
          this.buildParentIdCache();
          this.notifyAll();
        }
      });
  }
}

export const FullFieldService: FieldService = new FieldService(
  APIService,
  true,
);

export default (new FieldService(APIService, false): FieldService);
