// @flow
import * as React from 'react';

import AtLeastFilter from 'models/core/wip/Calculation/CohortCalculation/NumericValueCohortFilterItem/AtLeastFilter';
import AtMostFilter from 'models/core/wip/Calculation/CohortCalculation/NumericValueCohortFilterItem/AtMostFilter';
import BetweenFilter from 'models/core/wip/Calculation/CohortCalculation/NumericValueCohortFilterItem/BetweenFilter';
import CustomizableTag from 'components/AdvancedQueryApp/QueryFormPanel/QueryBuilder/SelectionBlock/CustomizableTag';
import EqualToFilter from 'models/core/wip/Calculation/CohortCalculation/NumericValueCohortFilterItem/EqualToFilter';
import GreaterThanFilter from 'models/core/wip/Calculation/CohortCalculation/NumericValueCohortFilterItem/GreaterThanFilter';
import I18N from 'lib/I18N';
import LessThanFilter from 'models/core/wip/Calculation/CohortCalculation/NumericValueCohortFilterItem/LessThanFilter';
import NumericFilterCustomizationModule from 'components/common/QueryBuilder/CustomizableIndicatorTag/IndicatorCustomizationModule/CalculationCustomizationBlock/CohortCustomizationBlock/CohortCreationPanel/CohortSegmentRow/NumericFilterCustomizationModule';
import SegmentOptionBlock from 'components/common/QueryBuilder/CustomizableIndicatorTag/IndicatorCustomizationModule/CalculationCustomizationBlock/CohortCustomizationBlock/CohortCreationPanel/CohortSegmentRow/SegmentOptionBlock';
import type CohortSegment from 'models/core/wip/Calculation/CohortCalculation/CohortSegment';
import type { NumericValueCohortFilterItem } from 'models/core/wip/Calculation/CohortCalculation/NumericValueCohortFilterItem/NumericValueCohortFilterItemUtil';

type Props = {
  onRequestCloseCustomizationModule: () => void,
  onSegmentChange: CohortSegment => void,
  onTagClick: () => void,
  segment: CohortSegment,
  showCustomizationModule: boolean,
};

const TAG_TEXT_MAP = {
  [AtLeastFilter.tag]: value => I18N.text('at least %(value)s', { value }),
  [AtMostFilter.tag]: value => I18N.text('at most %(value)s', { value }),
  [EqualToFilter.tag]: value => I18N.text('equal to %(value)s', { value }),
  [GreaterThanFilter.tag]: value =>
    I18N.text('greater than %(value)s', { value }),
  [LessThanFilter.tag]: value => I18N.text('less than %(value)s', { value }),
};

export default function NumericFilterOption({
  onRequestCloseCustomizationModule,
  onSegmentChange,
  onTagClick,
  segment,
  showCustomizationModule,
}: Props): React.Node {
  const onFilterChange = (filter: NumericValueCohortFilterItem) => {
    onSegmentChange(segment.numericValueCohortFilter(filter));
  };

  const numericValueCohortFilter = segment.numericValueCohortFilter();

  const onRemoveTagClick = React.useCallback(() => {
    onSegmentChange(segment.numericValueCohortFilter(undefined));
  }, [segment, onSegmentChange]);

  const renderCustomizationModule = () => {
    if (numericValueCohortFilter === undefined) {
      return null;
    }

    return (
      <NumericFilterCustomizationModule
        initialFilter={numericValueCohortFilter}
        onFilterChange={onFilterChange}
        onRequestClose={onRequestCloseCustomizationModule}
      />
    );
  };

  if (numericValueCohortFilter === undefined) {
    return null;
  }

  const tagName =
    numericValueCohortFilter.tag === BetweenFilter.tag
      ? I18N.text('between %(minValue)s and %(maxValue)s', {
          maxValue: numericValueCohortFilter.maxValue(),
          minValue: numericValueCohortFilter.minValue(),
        })
      : TAG_TEXT_MAP[numericValueCohortFilter.tag](
          numericValueCohortFilter.get('value'),
        );

  const option = (
    <CustomizableTag
      className="filter-option-item__tag"
      item={numericValueCohortFilter}
      onCloseCustomizationModuleClick={onRequestCloseCustomizationModule}
      onRemoveTagClick={onRemoveTagClick}
      onTagClick={onTagClick}
      renderCustomizationModule={renderCustomizationModule}
      showCustomizationModule={showCustomizationModule}
      showDragHandle={false}
      tagName={tagName}
      useDefaultCustomizationModuleContainer={false}
    />
  );

  return (
    <SegmentOptionBlock option={option} title={I18N.text('having values')} />
  );
}
