// @flow
import * as React from 'react';

import type { SVGProps } from 'components/ui/Icon/internal/SVGs/types';

export default function Flashlight(props: SVGProps): React.Element<'svg'> {
  return (
    <svg
      color="#313234"
      height="20"
      viewBox="0 0 51 20"
      width="51"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g fill="currentColor">
        <path d="M43.61111.16667h-.05555H43.5h-.05556-.05555l-9.72222 4.55555H2.6111C2.27778 4.72222 2 5 2 5.33333v.27778H.61111C.27778 5.61111 0 5.8889 0 6.22222v7.55556c0 .33333.27778.6111.61111.6111h1.44445v.27779c0 .33333.27777.6111.6111.6111h31.05556l9.72222 4.55556h6c.33334 0 .61112-.27777.61112-.6111v-18.5c0-.33334-.27778-.61112-.61112-.61112l-5.83333.05556zm-41.72222 13h-.72222V6.83333h.66666v6.33334h.05556zM33.22222 14h-30V5.94444h30V14zm9.83334 4.33333l-8.61112-4.05555v-8.5l8.61112-4.11111v16.66666zm1.16666-17h4.61111v17.33334h-4.6111V1.33333z" />
        <path d="M23.77778 10c0 1.05556.83333 1.88889 1.88889 1.88889H29c1.05556 0 1.88889-.83333 1.88889-1.88889 0-1.05556-.83333-1.88889-1.88889-1.88889h-3.33333c-1.05556 0-1.8889.83333-1.8889 1.88889zm5.94444 0c0 .38889-.33333.72222-.72222.72222h-1.77778c.27778-.44444.27778-1 0-1.44444H29c.38889 0 .72222.33333.72222.72222z" />
      </g>
    </svg>
  );
}
